import {
    Entity,
    Var,
    Input,
    EntityBase,
} from "addeus-common-library/stores/firestore/index";
import { Owner } from "../owner";
import { Circuit } from "./circuit";
import { EntityArray } from "addeus-common-library/stores/firestore/entity";

export class Coating extends EntityBase {
    @Var(String)
    @Input("text", { required: true })
    type?: string;

    @Var(String)
    @Input("text", { required: true })
    quality?: string;

    toString() {
        return this.type;
    }
}

export class Track extends Entity {
    static collectionName = "tracks";

    @Var(String)
    @Input("text", { required: true })
    name?: string;

    @Var(String)
    @Input("text", { required: true })
    description?: string;

    @Var(String)
    @Input("file")
    picture?: string;

    @Var(Array.of(Circuit))
    @Input("array", { of: Circuit })
    circuits?: Circuit[] = EntityArray();

    @Var(Array.of(Coating))
    @Input("array", { of: Coating })
    coatings?: string[] = EntityArray();

    @Var(Owner)
    owner?: Owner;

    toString() {
        return this.name;
    }
}
