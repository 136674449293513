import {
    Entity,
    EntityBase,
    Input,
    Var,
} from "addeus-common-library/stores/firestore/index";
import moment from "moment-with-locales-es6";
import type { Tag } from "../customer";
import { Customer } from "../customer";
import { Owner } from "../owner";
import { Employee } from "../employee";
import { Type as KartType, Kart } from "./kart";
import { Device } from "./device";
import { Circuit } from "./circuit";
import { EntityArray } from "addeus-common-library/stores/firestore/entity";
import { Speed as KartSpeed } from "./speed";
import { Item, ProjectorScenario } from "./item";
import { asyncSort } from "addeus-common-library/utils/array";
import { sortDrivers } from "../../algorithm";

export enum Status {
    waiting = "waiting",
    assigning = "assigning",
    started = "started",
    finished = "finished",
    canceled = "canceled",
}

export enum RankingType {
    bestTime = "bestTime",
    firstArrived = "firstArrived",
    earnedMoney = "earnedMoney",
}

export enum FillParticipantsType {
    notDuplicated = "notDuplicated",
}

export enum OrderParticipantsType {
    previousRankings = "previousRankings",
    previousRankingsReverse = "previousRankingsReverse",
    previousBestTimes = "previousBestTimes",
    previousBestTimesReverse = "previousBestTimesReverse",
    earnedMoney = "earnedMoney",
    previousOrder = "previousOrder",
}

export enum RankingPeriod {
    rolling = "rolling",
    // month = "month",
    // year = "year",
    // permanent = "permanent",
}

export class Type extends Entity {
    static collectionName = "raceTypes";

    @Var(String)
    @Input("text", { required: true })
    name?: string;

    @Var(Number)
    @Input("number", {
        validate: [
            [
                "required",
                (value: number, row: Type) => {
                    return (row.round && row.round > 0) || (value && value > 0);
                },
            ],
        ],
    })
    duration?: number;

    @Var(Number)
    @Input("number", {
        validate: [
            [
                "required",
                (value: number, row: Type) => {
                    return (row.duration && row.duration > 0) || (value && value > 0);
                },
            ],
        ],
    })
    round?: number;

    @Var(RankingType)
    @Input("select", { required: true, options: RankingType })
    rankingType: RankingType = RankingType.bestTime;

    @Var(Number)
    @Input("number", { required: true })
    maxParticipants: number = 8;

    @Var(FillParticipantsType)
    @Input("select", { required: true, options: FillParticipantsType })
    fillParticipantsType?: FillParticipantsType = FillParticipantsType.notDuplicated;

    @Var(OrderParticipantsType)
    @Input("select", { required: true, options: OrderParticipantsType })
    orderParticipantsType?: OrderParticipantsType;

    @Var(RankingPeriod)
    @Input("select", { options: RankingPeriod, multiple: true })
    rankingPeriods?: RankingPeriod[] = EntityArray();

    @Var(Array.of(ProjectorScenario))
    projectorScenarios: ProjectorScenario[] = EntityArray();

    @Var(Owner)
    owner?: Owner;

    toString() {
        return `${this.name}`;
    }
}

export class Checkpoint extends EntityBase {
    @Var(Device)
    from?: Device;

    @Var(Device)
    to?: Device;

    @Var(Number)
    duration: number = 0;

    @Var(Array.of(Item))
    usedItems: Item[] = EntityArray();

    @Var(Array.of(Item))
    ownedItems: Item[] = EntityArray();

    @Var(Number)
    earnedMoney = 0;

    @Var(Number)
    spentMoney = 0;

    earnItem(item: Item) {
        this.ownedItems.push(item);
        this.earnedMoney += item.ownMoney || 0;
        if (item.activateOnAcquisition) this.useItem(item);
    }

    useItem(item: Item) {
        this.usedItems.push(item);
        this.spentMoney += item.costMoney || 0;

        // Apply item effect

        // setTimeout(() => {

        //     // Remove Item effect
        // }, item.effectiveTime);
    }
}

export class Lap extends EntityBase {
    @Var(Kart)
    kart?: Kart;

    @Var(Array.of(Checkpoint))
    checkpoints: Checkpoint[] = EntityArray();

    getTime(): number {
        return this.checkpoints.reduce((acc, checkpoint) => {
            return acc + checkpoint.duration;
        }, 0);
    }

    getTimeDifference(from: number) {
        return this.getTime() - from;
    }

    get ownedItems(): Item[] {
        return this.checkpoints.reduce((acc, checkpoint) => {
            return acc.concat(checkpoint.ownedItems);
        }, [] as Item[]);
    }

    get usedItems(): Item[] {
        return this.checkpoints.reduce((acc, checkpoint) => {
            return acc.concat(checkpoint.usedItems);
        }, [] as Item[]);
    }
}

export class Driver extends EntityBase {
    @Var(KartType)
    kartType?: KartType;

    @Var(KartSpeed)
    kartSpeed?: KartSpeed;

    @Var(String)
    kartColor: string = "#000000";

    @Var(Customer)
    customer?: Customer;

    @Var(String)
    byName?: string;

    @Var(String)
    uid?: string;

    @Var(Kart)
    kart?: Kart;

    @Var(Array.of(Lap))
    laps: Lap[] = EntityArray();

    @Var(Array.of(String))
    tags?: Tag[] = [];

    get driverTags() {
        if (this.customer !== undefined) {
            this.tags = this.customer.tags;
        }
        return this.tags;
    }

    set driverTags(value: Tag[] | undefined) {
        if (value === undefined) return;
        if (this.customer !== undefined) {
            this.customer.tags = value;
        }
        this.tags = value;
    }

    get nickName() {
        if (this.customer !== undefined) {
            this.byName = this.customer.nickName;
        }
        return this.byName;
    }

    set nickName(value: string | undefined) {
        if (this.customer !== undefined) {
            this.customer.nickName = value;
        }
        this.byName = value;
    }

    get driverID() {
        if (this.uid === undefined && this.customer !== undefined) {
            return this.customer?.$getID();
        }
        return this.uid;
    }

    getTotalTime(removeFirstLap: boolean = false): number {
        let laps = this.laps.filter((lap) => lap);
        if (removeFirstLap && laps.length > 0) laps = laps.slice(1);
        return laps
            .filter((lap) => lap)
            .reduce((acc, lap) => {
                return acc + lap.getTime();
            }, 0);
    }

    $getAverageSpeed(circuit: Circuit): number {
        if (circuit.length === 0 || circuit.length === undefined) {
            return 0;
        }
        const speeds: number[] = this.laps
            .map((lap) => {
                const time: number = lap.getTime() / (60 * 60 * 1000); // compute time in hour
                const gates: Device[] = lap.checkpoints.map((chkpt) => chkpt.to); // get the gates passed during the lap
                let currentCircuit = undefined;
                // Check for each circuit if all its gates are in the lap
                if (circuit.gates.every((device) => gates.includes(device))) {
                    currentCircuit = circuit;
                }
                return currentCircuit ? currentCircuit.length / 1000 / time : 0;
            })
            .filter((speed) => speed !== 0);
        return speeds.reduce((prev, curr) => prev + curr, 0) / speeds.length;
    }

    getBestLapTime(removeFirstLap: boolean = false): number {
        let laps = this.laps.filter((lap) => lap);
        if (removeFirstLap && laps.length > 0) laps = laps.slice(1);

        const lapsOrdered = laps.sort((lapA: Lap, lapB: Lap) => {
            if (lapA.getTime() === 0) return 1;
            if (lapB.getTime() === 0) return -1;
            return lapA.getTime() - lapB.getTime();
        });
        if (lapsOrdered[0] === undefined) return Number.MAX_SAFE_INTEGER;
        return lapsOrdered[0].getTime();
    }

    getAvailableItems() {
        const availableItems: Item[] = [];

        this.laps.forEach((lap) => {
            lap.checkpoints.forEach((checkpoint) => {
                checkpoint.ownedItems.forEach((item) => {
                    availableItems.push(item);
                });
                checkpoint.usedItems.forEach((item) => {
                    const index = availableItems.findIndex((availableItem) => {
                        return item.$isSame(availableItem);
                    });
                    availableItems.splice(index, 1);
                });
            });
        });
        return availableItems;
    }

    getEarnedMoney() {
        let earnedMoney = 0;
        this.laps.forEach((lap) => {
            lap.checkpoints.forEach((checkpoint) => {
                earnedMoney += checkpoint.earnedMoney;
                earnedMoney -= checkpoint.spentMoney;
                // checkpoint.ownedItems.forEach((item) => {
                //     earnedMoney += item.ownMoney || 0;
                // });

                // checkpoint.usedItems.forEach((item) => {
                //     earnedMoney -= item.costMoney || 0;
                // });
            });
        });
        return earnedMoney;
    }
}

export enum ModifierType {
    seconds = "seconds",
    round = "round",
}

export class Modifier extends EntityBase {
    @Var(ModifierType)
    type: ModifierType = ModifierType.seconds;

    @Var(Number)
    value: number = 0;
}

export class Race extends Entity {
    static collectionName = "races";

    @Var(moment)
    date: moment;

    @Var(Status)
    status: Status = Status.waiting;

    @Var(Type)
    type?: Type;

    @Var(Array.of(Driver))
    drivers: Driver[] = EntityArray();

    @Var(Number)
    groups: { [key: string]: number } = {};

    @Var(Owner)
    owner?: Owner;

    @Var(Employee)
    createdBy?: Employee;

    @Var(Circuit)
    circuit?: Circuit;

    @Var(moment)
    startedAt?: moment;

    @Var(moment)
    endedAt?: moment;

    @Var(Array.of(Modifier))
    modifiers: Modifier[] = EntityArray();

    get virtualNumber() {
        const hour = parseInt(this.date.format("HH")) + 1;
        const minute = parseInt(this.date.format("mm")) + 10;

        const number = hour * (minute / 10);
        return number;
    }

    isStarted(): boolean {
        return this.status === Status.started;
    }

    getBestLapTime(removeFirstLap: boolean = false): number {
        let bestLapTime = Infinity;
        this.drivers.forEach((driver) => {
            const bestDriverLapTime = driver.getBestLapTime(removeFirstLap);
            if (bestLapTime > bestDriverLapTime) {
                bestLapTime = bestDriverLapTime;
            }
        });
        return bestLapTime;
    }

    /**
     * @Todo a supprimer
     */
    getPosition(driver: Driver): number | undefined {
        const driversSorted = this.getOrderedDrivers();

        if (!Array.isArray(driversSorted) || driversSorted.length === 0) return undefined;

        for (let i = 0; i < driversSorted.length; i++) {
            if (driversSorted[i] === driver) {
                return i + 1;
            }
        }
        return undefined;
    }

    get currentLap() {
        let currentLap = 0;
        this.drivers.forEach((driver) => {
            if (driver.laps.length > currentLap) currentLap = driver.laps.length;
        });
        return currentLap;
    }

    get maxLaps(): number | undefined {
        if (this.type?.round === undefined) return undefined;
        const modifiersRound = this.modifiers?.filter(
            (modifier) => modifier.type === "round",
        );
        const deltaModifierLap = modifiersRound.reduce(
            (acc, modifier) => acc + modifier.value,
            0,
        );
        return this.type.round + deltaModifierLap;
    }

    getOrderedDrivers() {
        return sortDrivers(this.type?.rankingType!, this.drivers);
    }
}
