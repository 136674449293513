import { acceptHMRUpdate, defineStore } from "pinia";
import { useUserSession } from "./userSession";
import { useFirebase } from "addeus-common-library/stores/firebase";
import {
    ref,
    push,
    onValue,
    orderByChild,
    startAt,
    endAt,
    query,
} from "firebase/database";
import moment from "moment-with-locales-es6";
import type { ComputedRef, Ref } from "vue";
import { ref as vueRef, watch, onUnmounted } from "vue";
import type { MomentRange } from "addeus-common-library/components/base/form/VDatePicker.vue";

export type AvailableRange =
    | "previousDay"
    | "lastWeek"
    | "lastMonth"
    | "lastYear"
    | "previousYear";

export type AvailableZoom = "minute" | "hour" | "day" | "week" | "month" | "year";

export const useReporting = defineStore("reporting", () => {
    const userSession = useUserSession();
    const firebase = useFirebase();

    return {
        async add(type: string, value: any) {
            const now = moment();
            const reference = ref(
                firebase?.database,
                `reportings/${userSession.user?.owner?.$getID()}/${type}`
            );
            await push(reference, {
                timestamp: now.valueOf(),
                value,
            });
        },
        fetch(type: string, range: ComputedRef<MomentRange>): Ref<any[]> {
            const result = vueRef([]);

            let unsuscribe: any = null;

            function updateQuery() {
                if (unsuscribe !== null) unsuscribe();

                if (userSession.user?.owner === undefined) return;

                const path = `reportings/${userSession.user?.owner?.$getID()}/${type}`;

                const reference = ref(firebase?.database, path);

                const q = query(
                    reference,
                    orderByChild("timestamp"),
                    startAt(range.value.start.valueOf()),
                    endAt(range.value.end.valueOf())
                );

                result.value = [];

                unsuscribe = onValue(
                    q,
                    (snapshot) => {
                        const values = snapshot.val();
                        if (values === null) return;
                        else result.value = Object.values(values);
                    },
                    // eslint-disable-next-line no-console
                    console.error
                );
            }

            watch(() => userSession.user, updateQuery);
            watch(() => {
                return range.value.start.valueOf() + "-" + range.value.end.valueOf();
            }, updateQuery);
            updateQuery();

            onUnmounted(() => {
                if (unsuscribe !== null) unsuscribe();
            });

            return result;
        },
    };
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useReporting, import.meta.hot));
}
