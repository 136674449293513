<script lang="ts">
import { Display, Scenario, Frame, Hook } from "/@src/models/display";
import { defineComponent, toRefs } from "vue";
import { useUserSession } from "/@src/stores/userSession";

export function modelEvents() {
    return {
        async onBeforeCreate(screen: any) {
            const { user } = useUserSession();
            if (user === null) throw new Error("No owner selected");
            screen.owner = user.owner;
        },

        async onBeforeEdit(screen: any) {
            const { user } = useUserSession();
            if (user === null) throw new Error("No owner selected");
            screen.owner = user.owner;
        },
    };
}

export default defineComponent({
    props: {
        form: {
            type: Object,
            required: true,
        },
    },
    setup(props: any) {
        const { form: screen } = toRefs(props);

        return {
            screen,
            screenType: Display,
            scenarioType: Scenario,
            frameType: Frame,
            hookType: Hook,
        };
    },
});
</script>

<template>
    <VFieldModel v-model="screen" property="name"></VFieldModel>
    <VEntities
        v-slot="{ field: scenario }"
        v-model="screen.scenarios"
        :model="scenarioType"
        property="scenarios"
        multiple>
        <VFieldModel v-model="scenario.value" property="name"></VFieldModel>

        <VEntities
            v-model="scenario.value.hooks"
            :model="hookType"
            property="hooks"
            :multiple="true">
            <template #default="{ field: hook }">
                <VFieldModel v-model="hook.value" property="type"></VFieldModel>
            </template>
            <template #collapsed="{ value }">
                <Translate>.type.options.{{ value.type }}</Translate>
            </template>
        </VEntities>
        <VFieldModel v-model="scenario.value" property="loopable"></VFieldModel>
        <VEntities
            v-slot="{ field: frame }"
            v-model="scenario.value.frames"
            :model="frameType"
            property="frames"
            multiple>
            <VFieldModel v-model="frame.value" property="name"></VFieldModel>
            <VFieldModel v-model="frame.value" property="type"></VFieldModel>
            <VFieldModel v-model="frame.value" property="duration"></VFieldModel>
            <VFieldModel
                v-if="frame.value.type === 'image' || frame.value.type === 'video'"
                v-model="frame.value"
                property="file"></VFieldModel>
        </VEntities>
    </VEntities>
</template>
