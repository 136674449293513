import moment from "moment-with-locales-es6";
import type { ProductCategory } from "..";
import { Product, ProductVariant } from "..";
import { Customer } from "../../customer";
import { Employee } from "../../employee";
import { Owner } from "../../owner";
import { PaymentMethod } from "../paymentMethod";
import { Entity, Input, Var, useDoc } from "addeus-common-library/stores/firestore";
import { EntityBase } from "addeus-common-library/stores/firestore";
import { useEmployeeSession } from "../../../stores/employeeSession";
import { OrderStatus } from "./orderStatus";
import { Discount } from "../discount";
import type { ProductSalesChannel } from "../channel";
import { DistributionChannel, SalesChannel } from "../channel";
import { EntityArray } from "addeus-common-library/stores/firestore/entity";

export class OrderItem extends EntityBase {
    @Var(Number)
    price?: number;

    @Var(Number)
    tax?: number;

    @Var(String)
    taxName?: string;

    @Var(ProductVariant)
    product?: ProductVariant;

    @Var(Array.of(Discount))
    discounts: Discount[] = EntityArray();

    @Var(Number)
    quantity: number = 1;

    @Var(Owner)
    owner?: Owner;

    get total() {
        return this.price! * this.quantity;
    }

    get unitPrice() {
        return Math.round(this.price! * (1 + this.tax!) * 100) / 100;
    }

    get VAT() {
        const unitPrice = this.price! * this.tax!;
        return unitPrice * this.quantity;
    }

    totalVAT() {
        return this.totalVATWithoutDiscount() - this.discount();
    }

    totalVATWithoutDiscount() {
        const unitPrice = Math.round(this.price! * (1 + this.tax!) * 100) / 100;
        return unitPrice * this.quantity;
    }

    discount() {
        if (!Array.isArray(this.discounts)) return 0;
        return this.discounts.reduce((acc, discount) => {
            return acc + discount.getDetailsFromOrder(this).total;
        }, 0);
    }
}

export class OrderPayment extends EntityBase {
    @Input("radio", {
        options: {
            entity: PaymentMethod,
            where() {
                const employeeSession = useEmployeeSession();
                return [["owner", "==", employeeSession.user?.owner?.$getID()]];
            },
        },
        required: true,
    })
    @Var(PaymentMethod)
    payment?: PaymentMethod;

    @Var(Number)
    amount?: number;

    @Input("textarea")
    @Var(String)
    comment?: string;
}

export class OrderCredit extends EntityBase {
    @Var(Number)
    amount?: number;

    @Input("radio", {
        options: {
            entity: PaymentMethod,
            where() {
                const employeeSession = useEmployeeSession();
                return [["owner", "==", employeeSession.user?.owner?.$getID()]];
            },
        },
        required: true,
    })
    @Var(PaymentMethod)
    payment?: PaymentMethod;

    @Var(Array.of(OrderItem))
    items: OrderItem[] = EntityArray();

    @Input("textarea", {
        required: true,
    })
    @Var(String)
    comment?: string;
}

export class Order extends Entity {
    static collectionName = "orders";

    // Increment from owner
    @Var(Number)
    number?: number;

    @Var(moment)
    createdAt: moment;

    @Var(Employee)
    createdBy?: Employee;

    @Var(moment)
    closedAt: moment;

    @Var(Boolean)
    isADemo: boolean = false;

    @Var(Boolean)
    isARefund: boolean = false;

    @Var(Order)
    refundOrder?: Order;

    @Var(Customer)
    customer?: Customer;

    @Var(Array.of(OrderItem))
    items: OrderItem[] = EntityArray();

    @Var(Array.of(OrderPayment))
    payments: OrderPayment[] = EntityArray();

    @Var(Array.of(OrderCredit))
    credits: OrderCredit[] = EntityArray();

    @Var(Array.of(Discount))
    discounts: Discount[] = EntityArray();

    get allDiscounts() {
        return this.items
            .reduce((acc, item) => {
                return acc.concat(item.discounts);
            }, [] as Discount[])
            .concat(this.discounts);
    }

    @Var(OrderStatus)
    status: OrderStatus = OrderStatus.Draft;

    @Var(SalesChannel)
    saleChannel?: SalesChannel;

    @Var(DistributionChannel)
    distributionChannel?: DistributionChannel;

    @Var(String)
    comment?: string;

    @Var(Owner)
    owner?: Owner;

    totalVAT() {
        return this.totalVATWithoutDiscount() - this.discount();
    }

    discount() {
        const itemDiscounts = this.items.reduce((acc, item) => {
            return acc + item.discount();
        }, 0);

        if (!Array.isArray(this.discounts)) return itemDiscounts;

        return this.discounts.reduce((acc, discount) => {
            return acc + discount.getDetailsFromOrder(this).total;
        }, itemDiscounts);
    }

    totalVATWithoutDiscount() {
        return this.items.reduce((acc, item) => {
            return acc + item.totalVATWithoutDiscount();
        }, 0);
    }

    async appendItem(
        product: ProductVariant,
        quantity: number = 1,
        salesChannel?: ProductSalesChannel,
    ) {
        let orderItem = this.items.find((item) => item.product?.$isSame(product));

        if (orderItem === undefined) {
            orderItem = new OrderItem();
            orderItem.quantity = quantity;
            orderItem.owner = product.owner;
            orderItem.price = product.price;
            orderItem.product = product;

            if (salesChannel === undefined) salesChannel = product.salesChannels[0];

            await salesChannel.tax?.$getMetadata().refresh();
            orderItem.tax = salesChannel?.tax?.tax;
            orderItem.taxName = salesChannel?.tax?.name;

            this.items.push(orderItem);
        } else orderItem.quantity += quantity;
    }

    removeItem(product: ProductVariant, quantity: number = 1) {
        const orderItemIndex = this.items.findIndex((item) =>
            item.product?.$isSame(product),
        );

        if (orderItemIndex === -1) return;

        const orderItem = this.items[orderItemIndex];

        orderItem.quantity -= quantity;
        if (orderItem.quantity <= 0) this.items.splice(orderItemIndex, 1);
    }

    isEmpty(): boolean {
        return this.items.length === 0 || this.items.every((item) => item.quantity === 0);
    }

    async getProductsCreditsFromTaxAndSaleChannel(
        taxValue: number,
        salesChannel: SalesChannel,
    ) {
        return this.credits.reduce(async (acc, credit) => {
            const tV = await acc;
            if (credit.items.length > 0) {
                let flag = false;
                await Promise.all(
                    credit.items.map(async (item) => {
                        if (item.product === undefined) return;
                        await item.product.$getMetadata().refresh();
                        await Promise.all(
                            item.product.salesChannels.map(async (saleChannel) => {
                                await saleChannel.channel?.$getMetadata().refresh();
                                await saleChannel.tax?.$getMetadata().refresh();
                            }),
                        );
                        if (
                            item.product?.salesChannels?.find((sc) => {
                                return (
                                    sc.channel?.$isSame(salesChannel) &&
                                    sc.tax?.tax === taxValue
                                );
                            })
                        ) {
                            flag = true;
                        }
                    }),
                );
                if (flag) return tV + (credit.amount || 0);
            }
            return tV;
        }, Promise.resolve(0));
    }

    async getProductsCreditsFromCategory(category: ProductCategory) {
        return this.credits.reduce(async (acc, credit) => {
            const tV = await acc;
            if (credit.items.length > 0) {
                let flag = false;
                await Promise.all(
                    credit.items.map(async (item) => {
                        if (item.product === undefined) return;
                        await item.product.$getMetadata().refresh();
                        await item.product.category?.$getMetadata().refresh();

                        if (item.product?.category?.$isSame(category)) {
                            flag = true;
                        }
                    }),
                );
                if (flag) return tV + (credit.amount || 0);
            }
            return tV;
        }, Promise.resolve(0));
    }

    async getTaxesValues() {
        if (this.saleChannel === undefined) return [];

        let totalReduce =
            this.discount() +
            this.credits.reduce((acc, credit) => {
                if (Array.isArray(credit.items) && credit.items.length > 0) return acc;
                return acc + (credit.amount || 0);
            }, 0);

        return Promise.all(
            Array.from(
                this.items
                    .reduce((taxMap, item) => {
                        const tax = taxMap.get(item.taxName!);
                        if (tax !== undefined) {
                            tax.totalPreTax += item.total;
                        } else {
                            taxMap.set(item.taxName!, {
                                value: item.tax!,
                                totalPreTax: item.total,
                            });
                        }
                        return taxMap;
                    }, new Map<string, { value: number; totalPreTax: number }>())
                    .entries(),
            )
                .sort((tax1, tax2) => {
                    return tax2[1].value - tax1[1].value;
                })
                .map(async ([key, value]) => {
                    let totalVAT = value.totalPreTax * (1 + value.value);
                    let totalPreTax = value.totalPreTax;

                    const productReduce =
                        await this.getProductsCreditsFromTaxAndSaleChannel(
                            value.value,
                            this.saleChannel,
                        );

                    if (productReduce > 0) {
                        totalVAT = totalVAT - productReduce;
                        totalPreTax = totalVAT / (1 + value.value);
                    }

                    if (totalReduce > 0) {
                        const totalVATTemp = totalVAT - totalReduce;

                        if (totalVATTemp < 0) {
                            totalReduce = totalReduce - totalVAT;
                            totalVAT = 0;
                            totalPreTax = 0;
                        } else {
                            totalReduce = 0;
                            totalPreTax = totalVATTemp / (1 + value.value);
                            totalVAT = totalVATTemp;
                        }
                    }
                    return {
                        name: key,
                        value: value.value,
                        totalPreTax: totalPreTax, // HT
                        total: totalVAT - totalPreTax, // Taxes
                        totalVAT: totalVAT, // TTC
                    };
                }),
        );
    }

    async getPaymentsValues() {
        const paymentsMap = new Map<PaymentMethod, number>();

        await this.payments.reduce(async (acc, payment) => {
            await acc;
            if (payment.payment === undefined || payment.amount === undefined) return;

            const totalReduce = this.credits.reduce((acc, credit) => {
                if (credit.payment?.$isSame(payment.payment))
                    return acc + (credit.amount || 0);
                return acc;
            }, 0);

            await payment.payment.$getMetadata().refresh();

            const total = paymentsMap.get(payment.payment);
            const amount = payment.amount - totalReduce; // * ratio;
            if (total === undefined) {
                paymentsMap.set(payment.payment, amount);
            } else {
                paymentsMap.set(payment.payment, total + amount);
            }
        }, Promise.resolve());
        return paymentsMap;
    }

    async getCategoriesValues() {
        if (this.saleChannel === undefined) return [];

        let totalReduce =
            this.discount() +
            this.credits.reduce((acc, credit) => {
                if (Array.isArray(credit.items) && credit.items.length > 0) return acc;
                return acc + (credit.amount || 0);
            }, 0);

        const categoriesMap = new Map<
            string,
            { name: string; amount: number; entity: ProductCategory }
        >();
        await this.items.reduce(async (promise, item) => {
            await promise;
            await item.product?.$getMetadata().refresh();
            await item.product?.category?.$getMetadata().refresh();

            let localReduce = 0;

            let category = item.product?.category;
            if (category === undefined) {
                if (item.product?.productId === undefined) return 0;
                const product = useDoc(Product, item.product?.productId);
                await product.$getMetadata().refresh();
                await product.category?.$getMetadata().refresh();
                category = product.category;
            }

            await category?.parentCategory?.$getMetadata().refresh();
            if (category?.parentCategory !== undefined) {
                let tmp = category.parentCategory;
                await tmp.parentCategory?.$getMetadata().refresh();
                while (tmp.parentCategory !== undefined) {
                    tmp = tmp.parentCategory;
                    await tmp.parentCategory?.$getMetadata().refresh();
                }
                category = tmp;
            }

            await this.credits.reduce(async (promise, credit) => {
                await promise;

                await credit.items.reduce(async (p1, creditItem) => {
                    if (creditItem.product === undefined) return;

                    await creditItem.product.$getMetadata().refresh();
                    // Ne pas appliquer deux fois la réduction
                    if (creditItem.product?.$isSame(item.product)) {
                        localReduce += credit.amount || 0;
                    }
                }, Promise.resolve());
            }, Promise.resolve());

            let categoryID = "";
            if (category !== undefined) categoryID = category.$getID() || "";

            const categoryMap = categoriesMap.get(categoryID);
            if (categoryMap !== undefined) {
                const amount = item.unitPrice * item.quantity;
                categoryMap.amount += amount - localReduce;
            } else {
                categoriesMap.set(categoryID, {
                    name: category?.name || "",
                    entity: category,
                    amount: item.unitPrice * item.quantity - localReduce,
                });
            }
            return 0;
        }, Promise.resolve(0));

        return Promise.all(
            Array.from(categoriesMap.entries())
                .sort((category1, category2) => category1.amount - category2.amount)
                .map(async ([key, value]) => {
                    const productReduce = await this.getProductsCreditsFromCategory(
                        value.entity,
                    );
                    let total = value.amount;

                    if (productReduce > 0) {
                        total = total - productReduce;
                    }

                    if (totalReduce > 0) {
                        const subTotal = total - totalReduce;

                        if (subTotal < 0) {
                            totalReduce = totalReduce - total;
                            total = 0;
                        } else {
                            totalReduce = 0;
                            total = subTotal;
                        }
                    }

                    return {
                        name: value.name,
                        id: key,
                        value: total,
                    };
                }),
        );
    }
}

export class Invoice extends Order {
    static collectionName = "invoices";

    @Var(Boolean)
    hasAcceptedGTC?: boolean;

    @Var(Boolean)
    hasRejectedDelay?: boolean;
}
