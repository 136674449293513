import { Owner } from "../owner";
import { Tax } from "./tax";
import { Entity, EntityBase, Input, Var } from "addeus-common-library/stores/firestore";
import { useUserSession } from "../../stores/userSession";

export enum SalesChannelType {
    pointOfSale = "pointOfSale",
    website = "website",
}

export class SalesChannel extends Entity {
    static collectionName = "salesChannels";

    @Var(String)
    @Input("text", {
        required: true,
    })
    name: string = "";

    @Var(SalesChannelType)
    @Input("select", {
        options: SalesChannelType,
        required: true,
    })
    type: SalesChannelType = SalesChannelType.pointOfSale;

    @Var(Owner)
    owner?: Owner;

    toString() {
        return this.name;
    }
}

export enum DistributionChannelType {
    onSite = "onSite",
    takeAway = "takeAway",
}

export class DistributionChannel extends Entity {
    static collectionName = "distributionChannels";

    @Var(String)
    @Input("text", {
        required: true,
    })
    name: string = "";

    @Var(DistributionChannelType)
    @Input("select", {
        options: DistributionChannelType,
        required: true,
    })
    type: DistributionChannelType = DistributionChannelType.onSite;

    @Var(Owner)
    owner?: Owner;

    @Var(Number)
    @Input("number", { required: true })
    weight: number = 0;

    toString() {
        return this.name;
    }
}

export class ProductDistributionChannel extends EntityBase {
    @Var(DistributionChannel)
    @Input("select", {
        required: true,
        options: {
            entity: DistributionChannel,
            where() {
                const userSession = useUserSession();
                return [["owner", "==", userSession.user?.owner?.$getID()]];
            },
        },
    })
    channel?: DistributionChannel;

    @Var(Number)
    @Input("number", { required: true })
    additionnalPrice: number = 0;

    toString() {
        return this.channel?.toString();
    }
}

export class ProductSalesChannel extends EntityBase {
    @Var(SalesChannel)
    @Input("select", {
        required: true,
        options: {
            entity: SalesChannel,
            where() {
                const userSession = useUserSession();
                return [["owner", "==", userSession.user?.owner?.$getID()]];
            },
        },
    })
    channel?: SalesChannel;

    @Var(Number)
    @Input("number", { required: true })
    additionnalPrice: number = 0;

    @Var(Tax)
    @Input("select", {
        required: true,
        options: {
            entity: Tax,
            where() {
                const userSession = useUserSession();
                return [["owner", "==", userSession.user?.owner?.$getID()]];
            },
        },
    })
    tax?: Tax;

    toString() {
        return this.channel?.toString();
    }
}
