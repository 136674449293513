<script lang="ts">
import { GoogleMap, Marker } from "vue3-google-map";
import { GeoPoint } from "firebase/firestore";
import { Address } from "../../models/address";
import { Employee } from "../../models/employee";
import { Permission } from "../../models/role";
import type { Owner } from "../../models/owner";
import { BusinessHour, SpeedLimitation, Website } from "../../models/owner";
import { Role } from "../../models/role";
import * as ComponentEmployee from "./employee.vue";
import { defineComponent, toRefs, computed, ref, watch } from "vue";
import * as yup from "yup";
import { useDoc, newDoc, useCollection } from "addeus-common-library/stores/firestore";
import moment from "moment-with-locales-es6";
import {
    Currencies,
    PaymentMethod,
    PaymentType,
} from "../../models/product/paymentMethod";
import { RankingType, Type as RaceType } from "../../models/race";
import { GroupType } from "../../models/race/group";
import { CurrencySymbol } from "addeus-common-library/stores/currency";
import { WebsiteFeature } from "../../models/owner";
import { getTimezonesForCountry } from "countries-and-timezones";
import { useTranslate } from "addeus-common-library/stores/translate";

// const doc = useDoc('ejrghdekejhrht-ej4rh'/* uid */);
// await doc.$getMetadata().isFullfilling;
const permissions = [
    Permission.employee_view,
    Permission.employee_edit,
    Permission.customer_view,
    Permission.customer_edit,
    Permission.role_view,
    Permission.role_edit,
];

export function modelEvents() {
    async function updateEmployeeOwner(employeeID: string, owner: Owner, role?: Role) {
        const doc: Employee = useDoc(Employee, employeeID);
        await doc.$getMetadata().refresh();
        await doc.$getMetadata().waitFullfilled();
        if (doc.role === undefined && role !== undefined) doc.role = role;
        if (doc.owner === undefined) doc.owner = owner;
        await doc.$save();
    }
    return {
        async onCreate(owner: Owner) {
            let manager = newDoc(Role);
            manager.name = "Gérant";
            manager.permissions = permissions;
            manager.owner = owner;
            await manager.$save();
            if (owner.manager !== undefined) {
                await updateEmployeeOwner(owner.manager, owner, manager);
            }

            /**
             * Generate Default Session Race Type
             */
            const raceTypeSession = newDoc(RaceType);
            raceTypeSession.duration = 480000;
            raceTypeSession.maxParticipants = 8;
            raceTypeSession.name = "8 Minutes";
            raceTypeSession.rankingType = RankingType.bestTime;
            raceTypeSession.owner = owner;
            await raceTypeSession.$save();

            const raceGroupTypeSession = newDoc(GroupType);
            raceGroupTypeSession.name = "Session";
            raceGroupTypeSession.owner = owner;
            raceGroupTypeSession.raceTypes.push(raceTypeSession);
            await raceGroupTypeSession.$save();

            /**
             * Generate Default Championship Race Type
             */
            const raceTypeFreeTrial = newDoc(RaceType);
            raceTypeFreeTrial.duration = 480000;
            raceTypeFreeTrial.maxParticipants = 8;
            raceTypeFreeTrial.name = "Essai Libre";
            raceTypeFreeTrial.rankingType = RankingType.bestTime;
            raceTypeFreeTrial.owner = owner;
            await raceTypeFreeTrial.$save();

            const raceTypeQualification = newDoc(RaceType);
            raceTypeQualification.round = 12;
            raceTypeQualification.maxParticipants = 8;
            raceTypeQualification.name = "Qualification";
            raceTypeQualification.rankingType = RankingType.firstArrived;
            raceTypeQualification.owner = owner;
            await raceTypeQualification.$save();

            const raceGroupTypeChampionship = newDoc(GroupType);
            raceGroupTypeChampionship.name = "Championnat";
            raceGroupTypeChampionship.owner = owner;
            raceGroupTypeChampionship.raceTypes.push(raceTypeFreeTrial);
            raceGroupTypeChampionship.raceTypes.push(raceTypeQualification);
            await raceGroupTypeChampionship.$save();

            const bankingCard = newDoc(PaymentMethod);
            bankingCard.currency = Currencies.Euro;
            bankingCard.type = PaymentType.BankingCard;
            bankingCard.owner = owner;
            bankingCard.canRefund = false;
            await bankingCard.$save();

            const cash = newDoc(PaymentMethod);
            cash.currency = Currencies.Euro;
            cash.type = PaymentType.Cash;
            cash.owner = owner;
            cash.canRefund = false;
            await cash.$save();
        },
    };
}

export default defineComponent({
    components: {
        GoogleMap,
        M: Marker,
    },
    props: {
        form: {
            type: Object,
            required: true,
        },
    },
    setup(props: any) {
        const { form: owner } = toRefs(props);

        const map = ref(null);
        const { translate } = useTranslate();

        const days: moment[] = [];
        const start = moment().startOf("week");
        const end = moment().endOf("week");
        for (var day = start; day.isBefore(end) === true; day.add(1, "day")) {
            days.push(day.clone());
        }

        let employees: Employee[] = [];
        if (owner.value.$isNew() === false) {
            employees = useCollection(Employee, {
                wheres: [["owner", "==", owner.value.$getID()]],
                limit: -1,
            });
        }

        const stringSchema = yup.string();

        const timeZoneOptions = computed(() => {
            const country = (owner.value as Owner).address?.country ?? "";
            return (getTimezonesForCountry(country) ?? []).map((tz) => {
                return {
                    value: tz.name,
                    id: tz.name,
                    label: tz.name,
                };
            });
        });

        const currencyOptions = computed(() => {
            return Object.keys(CurrencySymbol).map((key) => {
                return {
                    value: key,
                    id: key,
                    label: `${key} (${CurrencySymbol[key]})`,
                };
            });
        });

        //

        watch(owner.value.address, async () => {
            if (map.value === null) return;
            const geocoder = new map.value.api.Geocoder();
            const { results } = await geocoder.geocode({
                address: owner.value.address.toString(),
            });
            owner.value.position = new GeoPoint(
                results[0].geometry.location.lat(),
                results[0].geometry.location.lng(),
            );
        });
        return {
            owner,
            employees,
            currencyOptions,
            timeZoneOptions,
            employeeType: Employee,
            addressType: Address,
            businessHourType: BusinessHour,
            websiteType: Website,
            websiteFeatureType: WebsiteFeature,
            employeeComponent: ComponentEmployee,
            speedLimitationType: SpeedLimitation,
            days,
            stringSchema,
            map,
            translate,
        };
    },
    methods: {
        setPosition(position: any) {
            this.owner.position = new GeoPoint(position.lat(), position.lng());
        },
    },
});
</script>

<template>
    <VMultipage
        v-model="owner"
        mode="wizard"
        :steps="[
            translate('.wizard.generalInfo').value,
            translate('.wizard.planning').value,
            translate('.wizard.web').value,
            translate('.wizard.settings').value,
        ]">
        <template #step-1>
            <VFieldModel v-model="owner" property="name"></VFieldModel>
            <VFieldModel v-model="owner" property="registration"></VFieldModel>
            <VFieldModel v-model="owner" property="phoneNumber"></VFieldModel>
            <VFieldModel v-model="owner" property="mail"></VFieldModel>
            <VFieldModel v-model="owner" property="saleEmail"></VFieldModel>
            <VFieldModel
                v-model="owner"
                property="currency"
                :select-options="currencyOptions"></VFieldModel>
            <VEntities
                v-slot="{ field }"
                v-model="owner.address"
                :model="addressType"
                property="address"
                opened>
                <VFieldModel v-model="field.value" property="street"></VFieldModel>
                <VFieldModel v-model="field.value" property="postalCode"></VFieldModel>
                <VFieldModel v-model="field.value" property="city"></VFieldModel>
                <VFieldModel v-model="field.value" property="country"></VFieldModel>
                <GoogleMap
                    ref="map"
                    api-key="AIzaSyA_8uX6qS6UqOTeOt6mnQPDF_r3t0dt3ZQ"
                    style="width: 100%; height: 300px"
                    :zoom="6"
                    :map-type-control="false"
                    :street-view-control="false"
                    :center="
                        owner.position
                            ? {
                                  lat: owner.position.latitude,
                                  lng: owner.position.longitude,
                              }
                            : { lat: 45.9966825, lng: -2.6871231 }
                    ">
                    <M
                        v-if="owner.position"
                        :options="{
                            draggable: true,
                            position: {
                                lat: owner.position.latitude,
                                lng: owner.position.longitude,
                            },
                        }"
                        @dragend="setPosition($event.latLng)">
                    </M>
                </GoogleMap>
            </VEntities>

            <VEntities
                v-if="owner.$isNew()"
                v-slot="entity"
                v-model="owner.manager"
                :model="employeeType"
                :required="false"
                only-ids
                property="manager"
                :opened="true"
                @update:model-value="(newValue: String) => (owner.manager = newValue)">
                <VModel
                    :component="employeeComponent.default"
                    :model="entity.field.value"
                    :options="{ fromOwner: true }"></VModel>
            </VEntities>

            <TranslateNamespace v-if="!owner.$isNew()" path=".manager">
                <VValidation
                    v-slot="{ field }"
                    v-model="owner.manager"
                    property="manager"
                    :schema="stringSchema">
                    <VField label=".label" :has-label="true">
                        <VControl>
                            <VSelect v-model="field.value">
                                <VOption
                                    v-for="employee in employees"
                                    :key="employee.$getID()"
                                    :value="employee.$getID()">
                                    {{ employee.toString() }}
                                </VOption>
                            </VSelect>
                        </VControl>
                    </VField>
                </VValidation>
            </TranslateNamespace>

            <TranslateNamespace v-if="!owner.$isNew()" path=".accounting">
                <VValidation
                    v-slot="{ field }"
                    v-model="owner.accounting"
                    property="accounting"
                    :schema="stringSchema">
                    <VField label=".label" :has-label="true">
                        <VControl :has-error="field.errors.length > 0">
                            <VSelect v-model="field.value">
                                <VOption
                                    v-for="employee in employees"
                                    :key="employee.$getID()"
                                    :value="employee.$getID()">
                                    {{ employee.toString() }}
                                </VOption>
                            </VSelect>
                        </VControl>
                    </VField>
                </VValidation>
            </TranslateNamespace>

            <TranslateNamespace v-if="!owner.$isNew()" path=".technician">
                <VValidation
                    v-slot="{ field }"
                    v-model="owner.technician"
                    property="technician"
                    :schema="stringSchema">
                    <VField label=".label" :has-label="true">
                        <VControl>
                            <VSelect v-model="field.value">
                                <VOption
                                    v-for="employee in employees"
                                    :key="employee.$getID()"
                                    :value="employee.$getID()">
                                    {{ employee.toString() }}
                                </VOption>
                            </VSelect>
                        </VControl>
                    </VField>
                </VValidation>
            </TranslateNamespace>
        </template>
        <template #step-2>
            <VEntities
                v-slot="{ field }"
                v-model="owner.businessHours"
                :model="businessHourType"
                property="businessHours"
                :opened="owner.$isNew()"
                multiple
                sortable>
                <VFlex flex-direction="column">
                    <VFieldModel
                        v-slot="{ field: fieldDaysOfWeek }"
                        v-model="field.value"
                        property="daysOfWeek">
                        <VFlex flex-direction="row" flex-wrap="wrap">
                            <VFlexItem
                                v-for="day in days"
                                :key="day.day()"
                                flex-basis="33%"
                                class="py-2">
                                <VField>
                                    <VLabel>{{ day.format("dddd") }}</VLabel>
                                    <VCheckbox
                                        v-model="fieldDaysOfWeek.value"
                                        :value="day.day()"
                                        multiple>
                                    </VCheckbox>
                                </VField>
                            </VFlexItem>
                        </VFlex>
                    </VFieldModel>
                    <VFlex
                        flex-direction="row"
                        align-items="center"
                        justify-content="space-between">
                        <VFieldModel
                            v-slot="{ field: fieldStartTime }"
                            v-model="field.value"
                            property="startTime">
                            <VInputTime
                                v-model="fieldStartTime.value"
                                to-string
                                :min-hour="5"
                                :max-hour="29"></VInputTime>
                        </VFieldModel>
                        <VIcon icon="arrow_right_alt"></VIcon>
                        <VFieldModel
                            v-slot="{ field: fieldEndTime }"
                            v-model="field.value"
                            property="endTime">
                            <VInputTime
                                v-model="fieldEndTime.value"
                                to-string
                                :min-hour="5"
                                :minute-step="15"
                                :max-hour="29"></VInputTime>
                        </VFieldModel>
                    </VFlex>
                </VFlex>
            </VEntities>
        </template>
        <template #step-3>
            <VEntities
                v-slot="{ field }"
                v-model="owner.website"
                :model="websiteType"
                property="website"
                opened>
                <VFieldModel v-model="field.value" property="pictures"></VFieldModel>
                <VFieldModel v-model="field.value" property="video"></VFieldModel>

                <VEntities
                    v-slot="{ field: fieldFeatures }"
                    v-model="field.value.features"
                    :model="websiteFeatureType"
                    property="features"
                    :opened="owner.$isNew()"
                    multiple>
                    <VFlex flex-direction="column">
                        <VFieldModel v-model="fieldFeatures.value" property="name">
                        </VFieldModel>
                        <VFieldModel v-model="fieldFeatures.value" property="icon">
                        </VFieldModel>
                    </VFlex>
                </VEntities>

                <VFieldModel
                    v-model="field.value"
                    property="priceBirthdayPackGold"></VFieldModel>
                <VFieldModel
                    v-model="field.value"
                    property="priceBirthdayPackSilver"></VFieldModel>
                <VFieldModel
                    v-model="field.value"
                    property="priceBirthdayPackBronze"></VFieldModel>

                <VFieldModel
                    v-model="field.value"
                    property="priceWeddingPack"></VFieldModel>
                <VFieldModel
                    v-model="field.value"
                    property="priceWeddingPackOption1"></VFieldModel>
                <VFieldModel
                    v-model="field.value"
                    property="priceWeddingPackOption2"></VFieldModel>
                <VFieldModel
                    v-model="field.value"
                    property="priceWeddingPackOption3"></VFieldModel>

                <VFieldModel
                    v-model="field.value"
                    property="priceAfterWorkPack"></VFieldModel>
                <VFieldModel
                    v-model="field.value"
                    property="priceRacing1901"></VFieldModel>
                <VFieldModel
                    v-model="field.value"
                    property="priceRacing1901Option"></VFieldModel>
                <VFieldModel
                    v-model="field.value"
                    property="priceStudentKart"></VFieldModel>
                <VFieldModel
                    v-model="field.value"
                    property="priceStudentKartOption1"></VFieldModel>
                <VFieldModel
                    v-model="field.value"
                    property="priceStudentKartOption2"></VFieldModel>
                <VFieldModel
                    v-model="field.value"
                    property="priceStudentKartOption3"></VFieldModel>
            </VEntities>
        </template>
        <template #step-4>
            <VFieldModel v-model="owner" property="availableOnline"></VFieldModel>
            <VFieldModel v-model="owner" property="bookableOnline"></VFieldModel>
            <VFieldModel v-model="owner" property="hasFullTrackConfig"></VFieldModel>
            <VFieldModel v-model="owner" property="defaultSlotDuration"></VFieldModel>
            <VFieldModel
                v-model="owner"
                property="timeZoneName"
                :select-options="timeZoneOptions">
            </VFieldModel>
            <VFieldModel v-model="owner" property="numberOfRankingDays"></VFieldModel>
            <VEntities
                v-slot="{ field }"
                v-model="owner.speedLimitations"
                :model="speedLimitationType"
                property="speedLimitations"
                :opened="owner.$isNew()"
                multiple>
                <VFlex flex-direction="column">
                    <VFieldModel v-model="field.value" property="name"> </VFieldModel>
                    <VFieldModel v-model="field.value" property="speed"> </VFieldModel>
                    <VFieldModel v-model="field.value" property="minAge"> </VFieldModel>
                    <VFieldModel v-model="field.value" property="maxAge"> </VFieldModel>
                </VFlex>
            </VEntities>
        </template>
    </VMultipage>
</template>
