<script lang="ts">
import { defineComponent, toRefs } from "vue";
import * as yup from "yup";
import { useUserSession } from "/@src/stores/userSession";
import type { Track } from "/@src/models/race/track";
import { Circuit } from "/@src/models/race/circuit";
import { Device } from "/@src/models/race/device";
import { string } from "yup";
import { Coating } from "../../models/race/track";

export function modelEvents() {
    return {
        async onBeforeCreate(track: Track) {
            const { user } = useUserSession();
            track.owner = user.owner;
        },

        async onCreate(track: Track) {
            const { user } = useUserSession();
            await Promise.all(
                track.circuits.map(async (circuit) => {
                    if (circuit.owner === undefined || circuit.track === undefined) {
                        circuit.owner = user.owner;
                        circuit.track = track.$getID();
                        await circuit.$save();
                    }
                })
            );
        },

        async onEdit(track: Track) {
            const { user } = useUserSession();
            await Promise.all(
                track.circuits.map(async (circuit) => {
                    if (circuit.owner === undefined || circuit.track === undefined) {
                        circuit.owner = user.owner;
                        circuit.track = track.$getID();
                        await circuit.$save();
                    }
                })
            );
        },
    };
}

export default defineComponent({
    props: {
        form: {
            type: Object,
            required: true,
        },
    },
    emits: ["cancel"],
    setup(props: any) {
        const { form: track } = toRefs(props);
        const { user } = useUserSession();
        const coatingsSchema = yup.array().of(yup.string()).nullable();
        return {
            track,
            Device,
            Coating,
            string,
            user,
            coatingsSchema,
            circuitType: Circuit,
        };
    },
});
</script>

<template>
    <div>
        <VFieldModel v-model="track" property="name"></VFieldModel>
        <VFieldModel v-model="track" property="description"></VFieldModel>
        <VFieldModel v-model="track" property="picture"></VFieldModel>
        <VEntities
            v-slot="{ field }"
            v-model="track.coatings"
            :model="Coating"
            multiple
            opened
            property="coatings">
            <VFieldModel v-model="field.value" property="type"></VFieldModel>
            <VFieldModel v-model="field.value" property="quality"></VFieldModel>
        </VEntities>
        <VEntities
            v-slot="{ field }"
            v-model="track.circuits"
            :model="circuitType"
            multiple
            :opened="track.$isNew()"
            property="circuits">
            <VFieldModel v-model="field.value" property="name"></VFieldModel>
            <VFieldModel v-model="field.value" property="length"></VFieldModel>
            <VFieldModel v-model="field.value" property="maxParticipants"></VFieldModel>

            <VEntities
                v-slot="{ field: deviceField }"
                v-model="field.value.gates"
                :schema="
                    string()
                        .defined('validation.required')
                        .required('validation.required')
                "
                property="gates"
                :opened="true"
                :save="false"
                multiple
                sortable>
                <VField label=".label">
                    <VControl :has-error="deviceField.errors.length > 0">
                        <VSelectModel
                            v-model="deviceField.value"
                            :model="Device"
                            required
                            :where="[
                                ['kind', '==', 'gate'],
                                ['owner', '==', user.owner.$getID()],
                            ]"></VSelectModel>
                        <p
                            v-for="error in deviceField.errors"
                            :key="error"
                            class="help is-danger">
                            <Translate>.{{ error }}</Translate>
                        </p>
                    </VControl>
                </VField>
            </VEntities>
        </VEntities>
    </div>
</template>
