import type moment from "moment-with-locales-es6";
import { Race } from "../models/race";
import { useCollection } from "addeus-common-library/stores/firestore";
import { useUserSession } from "./userSession";
import type { WhereOption } from "addeus-common-library/stores/firestore";
import { acceptHMRUpdate, defineStore } from "pinia";

export const useRaceShift = defineStore("race-composition-actions", () => {
    const userSession = useUserSession();
    const shiftRaces = async (
        start: moment,
        end: moment,
        excludeRaceIds: string[]
    ): Promise<void> => {
        const races = (await getRacesBySlot(start, end))
            .filter((race) => !excludeRaceIds.includes(race.$getID()!))
            .sort((a, b) => a.date.unix() - b.date.unix());
        if (races.length === 0) return;
        const lastRaceDate = end.clone();
        await Promise.all(
            races.map((race) => {
                race.date = lastRaceDate.clone();
                lastRaceDate.add(10, "minutes");
                return race.$save();
            })
        );
        await shiftRaces(
            end,
            lastRaceDate,
            races.map((race) => race.$getID()!)
        );
    };
    const getRacesBySlot = async (start: moment, end: moment): Promise<Race[]> => {
        const wheres: WhereOption[] = [
            ["owner", "==", userSession.user?.owner?.$getID()],
            ["date", ">=", start.toDate()],
            ["date", "<", end.toDate()],
        ];
        const races = useCollection(Race, {
            wheres,
        });
        await races.fetched();
        return races;
    };

    return {
        shiftRaces,
        getRacesBySlot,
    };
});
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useRaceShift, import.meta.hot));
}
