enum Kind {
    broadcast = "broadcast",
    gate = "gate",
    kart = "kart",
    trafficSignal = "trafficSignal",
}

enum Type {
    ASSIGNED = "assigned",
    COLOR = "color",
    END = "endRace",
    GATE = "gate",
    LIGHT = "light",
    NONE = "none",
    SPEED = "speed",
    START = "startRace",
    STATUS = "status",
    VERSION = "version",
    VOLTAGE = "voltage",
}

class Message {
    type: Type;
    device: Kind;
    reference: string;
    values: { [key: string]: number | string };

    constructor(type: Type, device: Kind, reference: string) {
        this.type = type;
        this.device = device;
        this.reference = reference;
        this.values = {};
    }

    toJson(): string {
        const values: any = {};
        Object.keys(this.values).forEach((key) => {
            values[key] = this.values[key];
        });
        const obj = {
            type: this.type,
            device: this.device,
            reference: this.reference,
            values,
        };
        return JSON.stringify(obj);
    }
}

export { Kind, Type, Message };
