<script lang="ts">
import { defineComponent, toRefs } from "vue";
import { useUserSession } from "/@src/stores/userSession";
import { ProjectorFrame, ProjectorScenario } from "../../../models/race/item";

export function modelEvents() {
    return {
        async onBeforeCreate(type: any) {
            const { user } = useUserSession();
            if (user === undefined || user === null) throw new Error("No owner selected");
            type.owner = user.owner;
        },

        async onBeforeEdit(type: any) {
            const { user } = useUserSession();
            if (user === undefined || user === null) throw new Error("No owner selected");
            type.owner = user.owner;
        },
    };
}

export default defineComponent({
    props: {
        form: {
            type: Object,
            required: true,
        },
    },
    setup(props: any) {
        const { form: type } = toRefs(props);

        return {
            type,
            ProjectorFrame,
            ProjectorScenario,
        };
    },
});
</script>

<template>
    <div>
        <VFieldModel v-model="type" property="name"></VFieldModel>
        <VFieldModel v-model="type" property="duration"></VFieldModel>
        <VFieldModel v-model="type" property="round"></VFieldModel>
        <VFieldModel v-model="type" property="maxParticipants"></VFieldModel>
        <VFieldModel v-model="type" property="rankingType"></VFieldModel>
        <VFieldModel v-model="type" property="fillParticipantsType"></VFieldModel>
        <VFieldModel v-model="type" property="orderParticipantsType"></VFieldModel>
        <VEntities
            v-slot="{ field: projectorScenario }"
            v-model="type.projectorScenarios"
            :model="ProjectorScenario"
            property="projectorScenarios"
            multiple
            :opened="true"
            sortable>
            <VFieldModel
                v-model="projectorScenario.value"
                property="projector"></VFieldModel>
            <VEntities
                v-slot="{ field: projectorFrame }"
                v-model="projectorScenario.value.frames"
                :model="ProjectorFrame"
                property="frames"
                multiple
                :opened="true"
                sortable>
                <VFieldModel v-model="projectorFrame.value" property="item"></VFieldModel>
                <VFieldModel
                    v-model="projectorFrame.value"
                    property="duration"></VFieldModel>
            </VEntities>
        </VEntities>
    </div>
</template>
