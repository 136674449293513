import { useStorage } from "@vueuse/core";
import { createI18n as createClientI18n } from "vue-i18n";
import moment from "moment-with-locales-es6";

/**
 * messages are generated using vite-plugin-i18n
 * each .json files located in the ./src/locales are registered in messages
 * @see https://github.com/intlify/bundle-tools/tree/main/packages/vite-plugin-vue-i18n
 */
import messages from "addeus-common-library/stores/locale";

const DEFAULT_LOCALE = "fr";

export function createI18n() {
    const locale = navigator?.language;
    const defaultNavigatorLocale = locale?.split("-")[0];

    const navigatorLocale = useStorage(
        "locale",
        defaultNavigatorLocale || DEFAULT_LOCALE
    );
    const i18n = createClientI18n({
        globalInjection: true,
        legacy: false,
        locale: navigatorLocale.value,
        messages,
        fallbackLocale: DEFAULT_LOCALE,
    });
    moment.locale(navigatorLocale.value);

    return i18n;
}
