import { Entity, Var } from "addeus-common-library/stores/firestore";

export class Speed extends Entity {
    static collectionName = "kartSpeeds";

    @Var(String)
    name?: string;

    @Var(Number)
    speed: number = 0;

    @Var(Number) // acceleration * speed ^ 2
    acceleration: number = 0;

    @Var(String)
    icon?: string;

    @Var(String)
    color?: string;

    @Var(Boolean)
    canBeMerged: boolean = false;

    @Var(Number)
    weight: number = 0;

    toString() {
        return this.name;
    }
}
