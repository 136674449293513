import { Entity, Var, Input } from "addeus-common-library/stores/firestore";
import { Device } from "./device";
import { Owner } from "../owner";
import { EntityArray } from "addeus-common-library/stores/firestore/entity";

export class Circuit extends Entity {
    static collectionName = "circuits";
    @Var(String)
    @Input("text", { required: true })
    name?: string;

    @Var(String)
    track?: string;

    @Var(Number)
    @Input("number", { required: true })
    length: number = 0;

    @Var(Array.of(Device))
    @Input("array", { of: Device })
    gates: Device[] = EntityArray();

    @Var(Number)
    @Input("number", { required: true })
    maxParticipants: number = 8;

    @Var(Boolean)
    removeFirstLap: boolean = true;

    @Var(Owner)
    owner?: Owner;

    toString() {
        return this.name;
    }
}
