import {
    Entity,
    EntityBase,
    Input,
    Var,
} from "addeus-common-library/stores/firestore/index";
import moment from "moment-with-locales-es6";
import { Owner } from "./owner";
import { Role } from "./role";
import { useUserSession } from "/@src/stores/userSession";

export class Size extends EntityBase {
    @Var(String)
    @Input("text")
    shirt?: string;

    @Var(String)
    @Input("text")
    pant?: string;

    @Var(String)
    @Input("text")
    shoe?: string;
}

export class Employee extends Entity {
    static collectionName = "employees";

    @Var(String)
    @Input("text", { required: true })
    firstName?: string;

    @Var(String)
    @Input("text", { required: true })
    lastName?: string;

    @Var(Boolean)
    @Input("checkbox")
    isExternal: boolean = false;

    @Var(String)
    @Input("email", {
        updateOn: "phoneNumber",
        validate: [
            "email",
            [
                "one-required",
                (value, row) => {
                    return typeof row.phoneNumber === "string" &&
                        row.phoneNumber.length > 0
                        ? row.phoneNumber
                        : value;
                },
            ],
        ],
    })
    email?: string;

    @Var(String)
    @Input("phone", {
        updateOn: "email",
        validate: [
            [
                "one-required",
                (value, row) => {
                    return typeof row.email === "string" && row.email.length > 0
                        ? row.email
                        : value;
                },
            ],
        ],
    })
    phoneNumber?: string;

    @Var(String)
    @Input("file")
    picture?: string;

    @Var(Size)
    size?: Size = new Size();

    @Var(Role)
    @Input("select", {
        required: true,
        options: {
            entity: Role,
            where() {
                const userSession = useUserSession();
                return [["owner", "==", userSession.user.owner?.$getID()]];
            },
        },
    })
    role?: Role;

    @Var(moment)
    vacateAt: moment | null = null;

    @Var(String)
    user?: string;

    @Var(Owner)
    owner?: Owner;

    toString() {
        let name = `${this.firstName} ${this.lastName}`;
        if (this.firstName === undefined && this.lastName !== undefined)
            name = this.lastName;
        else if (this.firstName !== undefined && this.lastName === undefined)
            name = this.firstName;
        else name = "";

        let sub = null;

        if (this.email !== undefined) sub = `${this.email}`;
        if (this.phoneNumber !== undefined)
            sub = `${sub !== null ? sub + "/" : ""}${this.phoneNumber}`;

        return sub !== undefined ? `${name} (${sub})` : name;
    }
}
