import moment from "moment-with-locales-es6";
import { ProductVariant } from ".";
import { ProductCategory } from ".";
import { Owner } from "../owner";
import { SalesChannel } from "./channel";
import { Entity, Var, Input } from "addeus-common-library/stores/firestore";
import { EntityBase } from "addeus-common-library/stores/firestore";
import { useUserSession } from "../../stores/userSession";
import { Order, OrderItem } from "./order";
import { EntityArray } from "addeus-common-library/stores/firestore/entity";

export enum DiscountType {
    Product,
    Order,
    FreeShipping,
}

export enum DiscountMethod {
    Automatic,
    Manual,
}

export enum MinimumConditionType {
    Price,
    Count,
    None,
}

export class MinimumCondition extends EntityBase {
    @Var(MinimumConditionType)
    @Input("radio", {
        options: MinimumConditionType,
        required: true,
    })
    type: MinimumConditionType = MinimumConditionType.None;

    @Var(Number)
    @Input("number", {
        required: true,
    })
    value: number = 0;

    @Var(Array.of(ProductVariant))
    @Input("select", {
        multiple: true,
        options: {
            entity: ProductVariant,
            where() {
                const userSession = useUserSession();
                return [["owner", "==", userSession.user.owner?.$getID()]];
            },
        },
    })
    products?: ProductVariant[] = EntityArray();

    @Var(Array.of(ProductCategory))
    @Input("select", {
        multiple: true,
        options: {
            entity: ProductCategory,
            where() {
                const userSession = useUserSession();
                return [["owner", "==", userSession.user.owner?.$getID()]];
            },
        },
    })
    collections?: ProductCategory[] = EntityArray();
}

export enum DiscountValueType {
    Percentage,
    Amount,
    Free,
}

export class Discount extends Entity {
    static collectionName = "discounts";

    @Var(String)
    @Input("text", {
        required: true,
    })
    name: string = "";

    @Var(DiscountType)
    @Input("radio", {
        options: DiscountType,
        required: true,
    })
    type: DiscountType = DiscountType.Product;

    @Var(Number)
    @Input("number", {
        required: true,
    })
    value: number = 0;

    @Var(DiscountValueType)
    @Input("radio", {
        required: true,
    })
    valueType: DiscountValueType = DiscountValueType.Amount;

    @Var(Array.of(ProductVariant))
    @Input("select", {
        multiple: true,
        options: {
            entity: ProductVariant,
            where() {
                const userSession = useUserSession();
                return [["owner", "==", userSession.user.owner?.$getID()]];
            },
        },
    })
    products: ProductVariant[] = EntityArray();

    @Var(Array.of(ProductCategory))
    @Input("select", {
        multiple: true,
        options: {
            entity: ProductCategory,
            where() {
                const userSession = useUserSession();
                return [["owner", "==", userSession.user.owner?.$getID()]];
            },
        },
    })
    categories: ProductCategory[] = EntityArray();

    @Var(Array.of(SalesChannel))
    @Input("select", {
        required: true,
        multiple: true,
        options: {
            entity: SalesChannel,
            where() {
                const userSession = useUserSession();
                return [["owner", "==", userSession.user?.owner?.$getID()]];
            },
        },
    })
    saleChannels: SalesChannel[] = EntityArray();

    @Var(DiscountMethod)
    @Input("radio", {
        options: DiscountMethod,
        required: true,
    })
    method: DiscountMethod = DiscountMethod.Automatic;

    @Var(MinimumCondition)
    minimumCondition: MinimumCondition = new MinimumCondition();

    @Input("checkbox")
    @Var(Boolean)
    canBeCombinedWithOtherProductDiscounts: boolean = true;

    @Input("checkbox")
    @Var(Boolean)
    canBeCombinedWithOtherShipDiscounts: boolean = true;

    @Var(Number)
    @Input("number")
    maximumUsageAmount?: number;

    @Var(Number)
    @Input("number")
    maximumUsagePerCustomer: number = 1;

    @Var(moment)
    @Input("datetime", { required: true })
    start: moment;

    @Var(moment)
    @Input("datetime", { required: true })
    end?: moment;

    @Var(Owner)
    owner?: Owner;

    @Var(String)
    @Input("text", { required: true })
    code: string = "";

    @Var(Number)
    numberOfUsage: number = 0;

    @Var(Object)
    numberOfUsagePerCustomer: { [key: string]: number } = {};

    canApply(order: Order): boolean {
        const currentTime = moment();

        if (this.end.isBefore(currentTime) === true) return false;
        else if (this.start.isSameOrAfter(currentTime) === true) return false;

        if (this.type === DiscountType.FreeShipping) {
            return false;
        }

        const currentDiscountQuantity = order.allDiscounts.reduce((acc, discount) => {
            if (discount.$isSame(this) === true) return acc + 1;
            return acc;
        }, 1);

        if (
            this.maximumUsageAmount !== undefined &&
            currentDiscountQuantity > this.maximumUsageAmount
        )
            return false;

        if (
            this.minimumCondition.type === MinimumConditionType.None &&
            this.method === DiscountMethod.Automatic &&
            currentDiscountQuantity > 1
        )
            return false;

        const currentDiscountPriceWithMinimumCondition =
            this.minimumCondition.value * currentDiscountQuantity;

        if (
            this.minimumCondition.type === MinimumConditionType.Price &&
            currentDiscountPriceWithMinimumCondition > order.totalVATWithoutDiscount()
        )
            return false;

        const minimumConditionQuantity = order.items.reduce((acc, item) => {
            const isInProducts = !!this.minimumCondition.products?.find((product) =>
                product.$isSame(item.product)
            );
            const isInCategories = !!this.minimumCondition.collections?.find((category) =>
                category.$isSame(item.product?.category)
            );

            if (isInProducts || isInCategories) return acc + item.quantity;
            return acc;
        }, 0);

        const currentDiscountQuantityWithMinimumCondition =
            minimumConditionQuantity -
            (currentDiscountQuantity - 1) * this.minimumCondition.value;

        if (
            this.minimumCondition.type === MinimumConditionType.Count &&
            this.minimumCondition.value > currentDiscountQuantityWithMinimumCondition
        )
            return false;

        const isInSaleChannels = !!this.saleChannels.find((channel) =>
            channel.$isSame(order.saleChannel)
        );

        if (!isInSaleChannels) return false;

        if (!this.canBeCombinedWithOtherProductDiscounts) {
            const hasProductDiscount = !!order.discounts.find(
                (discount) =>
                    discount.type === DiscountType.Product &&
                    discount.$getID() !== this.$getID()
            );
            if (hasProductDiscount) return false;
        }

        if (!this.canBeCombinedWithOtherShipDiscounts) {
            const hasShipDiscount = !!order.discounts.find(
                (discount) =>
                    discount.type === DiscountType.FreeShipping &&
                    discount.$getID() !== this.$getID()
            );
            if (hasShipDiscount) return false;
        }

        if (
            this.maximumUsageAmount !== undefined &&
            this.maximumUsageAmount <= this.numberOfUsage + currentDiscountQuantity
        )
            return false;

        if (this.maximumUsagePerCustomer !== 0 && order.customer !== undefined) {
            const customerID = order.customer.$getID();
            if (customerID !== undefined) {
                const numberOfUsagePerCustomer =
                    this.numberOfUsagePerCustomer[customerID] ||
                    0 + currentDiscountQuantity;

                if (
                    numberOfUsagePerCustomer !== undefined &&
                    numberOfUsagePerCustomer >= this.maximumUsagePerCustomer
                )
                    return false;
            }
        }
        return true;
    }

    apply(order: Order) {
        if (this.type === DiscountType.Order) {
            do {
                const isInOrder =
                    order.discounts.find((d) => d.$isSame(this)) !== undefined;
                const canApply = this.canApply(order);
                if (canApply) {
                    order.discounts.push(this);
                } else if (isInOrder && !canApply) {
                    const index = order.discounts.findIndex(
                        (d) => d.$getID() === this.$getID()
                    );
                    order.discounts.splice(index, 1);
                }
            } while (this.canApply(order) && this.method === DiscountMethod.Automatic);
        } else if (this.type === DiscountType.Product) {
            order.items.forEach((item) => {
                if (
                    this.products.find((p) => p.$isSame(item.product)) ||
                    this.categories.find((c) => c.$isSame(item.product?.category))
                ) {
                    do {
                        const isInOrder =
                            item.discounts.find((d) => d.$getID() === this.$getID()) !==
                            undefined;
                        const canApply = this.canApply(order);
                        if (canApply) {
                            item.discounts.push(this);
                        } else if (isInOrder && !canApply) {
                            const index = item.discounts.findIndex(
                                (d) => d.$getID() === this.$getID()
                            );
                            item.discounts.splice(index, 1);
                        }
                    } while (
                        this.canApply(order) &&
                        this.method === DiscountMethod.Automatic
                    );
                }
            });
        }
    }

    getDetailsFromOrder(order: Order | OrderItem) {
        let totalOrder = order.totalVATWithoutDiscount();
        let total = 0,
            quantity = 0;
        if (order instanceof Order && this.type === DiscountType.Order) {
            const allReadyDiscounted: Discount[] = [];
            order.discounts.forEach((discount) => {
                if (discount.$isSame(this) && !allReadyDiscounted.includes(discount)) {
                    let tempDiscount = 0;
                    allReadyDiscounted.push(discount);
                    if (discount.valueType === DiscountValueType.Amount) {
                        tempDiscount = discount.value;
                    } else if (discount.valueType === DiscountValueType.Percentage) {
                        tempDiscount = ((totalOrder || 0) * discount.value) / 100;
                    }
                    totalOrder -= tempDiscount;
                    total += tempDiscount;
                }
            });
        } else if (order instanceof OrderItem && this.type === DiscountType.Product) {
            const allReadyDiscounted: Discount[] = [];
            order.discounts.forEach((discount) => {
                if (discount.$isSame(this) && !allReadyDiscounted.includes(discount)) {
                    let tempDiscount = 0;
                    allReadyDiscounted.push(discount);
                    if (discount.valueType === DiscountValueType.Free) {
                        quantity += discount.value;
                        tempDiscount = order.product?.price || 0;
                    } else if (discount.valueType === DiscountValueType.Amount) {
                        tempDiscount = discount.value;
                    } else if (discount.valueType === DiscountValueType.Percentage) {
                        tempDiscount = ((totalOrder || 0) * discount.value) / 100;
                    }
                    totalOrder -= tempDiscount;
                    total += tempDiscount;
                }
            });
        }
        return {
            total,
            quantity,
        };
    }
}
