<script setup lang="ts">
import { computed, ref } from "vue";
import { useVModel } from "@vueuse/core";
import { useCollection, newDoc } from "addeus-common-library/stores/firestore";
import { useTranslate } from "addeus-common-library/stores/translate";
import CustomerComponent from "./models/customer.vue";
import { Customer } from "../models/customer";

export interface FindCustomerProps {
    modelValue: any;
    required: boolean;
    options?: any;
}

export interface FindCustomerEmits {
    (event: "update:modelValue", value?: any): void;
}

const props = withDefaults(defineProps<FindCustomerProps>(), {
    options: { booker: true },
});
const emit = defineEmits<FindCustomerEmits>();

const modelElement = ref(null);

const customer = useVModel(props, "modelValue", emit);
const customers = ref([]);

const customerID = computed(() => {
    if (customer.value !== undefined && customer.value !== null)
        return customer.value.$getID();
    return null;
});

function findCustomer(text: string) {
    if (text === undefined || text.length <= 0) {
        customers.value = [];
    } else {
        customers.value = useCollection(Customer, {
            search: text,
        });
    }
}

const customersOptions = computed(() => {
    let hasCustomer = false;
    const options = customers.value.map((c) => {
        if (
            customer.value !== undefined &&
            customer.value !== null &&
            customer.value.$isSame(c) === true
        )
            hasCustomer = true;
        return {
            label: c.toString(),
            value: c.$getID(),
        };
    });

    if (
        !hasCustomer &&
        customer.value !== undefined &&
        customer.value !== null &&
        customer.value.$isNew() === false
    )
        options.push({
            label: customer.value.toString(),
            value: customer.value.$getID(),
        });
    options.push({
        label: translate(".new").value,
        value: "new",
    });
    return options;
});

async function selectCustomer(selected) {
    if (selected === undefined) return;

    let found;

    if (selected !== "new") {
        found = customers.value.find((c) => {
            return c.$getID() === selected;
        });
    }

    // Reset customer template
    customer.value = null;
    await new Promise((resolve) => {
        setTimeout(resolve, 100);
    });

    if (selected === "new") {
        found = newDoc(Customer);
    }

    customer.value = found;
}

async function clearCustomer() {
    if (props.required === true) customer.value = newDoc(Customer);
    else customer.value = undefined;
}

const { translate } = useTranslate();
</script>
<template>
    <div>
        <VField v-slot="{ id }" class="is-autocomplete-select">
            <VControl icon="person_search">
                <Multiselect
                    :id="id"
                    :value="customerID"
                    :options="customersOptions"
                    :searchable="true"
                    :filterResults="false"
                    @select="selectCustomer"
                    @deselect="clearCustomer"
                    @clear="clearCustomer"
                    @search-change="findCustomer">
                </Multiselect>
            </VControl>
        </VField>

        <VModel
            v-if="customer"
            ref="modelElement"
            :component="CustomerComponent"
            :model="customer"
            :options="options"></VModel>
    </div>
</template>
