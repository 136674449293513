<script lang="ts">
import { defineComponent, ref, toRefs, watch } from "vue";
import type { Discount } from "/@src/models/product/dicount";
import { useUserSession } from "/@src/stores/userSession";
import {
    DiscountMethod,
    DiscountType,
    DiscountValueType,
    MinimumConditionType,
} from "../../../models/product/discount";
import { useCollection } from "addeus-common-library/stores/firestore";
import { SalesChannel } from "../../../models/product/channel";
import * as yup from "yup";

type QuantityType = "products" | "categories";

export function modelEvents() {
    return {
        async onBeforeCreate(discount: Discount) {
            const { user } = useUserSession();
            if (user === undefined) throw new Error("No owner selected");
            discount.owner = user.owner;
        },
        async onBeforeEdit(discount: Discount) {
            const { user } = useUserSession();
            if (user === undefined) throw new Error("No owner selected");
            discount.owner = user.owner;
        },
    };
}
export default defineComponent({
    props: {
        form: {
            type: Object,
            required: true,
        },
    },
    setup(props: any) {
        const { form: discount } = toRefs(props);
        const userSession = useUserSession();
        if (discount.value.saleChannels.length === 0) {
            const saleChannels = useCollection(SalesChannel, {
                wheres: [["owner", "==", userSession.user?.owner?.$getID()]],
            });
            void saleChannels.fetched().then(() => {
                if (saleChannels.length > 0) {
                    discount.value.saleChannels.push(saleChannels[0]);
                }
            });
        }
        const minimumConditionQuantityType = ref<QuantityType>(
            discount.value.minimumCondition.products.length >=
                discount.value.minimumCondition.collections.length
                ? "products"
                : "categories"
        );
        watch(minimumConditionQuantityType, () => {
            if (minimumConditionQuantityType.value === "categories") {
                discount.value.minimumCondition.products.splice(
                    0,
                    discount.value.minimumCondition.products.length
                );
            }
            if (minimumConditionQuantityType.value === "products") {
                discount.value.minimumCondition.collections.splice(
                    0,
                    discount.value.minimumCondition.collections.length
                );
            }
        });
        const applicationQuantityType = ref<QuantityType>(
            discount.value.products.length >= discount.value.categories.length
                ? "products"
                : "categories"
        );
        watch(applicationQuantityType, () => {
            if (applicationQuantityType.value === "categories") {
                discount.value.products.splice(0, discount.value.products.length);
            }
            if (applicationQuantityType.value === "products") {
                discount.value.categories.splice(0, discount.value.categories.length);
            }
        });
        const schemaArray = yup.array();
        return {
            discount,
            DiscountMethod,
            DiscountType,
            MinimumConditionType,
            DiscountValueType,
            minimumConditionQuantityType,
            applicationQuantityType,
            schemaArray,
        };
    },
    computed: {
        valueTypeOptions() {
            const options = [
                {
                    label: ".valueType.options.Percentage",
                    value: 0,
                    id: 0,
                },
                {
                    label: ".valueType.options.Amount",
                    value: 1,
                    id: 1,
                },
            ];

            if (
                this.discount.type === DiscountType.Product &&
                this.discount.minimumCondition.type !== MinimumConditionType.None
            )
                options.push({
                    label: ".valueType.options.Free",
                    value: 2,
                    id: 2,
                });
            return options;
        },
    },
});
</script>

<template>
    <VFlex flex-direction="column">
        <VFieldModel v-model="discount" property="name"></VFieldModel>
        <VFieldModel v-model="discount" property="type"></VFieldModel>
        <VFieldModel v-model="discount" property="method"></VFieldModel>
        <VFieldModel
            v-if="discount.method !== DiscountMethod.Automatic"
            v-model="discount"
            property="code"></VFieldModel>
        <VLabel class="has-text-centered">
            <Translate>.minimumCondition.title</Translate>
        </VLabel>
        <TranslateNamespace path=".minimumCondition">
            <VFieldModel
                v-model="discount.minimumCondition"
                property="type"></VFieldModel>
            <VFieldModel
                v-if="discount.minimumCondition.type !== MinimumConditionType.None"
                v-model="discount.minimumCondition"
                :label="`.value.label.${
                    MinimumConditionType[discount.minimumCondition.type]
                }`"
                property="value"></VFieldModel>
            <VFlex
                v-if="discount.minimumCondition.type === MinimumConditionType.Count"
                flex-direction="row"
                align-items="center">
                <VRadio
                    v-model="minimumConditionQuantityType"
                    name="minimumConditionQuantityType"
                    value="products">
                    Products
                </VRadio>
                <VFlexItem :flex-grow="1">
                    <VFieldModel
                        v-model="discount.minimumCondition"
                        property="products"></VFieldModel>
                </VFlexItem>
            </VFlex>
            <VFlex
                v-if="discount.minimumCondition.type === MinimumConditionType.Count"
                flex-direction="row"
                align-items="center">
                <VRadio
                    v-model="minimumConditionQuantityType"
                    name="minimumConditionQuantityType"
                    value="categories">
                    Collections
                </VRadio>
                <VFlexItem :flex-grow="1">
                    <TranslateNamespace path=".collections">
                        <VLabel><Translate>.label</Translate></VLabel>
                        <VValidation
                            v-slot="{ field }"
                            v-model="discount.minimumCondition.collections"
                            property="collections"
                            :schema="schemaArray">
                            <VControl :has-error="field.errors.length > 0">
                                <FindProductCategory
                                    v-model="field.value"
                                    :edit="false"
                                    multiple
                                    required></FindProductCategory>
                                <p
                                    v-for="error in field.errors"
                                    :key="error"
                                    class="help is-danger">
                                    <Translate>.{{ error }}</Translate>
                                </p>
                            </VControl>
                        </VValidation>
                    </TranslateNamespace>
                </VFlexItem>
            </VFlex>
        </TranslateNamespace>
        <VLabel class="has-text-centered">
            <Translate>.application.title</Translate>
        </VLabel>
        <VFieldModel
            v-model="discount"
            property="valueType"
            :select-options="valueTypeOptions"></VFieldModel>
        <VFieldModel
            v-model="discount"
            property="value"
            :label="`.value.label.${
                DiscountValueType[discount.valueType]
            }`"></VFieldModel>
        <VFlex
            v-if="discount.type === DiscountType.Product"
            flex-direction="row"
            align-items="center">
            <VRadio
                v-model="applicationQuantityType"
                name="applicationQuantityType"
                value="products">
                Products
            </VRadio>
            <VFlexItem :flex-grow="1">
                <VFieldModel v-model="discount" property="products"></VFieldModel>
            </VFlexItem>
        </VFlex>
        <VFlex
            v-if="discount.type === DiscountType.Product"
            flex-direction="row"
            align-items="center">
            <VRadio
                v-model="applicationQuantityType"
                name="applicationQuantityType"
                value="categories">
                Collections
            </VRadio>
            <VFlexItem :flex-grow="1">
                <TranslateNamespace path=".categories">
                    <VLabel><Translate>.label</Translate></VLabel>
                    <VValidation
                        v-slot="{ field }"
                        v-model="discount.categories"
                        property="categories"
                        :schema="schemaArray">
                        <VControl :has-error="field.errors.length > 0">
                            <FindProductCategory
                                v-model="field.value"
                                :edit="false"
                                multiple
                                required></FindProductCategory>
                            <p
                                v-for="error in field.errors"
                                :key="error"
                                class="help is-danger">
                                <Translate>.{{ error }}</Translate>
                            </p>
                        </VControl>
                    </VValidation>
                </TranslateNamespace>
            </VFlexItem>
        </VFlex>
        <VLabel class="has-text-centered">
            <Translate>.other.title</Translate>
        </VLabel>
        <VFieldModel
            v-model="discount"
            property="canBeCombinedWithOtherProductDiscounts"></VFieldModel>
        <VFieldModel
            v-model="discount"
            property="canBeCombinedWithOtherShipDiscounts"></VFieldModel>
        <VFieldModel v-model="discount" property="maximumUsageAmount"></VFieldModel>
        <VFieldModel v-model="discount" property="maximumUsagePerCustomer"></VFieldModel>
        <VLabel><Translate>.date.label</Translate></VLabel>
        <VFlex flex-direction="row" justify-content="space-between">
            <VFieldModel v-model="discount" property="start"></VFieldModel>
            <VFieldModel v-model="discount" property="end"></VFieldModel>
        </VFlex>
        <VFieldModel v-model="discount" property="saleChannels"></VFieldModel>
    </VFlex>
</template>
