<script setup lang="ts">
import type { Colors } from "addeus-common-library/stores/color";
import { useColor } from "addeus-common-library/stores/color";
export interface ButtonPrimaryProps {
    color: Colors;
}

const props = defineProps<ButtonPrimaryProps>();

const color = useColor(props.color);
</script>

<template>
    <VButton class="course-btn-fill" v-bind="$attrs">
        <span class="text">
            <VFlex
                flex-direction="row"
                align-items="center"
                justify-content="center"
                column-gap="5px"
                class="px-3 is-weight-600">
                <slot></slot>
                <VIcon icon="/images/kart/book-button-2.png"></VIcon>
            </VFlex>
        </span>
        <span class="text-decoration"></span>
        <span class="decoration"></span>
    </VButton>
</template>

<style scoped lang="scss">
@use "sass:color";

@import "bulma/sass/utilities/_all.sass";

[class*="course-btn-"] {
    display: inline-block;
    letter-spacing: 1px;
    border: 1px solid v-bind(color);
    color: white;
    font-weight: 800;
    font-size: 1.2rem;
    background: url("/images/sidebar/elements/SB_button_course.svg"), v-bind(color);
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 60px; // a voir
    line-height: 0;
    white-space: no-wrap;

    &:hover,
    &:focus {
        color: white;
    }

    .text,
    .decoration {
        display: inline-block;
    }

    .decoration {
        float: right;
    }
}
</style>
