<script lang="ts">
import { defineComponent, toRefs } from "vue";
import { useUserSession } from "../../stores/userSession";
import { Closure } from "../../models/closure";

export function modelEvents() {
    return {
        onBeforeCreate(closure: Closure) {
            const { user } = useUserSession();
            closure.startDate = closure.startDate.startOf("minute");
            closure.endDate = closure.endDate.startOf("minute");
            if (user === null) throw new Error("No owner selected");
            closure.owner = user.owner;
        },

        onBeforeEdit(closure: Closure) {
            const { user } = useUserSession();
            closure.startDate = closure.startDate.startOf("minute");
            closure.endDate = closure.endDate.startOf("minute");
            if (user === null) throw new Error("No owner selected");
            closure.owner = user.owner;
        },
    };
}

export default defineComponent({
    props: {
        form: {
            type: Closure,
            required: true,
        },
    },
    setup(props: any) {
        const { form: closure } = toRefs(props);

        return {
            closure,
        };
    },
});
</script>

<template>
    <VFlex :flex-direction="'column'">
        <VFieldModel v-model="closure" property="startDate"></VFieldModel>
        <VFieldModel v-model="closure" property="endDate"></VFieldModel>
        <VFieldModel v-model="closure" property="comment"></VFieldModel>
    </VFlex>
</template>