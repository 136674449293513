import { useCollection } from "addeus-common-library/stores/firestore";
import { acceptHMRUpdate, defineStore } from "pinia";
import { ProductCategory } from "../models/product";
import { useUserSession } from "./userSession";

export const useRootProductCategories = defineStore("product-categories", () => {
    const userSession = useUserSession();
    const categories = useCollection(ProductCategory, {
        wheres: [
            ["owner", "==", userSession.user?.owner?.$getID()],
            ["isRoot", "==", true],
        ],
    });
    return { categories };
});

/**
 * Pinia supports Hot Module replacement so you can edit your stores and
 * interact with them directly in your app without reloading the page.
 *
 * @see https://pinia.esm.dev/cookbook/hot-module-replacement.html
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useRootProductCategories, import.meta.hot));
}
