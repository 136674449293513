import { Entity, Input, Var } from "addeus-common-library/stores/firestore";
import moment from "moment-with-locales-es6";
import { Owner } from "./owner";

export class Closure extends Entity {
    static collectionName = "closureDates";

    @Var(moment)
    @Input("datetime", { required: true })
    startDate: moment = moment();

    @Var(moment)
    @Input("datetime", { required: true })
    endDate: moment = moment().add(1, "day");

    @Var(String)
    @Input("text", { required: true })
    comment?: string;

    @Var(Owner)
    owner?: Owner;
}
