<script setup lang="ts">
import { ref } from "vue";

export interface PINEmits {
    (event: "code", value: string): void;
}

export interface PINProps {
    retry?: boolean;
}

withDefaults(defineProps<PINProps>(), {
    retry: false,
});

const emits = defineEmits<PINEmits>();

const code = ref("");
function addNumber(number: number) {
    if (code.value.length >= 4) return;
    code.value = code.value + number.toString();
    if (code.value.length === 4) emits("code", code.value);
}

function back() {
    code.value = code.value.slice(0, -1);
}

function erase() {
    code.value = "";
}
</script>
<template>
    <TranslateNamespace path="sidebar.user.pin">
        <VFlex flex-direction="column" align-items="center">
            <VInput
                v-model="code"
                type="password"
                disabled
                class="has-text-centered my-1"></VInput>
            <p v-if="retry" class="has-text-danger">
                <Translate>.wrongPassword</Translate>
            </p>
            <VFlex flex-direction="row" justify-content="center">
                <VButton
                    rounded
                    size="big"
                    :disabled="code.length >= 4"
                    @click="addNumber(1)"
                    >1</VButton
                >
                <VButton
                    rounded
                    size="big"
                    :disabled="code.length >= 4"
                    @click="addNumber(2)"
                    >2</VButton
                >
                <VButton
                    rounded
                    size="big"
                    :disabled="code.length >= 4"
                    @click="addNumber(3)"
                    >3</VButton
                >
            </VFlex>
            <VFlex flex-direction="row" justify-content="center">
                <VButton
                    rounded
                    size="big"
                    :disabled="code.length >= 4"
                    @click="addNumber(4)"
                    >4</VButton
                >
                <VButton
                    rounded
                    size="big"
                    :disabled="code.length >= 4"
                    @click="addNumber(5)"
                    >5</VButton
                >
                <VButton
                    rounded
                    size="big"
                    :disabled="code.length >= 4"
                    @click="addNumber(6)"
                    >6</VButton
                >
            </VFlex>
            <VFlex flex-direction="row" justify-content="center">
                <VButton
                    rounded
                    size="big"
                    :disabled="code.length >= 4"
                    @click="addNumber(7)"
                    >7</VButton
                >
                <VButton
                    rounded
                    size="big"
                    :disabled="code.length >= 4"
                    @click="addNumber(8)"
                    >8</VButton
                >
                <VButton
                    rounded
                    size="big"
                    :disabled="code.length >= 4"
                    @click="addNumber(9)"
                    >9</VButton
                >
            </VFlex>
            <VFlex flex-direction="row" justify-content="center" align-items="center">
                <VIconButton
                    circle
                    size="big"
                    icon="arrow_back"
                    :disabled="code.length == 0"
                    @click="back()"></VIconButton>
                <VButton
                    rounded
                    size="big"
                    :disabled="code.length >= 4"
                    @click="addNumber(0)"
                    >0</VButton
                >
                <VIconButton
                    :disabled="code.length == 0"
                    circle
                    size="big"
                    icon="cancel"
                    @click="erase()"></VIconButton>
            </VFlex>
        </VFlex>
    </TranslateNamespace>
</template>
