import type { Ref } from "vue";
import type { Race } from "../../models/race";
import type { Item } from "../../models/race/item";
import type { Device } from "../../models/race/device";
import type { Kart } from "../../models/race/kart";
import type { ProjectorScenario } from "../../models/race/item";

const useItemManagement = (
    socketStore: {
        closeSocket: () => void;
        startSocket: () => Promise<void>;
        sendEndSignal: () => void;
        sendStartSignal: () => void;
        activateKart: (reference: string, number: number) => void;
        deactivateKart: (reference: string, number: number) => void;
        onItemCatched: (
            callback: (item: Item, projector: Device, kart: Kart) => any
        ) => void;
        setItemProjector: (item: Item, projector: Device) => void;
        onItemReleased: (callback: (item: Item, kart: Kart) => any) => any;
        isSocketOpen: Ref<any>;
    },
    currentRace: Ref<Race | null>
) => {
    let isEnded = true;
    function newSequenceProjector(
        projector: Device,
        frameIndex: number,
        scenario: ProjectorScenario
    ) {
        if (isEnded) return;

        const frames = scenario.frames;
        let frame = frames[frameIndex];
        if (frame === undefined) frame = frames[(frameIndex = 0)];

        socketStore.setItemProjector(frame.item, projector);

        setTimeout(() => {
            newSequenceProjector(projector, frameIndex + 1, scenario);
        }, scenario.frames[frameIndex].duration);
    }

    const hasImmunity: { [key: string]: NodeJS.Timeout } = {};
    const currentSpeedRatio: { [key: string]: number } = {};

    function sendItemSignal(item: Item, kart: Kart) {
        // Set kart parameters from item

        const kartID = kart.$getID();
        if (kartID === undefined) return;

        if (item.ownImmunity === true) {
            clearTimeout(hasImmunity[kartID]);
            hasImmunity[kartID] = setTimeout(() => {
                delete hasImmunity[kartID];
            });
        }

        if (item.allSpeedRatio !== undefined) {
            currentRace.value?.drivers.forEach((driver) => {
                const currentKartID = driver.kart?.$getID();
                if (
                    currentKartID === undefined ||
                    hasImmunity[currentKartID] !== undefined
                )
                    return;

                currentSpeedRatio[currentKartID] =
                    currentSpeedRatio[currentKartID] * (item.allSpeedRatio || 1);
                // setSpeedRatio(driver.kart.reference, currentSpeedRatio[kartID]);
            });
        }
        if (item.ownSpeedRatio !== undefined) {
            currentSpeedRatio[kartID] * (item.ownSpeedRatio || 1);
            // setSpeedRatio(kart.reference, currentSpeedRatio[kartID]);
        }

        if (item.acceleration !== undefined) {
            // setAcceleration(kart.reference, item.acceleration);
        }
        setTimeout(() => {
            return resetItemSignal(item, kart);
        }, item.effectiveTime);
    }

    function resetItemSignal(item: Item, kart: Kart) {
        const kartID = kart.$getID();
        if (kartID === undefined) return;

        // Reset kart parameters
        if (item.allSpeedRatio !== undefined) {
            currentRace.value?.drivers.forEach((driver) => {
                const currentKartID = driver.kart?.$getID();
                if (
                    currentKartID === undefined ||
                    hasImmunity[currentKartID] !== undefined
                )
                    return;

                currentSpeedRatio[currentKartID] =
                    currentSpeedRatio[currentKartID] / (item.allSpeedRatio || 1);
                // setSpeedRatio(driver.kart.reference, currentSpeedRatio[kartID]);
            });
        }
        if (item.ownSpeedRatio !== undefined) {
            currentSpeedRatio[kartID] =
                currentSpeedRatio[kartID] / (item.ownSpeedRatio || 1);

            // setSpeedRatio(kart.reference, currentSpeedRatio[kartID]);
        }
        if (item.acceleration !== undefined) {
            // setAcceleration(kart.reference, [0, 0.25, 0.5, 1]);
        }
    }

    socketStore.onItemReleased(async (item, kart) => {
        const findedDriver = currentRace.value?.drivers.find((driver) =>
            driver.kart?.$isSame(kart)
        );
        if (!findedDriver) return;

        const lastLap = findedDriver.laps[findedDriver.laps.length - 1];
        const lastCheckpoint = lastLap.checkpoints[lastLap.checkpoints.length - 1];

        const earnedMoney = await findedDriver.getEarnedMoney();
        if (earnedMoney < (item.costMoney || 0)) return;
        lastCheckpoint.useItem(item);
        sendItemSignal(item, kart);
    });

    socketStore.onItemCatched(async (item, projector, kart) => {
        const findedDriver = currentRace.value?.drivers.find((driver) =>
            driver.kart?.$isSame(kart)
        );
        if (!findedDriver) return;

        const lastLap = findedDriver.laps[findedDriver.laps.length - 1];
        const lastCheckpoint = lastLap.checkpoints[lastLap.checkpoints.length - 1];

        lastCheckpoint.earnItem(item);
        if (item.activateOnAcquisition) sendItemSignal(item, kart);
    });

    return {
        start() {
            isEnded = false;
            currentRace.value?.type?.projectorScenarios.forEach((scenario) => {
                if (scenario.projector === undefined) return;
                newSequenceProjector(scenario.projector, 0, scenario);
            });
        },
        end() {
            isEnded = true;
        },
    };
};

export { useItemManagement };
