import {
    Entity,
    EntityBase,
    Input,
    Var,
} from "addeus-common-library/stores/firestore/index";
import { GeoPoint } from "firebase/firestore";
import { Address } from "./address";
import moment from "moment-with-locales-es6";
import { EntityArray } from "addeus-common-library/stores/firestore/entity";
import { Currency } from "addeus-common-library/stores/currency";

const days: moment[] = [];
const start = moment().startOf("week");
const end = moment().endOf("week");
for (let day = start; day.isBefore(end) === true; day.add(1, "day")) {
    days.push({
        label: day.format("dddd"),
        value: day.day(),
    });
}

export class BusinessHour extends EntityBase {
    @Var(Array.of(Number))
    @Input("select", {
        multiple: true,
        required: true,
        options: days,
    })
    daysOfWeek: number[] = EntityArray([0, 1, 2, 3, 4, 5, 6]);

    @Var(String)
    @Input("text", {
        validate: [
            [
                "laterThanFive",
                (value: string) => {
                    const hours = Number.parseInt(value.split(":")[0]);
                    return hours >= 5;
                },
            ],
            [
                "endTimeAfterStartTime",
                (value: string, row: BusinessHour) => {
                    const splitedStartTime = value.split(":");
                    const startHours = Number.parseInt(splitedStartTime[0]);
                    const startMinutes = Number.parseInt(splitedStartTime[1]);

                    const splitedEndTime = row.endTime.split(":");
                    const endHours = Number.parseInt(splitedEndTime[0]);
                    const endMinutes = Number.parseInt(splitedEndTime[1]);
                    return (
                        startHours < endHours ||
                        (startHours === endHours && startMinutes < endMinutes)
                    );
                },
            ],
        ],
    })
    startTime: string = "00:00";

    @Var(String)
    @Input("text", {
        validate: [
            [
                "beforeFive",
                (value: string) => {
                    const splitedTime = value.split(":");
                    const hours = Number.parseInt(splitedTime[0]);
                    const minutes = Number.parseInt(splitedTime[1]);
                    return hours < 24 + 5 || (hours === 29 && minutes === 0);
                },
            ],
        ],
    })
    endTime: string = "23:59";

    toString() {
        const days = this.daysOfWeek
            .map((day) => {
                const start = moment().day(day);

                return `${start.format("dddd")}`;
            })
            .join(" / ");

        return `${this.startTime} - ${this.endTime} (${days})`;
    }

    $isBetweenSlot(datetime: moment, ofDay?: moment) {
        const minParsed = this.startTime.match(/([0-9]+)\:([0-9]+)/);
        const maxParsed = this.endTime.match(/([0-9]+)\:([0-9]+)/);
        if (!minParsed || !maxParsed) return false;

        // Manage Daylight
        if (datetime instanceof Date) datetime = moment(datetime);
        // console.log(datetime);
        // const o = datetime.clone().add(1, "days");
        // if (o.utcOffset() > datetime.utcOffset()) {
        //     datetime.subtract(1, "hours");
        // } else if (o.utcOffset() < datetime.utcOffset()) {
        //     datetime.add(1, "hours");
        // }

        let startOf;
        if (ofDay !== undefined) startOf = ofDay;
        else startOf = datetime.clone().subtract(5, "hours").startOf("day");

        const dayOfWeek = parseInt(startOf.format("d"));
        if (this.daysOfWeek.indexOf(dayOfWeek) < 0) return false;

        const min: moment = startOf
            .clone()
            .add(parseInt(minParsed[1]), "hour")
            .add(parseInt(maxParsed[2]), "minute");
        const max: moment = startOf
            .clone()
            .add(parseInt(maxParsed[1]), "hour")
            .add(parseInt(minParsed[2]), "minute");

        const o = min.clone().add(1, "days");
        const i = min.clone().subtract(1, "days");
        if (
            o.utcOffset() === min.utcOffset() &&
            i.utcOffset() < min.utcOffset() &&
            min.hours() > 2
        ) {
            min.subtract(1, "hours");
            max.subtract(1, "hours");
        } else if (
            o.utcOffset() === min.utcOffset() &&
            i.utcOffset() > min.utcOffset() &&
            min.hours() > 2
        ) {
            min.add(1, "hours");
            max.add(1, "hours");
        }

        return min.isSameOrBefore(datetime) === true && max.isAfter(datetime) === true;
    }
}

export class WebsiteFeature extends EntityBase {
    @Var(String)
    @Input("textarea", { required: true })
    name?: string;

    @Var(String)
    @Input("select", {
        required: true,
        options: ["beer", "customer", "light", "mister", "track", "kart", "cloche"],
    })
    icon?: string;

    toString() {
        return this.name;
    }
}

export class Website extends EntityBase {
    @Var(Array.of(String))
    @Input("file", { multiple: true })
    pictures?: string[] = [];

    @Var(Array.of(WebsiteFeature))
    @Input("array", { multiple: true })
    features?: WebsiteFeature[] = EntityArray();

    @Var(String)
    @Input("file", { accepts: "video/*" })
    video?: string;

    @Var(Number)
    @Input("number")
    priceBirthdayPackGold: number = 0;

    @Var(Number)
    @Input("number")
    priceBirthdayPackSilver: number = 0;

    @Var(Number)
    @Input("number")
    priceBirthdayPackBronze: number = 0;

    @Var(Number)
    @Input("number")
    priceWeddingPack: number = 0;

    @Var(Number)
    @Input("number")
    priceWeddingPackOption1: number = 0;

    @Var(Number)
    @Input("number")
    priceWeddingPackOption2: number = 0;

    @Var(Number)
    @Input("number")
    priceWeddingPackOption3: number = 0;

    @Var(Number)
    @Input("number")
    priceAfterWorkPack: number = 0;

    @Var(Number)
    @Input("number")
    priceRacing1901: number = 0;

    @Var(Number)
    @Input("number")
    priceRacing1901Option: number = 0;

    @Var(Number)
    @Input("number")
    priceStudentKart: number = 0;

    @Var(Number)
    @Input("number")
    priceStudentKartOption1: number = 0;

    @Var(Number)
    @Input("number")
    priceStudentKartOption2: number = 0;

    @Var(Number)
    @Input("number")
    priceStudentKartOption3: number = 0;

    toString() {
        return "";
    }
}

export class SpeedLimitation extends EntityBase {
    @Var(String)
    @Input("text", { required: true })
    name: string = "";

    @Var(Number)
    @Input("number", { required: true })
    speed: number;

    @Var(Number)
    @Input("number", { required: true })
    minAge: number;

    @Var(Number)
    @Input("number")
    maxAge: number;

    toString() {
        const base = `${this.name} - ${this.speed}km/s`;
        if (this.maxAge) return `${base} (${this.minAge} - ${this.maxAge})`;

        return `${base} (+${this.minAge})`;
    }
}

export class Owner extends Entity {
    static collectionName = "owners";

    @Var(String)
    @Input("text", { required: true })
    name?: string;

    @Var(Address)
    address?: Address = new Address();

    @Var(String)
    @Input("email")
    mail?: string;

    @Var(String)
    @Input("email")
    saleEmail?: string;

    @Var(String)
    @Input("text")
    registration?: string;

    @Var(String)
    manager?: string;

    @Var(String)
    accounting?: string;

    @Var(String)
    technician?: string;

    @Var(String)
    @Input("phone")
    phoneNumber?: string;

    @Var(Array.of(BusinessHour))
    businessHours: BusinessHour[] = EntityArray();

    @Var(Array.of(SpeedLimitation))
    speedLimitations?: SpeedLimitation[] = EntityArray();

    enableNegativeStock = false;

    @Var(Boolean)
    @Input("checkbox")
    hasFullTrackConfig: boolean = false;

    @Var(Currency)
    @Input("select")
    currency?: Currency = Currency.euro;

    @Var(Boolean)
    isAlarmOn = false;

    @Var(Boolean)
    @Input("checkbox")
    availableOnline = false;

    @Var(Boolean)
    @Input("checkbox")
    bookableOnline = false;

    @Var(GeoPoint)
    position?: GeoPoint;

    @Var(Website)
    website?: Website = new Website();

    $isInBusinessHours(datetime: moment): boolean {
        if (this.businessHours.length <= 0) return true;
        return this.businessHours.some((businessHour) => {
            return businessHour.$isBetweenSlot(datetime);
        });
    }

    nextOpenningTime(datetime: moment = moment()): moment | undefined {
        let closestOpenningTimeMoment: moment | undefined,
            closestDuration: moment.Duration;

        for (const date = datetime.clone(); ; date.add(1, "day").startOf("day")) {
            if (this.businessHours.length === 0) break;
            if (date.isAfter(datetime.clone().add(7, "day")) === true) break;
            for (const businessHour of this.businessHours) {
                if (businessHour.$isBetweenSlot(datetime) === true) {
                    closestDuration = moment.duration(0);
                    closestOpenningTimeMoment = moment();
                    break;
                }
                if (businessHour.daysOfWeek.includes(date.day()) === false) continue;
                const minParsed = businessHour.startTime.match(/([0-9]+)\:([0-9]+)/);
                if (!minParsed) continue;
                const openningTimeMoment = date
                    .clone()
                    .hours(parseInt(minParsed[1]))
                    .minutes(parseInt(minParsed[2]));
                const duration = moment.duration(openningTimeMoment.diff(datetime));
                if (duration < 0) continue;
                if (
                    closestDuration === undefined ||
                    closestDuration.asMilliseconds() > duration.asMilliseconds()
                ) {
                    closestDuration = duration;
                    closestOpenningTimeMoment = openningTimeMoment;
                }
            }
            if (closestDuration !== undefined && closestDuration.asMilliseconds() >= 0) {
                break;
            }
        }
        return closestOpenningTimeMoment;
    }

    nextClosingTime(datetime: moment = moment()): moment | undefined {
        let closestClosingTimeMoment: moment | undefined,
            closestDuration: moment.Duration;

        for (const businessHour of this.businessHours) {
            if (businessHour.$isBetweenSlot(datetime) === false) {
                continue;
            }

            if (businessHour.daysOfWeek.includes(datetime.day()) === false) continue;

            const maxParsed = businessHour.endTime.match(/([0-9]+)\:([0-9]+)/);
            if (!maxParsed) continue;
            const closingTimeMoment = datetime
                .clone()
                .hours(parseInt(maxParsed[1]))
                .minutes(parseInt(maxParsed[2]));
            const duration = moment.duration(closingTimeMoment.diff(datetime));
            if (
                closestDuration === undefined ||
                (duration.asMilliseconds() < closestDuration?.asMilliseconds() &&
                    duration.asMilliseconds() > 0)
            ) {
                closestDuration = duration;
                closestClosingTimeMoment = closingTimeMoment;
            }
        }
        return closestClosingTimeMoment;
    }
}
