import { defineAsyncComponent } from "vue";
import { plugin as VueTippy } from "vue-tippy";
import StackdriverErrorReporter from "stackdriver-errors-js";

import type { Track2MaxAppContext } from "./app";
import hideOnHidden from "addeus-common-library/directives/hideOnHidden";

if (process.env.NODE_ENV !== "development") {
    const errorHandler = new StackdriverErrorReporter();
    errorHandler.start({
        key: import.meta.env.VITE_FIREBASE_API_KEY,
        projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    });
}

// Lazy load aditional components
export function registerGlobalComponents({ app }: Track2MaxAppContext) {
    app.use(VueTippy, {
        component: "Tippy",
        defaultProps: {
            theme: "light",
        },
    });

    app.component(
        // eslint-disable-next-line vue/multi-word-component-names
        "Multiselect",
        defineAsyncComponent({
            loader: () => import("@vueform/multiselect").then((mod) => mod.default),
            delay: 0,
            suspensible: false,
        })
    );
    app.component(
        // eslint-disable-next-line vue/multi-word-component-names
        "Slider",
        defineAsyncComponent({
            loader: () => import("@vueform/slider").then((mod) => mod.default),
            delay: 0,
            suspensible: false,
        })
    );
    app.component(
        "VCalendar",
        defineAsyncComponent({
            loader: () => import("v-calendar").then((mod) => mod.Calendar),
            delay: 0,
            suspensible: false,
        })
    );

    app.directive("hideOnHidden", hideOnHidden);

    // app.directive("t", t);
}
