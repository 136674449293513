<script setup lang="ts">
import type { Ref } from "vue";
import { computed, ref, watch } from "vue";
import { useVModel } from "@vueuse/core";
import { ProductVariant } from "../models/product";
import type { Entity, Collection } from "addeus-common-library/stores/firestore";
import { useDoc, useCollection } from "addeus-common-library/stores/firestore";
import { useTranslate } from "addeus-common-library/stores/translate";

export interface FindProductVariantProps {
    modelValue: any;
    required: boolean;
    ignore?: Entity;
}

export interface FindProductVariantEmits {
    (event: "update:modelValue", value?: any): void;
}

const props = defineProps<FindProductVariantProps>();
const emit = defineEmits<FindProductVariantEmits>();

const productVariantID = useVModel(props, "modelValue", emit);
const productVariants: Ref<Collection<ProductVariant> | never[]> = ref([]);

const productVariant = ref<ProductVariant | undefined>(
    useDoc(ProductVariant, productVariantID.value)
);
watch([productVariant], () => {
    productVariantID.value = productVariant.value?.$getID();
});

function findProductVariant(text: string) {
    productVariants.value = useCollection(ProductVariant, {
        wheres: [
            ["name", ">=", text],
            ["name", "<=", text + "~"],
        ],
    });
}
findProductVariant("");

const productVariantsOptions = computed(() => {
    let hasProductVariant = false;

    const options = productVariants.value
        .filter((c) => {
            return props.ignore === undefined || !props.ignore.$isSame(c);
        })
        .map((c) => {
            if (
                productVariant.value !== undefined &&
                productVariant.value.$isSame(c) === true
            )
                hasProductVariant = true;
            return {
                label: c.toString(),
                value: c.$getID(),
            };
        });

    if (!hasProductVariant && productVariant.value !== undefined)
        options.push({
            label: productVariant.value.toString(),
            value: productVariant.value.$getID(),
        });
    return options;
});

async function selectProductVariant(selected) {
    if (selected === undefined) return;

    productVariant.value = productVariants.value.find((c) => {
        return c.$getID() === selected;
    });
}

async function clearProductVariant() {
    productVariant.value = undefined;
}

const { translate } = useTranslate();
const placeHolder = translate(".placeholder");
</script>
<template>
    <VField v-slot="{ id }" class="is-autocomplete-select">
        <VControl icon="person_search">
            <Multiselect
                :id="id"
                :value="productVariantID"
                :options="productVariantsOptions"
                :placeholder="placeHolder"
                :searchable="true"
                :filterResults="false"
                :label-attr="{}"
                @select="selectProductVariant"
                @deselect="clearProductVariant"
                @clear="clearProductVariant"
                @search-change="findProductVariant">
            </Multiselect>
        </VControl>
    </VField>
</template>
