import { Var, Input, Entity } from "addeus-common-library/stores/firestore";
import moment from "moment-with-locales-es6";
import { ProductVariant } from "./index";
import { Employee } from "../employee";
import { Owner } from "../owner";

export enum StockType {
    Loss, // When value is entered, store a negative value of this value
    Replenishment, // When value is entered, store a positive value of this value
    Recount, // When value is entered, store a difference value from current availability
    Sale, // When value is entered, store a negative value of this value
}

export class Stock extends Entity {
    static collectionName = "stocks";

    @Var(ProductVariant)
    product?: ProductVariant;

    @Var(moment)
    createdAt?: moment;

    @Var(Employee)
    createdBy?: Employee;

    @Var(Number)
    @Input("number", {
        required: true,
    })
    quantity: number = 0;

    @Var(StockType)
    @Input("radio", {
        options: {
            Loss: StockType.Loss,
            Replenishment: StockType.Replenishment,
            Recount: StockType.Recount,
        },
        required: true,
    })
    type: StockType = StockType.Replenishment;

    @Var(Owner)
    owner?: Owner;

    @Var(Number)
    productQuantityBefore: number = 0;

    @Var(Number)
    @Input("text", {
        required: true,
    })
    buyPrice?: number;

    get productQuantityAfter() {
        return this.productQuantityBefore + this.deltaQuantity;
    }

    get deltaQuantity() {
        switch (this.type) {
            case StockType.Loss:
                return -this.quantity;
            default:
            case StockType.Replenishment:
                return this.quantity;
            case StockType.Recount:
                return this.quantity - this.productQuantityBefore;
            case StockType.Sale:
                return -this.quantity;
        }
    }
}
