<script setup async lang="ts">
import { provide, watch } from "vue";
import { useCustomerSession } from "../stores/customerSession";
import { useRoute, useRouter } from "vue-router";
import { useTitle } from "@vueuse/core";
import { useI18n } from "vue-i18n";
import extendModels from "../extend-models";
import functions from "addeus-common-library/stores/function";
import Layout from "./website/Layout.vue";

extendModels();
const customerSession = useCustomerSession();
const router = useRouter();
const title = useTitle("Speed2Max");
const { t } = useI18n();
const { query, fullPath } = useRoute();

provide("translate", () => {
    return;
});

await customerSession.waitInitialize;

const redirectToLogin = async () => {
    await router.push({
        path: "/auth/login",
        query: { redirect: fullPath },
    });
};

const checkIsLoggedIn = async () => {
    if (!customerSession.isLoggedIn()) {
        if (
            query.token === undefined &&
            router.currentRoute.value.meta?.requiresAuth === true
        ) {
            await redirectToLogin();
        } else if (
            router.currentRoute.value.meta?.requiresAuth === true &&
            query.token !== undefined
        ) {
            const connectionLink = await functions.loginWithToken({
                url: window.location.href,
                token: query.token,
            });
            if (connectionLink !== null) {
                window.location = connectionLink;
            } else {
                await redirectToLogin();
            }
        }
    }
};

watch(customerSession.customer, checkIsLoggedIn);
await checkIsLoggedIn();

function toCamelCase(str) {
    return str.toLowerCase().replace(/-([a-z])/g, (match, chr) => chr.toUpperCase());
}

async function onRouteChange(to: any) {
    await customerSession.waitInitialize;
    const isLoggedIn = customerSession.customer !== undefined;

    if (typeof to.name === "string") {
        const translatedTitle = t(
            "speed2max." + toCamelCase(to.name.replace(/[:]/, "")) + ".title",
        );
        title.value = `Speed2Max - ${translatedTitle}`;
    } else {
        title.value = `Speed2Max`;
    }

    if (to.meta.requiresAuth === true && !isLoggedIn) {
        return {
            path: "/",
        };
    }
}

router.beforeEach(onRouteChange);
router.afterEach(() => {
    window.scrollTo(0, 0);
});
const newRoute = await onRouteChange(router.currentRoute.value);
if (newRoute) void router.push(newRoute);
</script>
<template>
    <TranslateNamespace path="speed2max">
        <Layout>
            <RouterView v-slot="{ Component, route }">
                <Transition name="fade-fast" mode="out-in">
                    <component :is="Component" :translate-namespace="route.name" />
                </Transition>
            </RouterView>
        </Layout>
    </TranslateNamespace>
</template>
