import { Entity, Input, Var } from "addeus-common-library/stores/firestore/index";
import { useCustomerSession } from "../stores/customerSession";
import moment from "moment-with-locales-es6";
import { Address } from "./address";
import { Owner } from "./owner";
import { EntityArray } from "addeus-common-library/stores/firestore/entity";

export enum Gender {
    other,
    his,
    her,
}

export enum Tag {
    handicap = "handicap",
    baby = "baby",
}

export class Customer extends Entity {
    static collectionName = "customers";

    @Var(String)
    @Input("text", {
        validate: [
            [
                "required",
                (value: string, row: Customer) => {
                    return !row.job || row.job.length === 0 || value;
                },
            ],
        ],
    })
    company?: string;

    @Var(String)
    @Input("text", {
        validate: [
            [
                "required",
                (value: string, row: Customer) => {
                    return !row.company || row.company.length === 0 || value;
                },
            ],
        ],
    })
    job?: string;

    @Var(String)
    @Input("text", {
        validate: [
            [
                "required",
                (value, row) => {
                    return (
                        (typeof row.nickName === "string" && row.nickName.length > 0) ||
                        value
                    );
                },
            ],
        ],
    })
    public firstName?: string;

    @Var(String)
    @Input("text", {
        validate: [
            [
                "required",
                (value, row) => {
                    return (
                        (typeof row.nickName === "string" && row.nickName.length > 0) ||
                        value
                    );
                },
            ],
        ],
    })
    lastName?: string;

    @Var(String)
    @Input("text", {
        validate: [
            [
                "required",
                (value, row) => {
                    return (
                        (typeof row.firstName === "string" && row.firstName.length > 0) ||
                        (typeof row.lastName === "string" && row.lastName.length > 0) ||
                        (typeof value === "string" && value.length > 0)
                    );
                },
            ],
        ],
    })
    nickName?: string;

    createdAt?: moment = moment();

    @Var(Boolean)
    hasAcceptedCondition: boolean = false;

    @Var(Boolean)
    hasAcceptedPromotion: boolean = true;

    @Var(String)
    @Input("email", {
        validate: [
            "email",
            [
                "exist",
                async (value: string, row: Customer) => {
                    const { isEmailExist } = useCustomerSession();
                    const isExist = await isEmailExist(value, row.$getID());
                    return !isExist;
                },
            ],
            [
                "required",
                (value: string, row: Customer) => {
                    return !row.company || value;
                },
            ],
        ],
    })
    email?: string;

    @Var(String)
    @Input("phone", {
        validate: [
            [
                "exist",
                async (value: string, row: Customer) => {
                    const { isPhoneNumberExist } = useCustomerSession();
                    const isExist = await isPhoneNumberExist(value, row.$getID());
                    return !isExist;
                },
            ],
            [
                "required",
                (value: string, row: Customer) => {
                    return row.company || value;
                },
            ],
        ],
    })
    phoneNumber?: string;

    @Var(String)
    @Input("file")
    picture?: string;

    @Var(String)
    @Input("select", { options: Gender })
    gender?: Gender = Gender.his;

    @Var(Address)
    address?: Address = new Address();

    @Var(Boolean)
    hasSameDeliveryAddress = false;

    @Var(Address)
    deliveryAddress?: Address = new Address();

    @Var(moment)
    @Input("date")
    birthdate?: moment = moment();

    @Var(Array.of(String))
    @Input("select", { options: Tag, multiple: true })
    tags?: Tag[] = [];

    @Var(String)
    @Input("text")
    referralCode?: string;

    @Var(String)
    user?: string;

    @Var(Array.of(Owner))
    owners: Owner[] = EntityArray();

    toString(showPhoneNumber: boolean = true, showEmail: boolean = true) {
        if (!this.firstName && !this.lastName) return this.nickName;
        const main = this.getName();

        let sub = null;

        if (showEmail && this.email) sub = `${this.email}`;
        if (showPhoneNumber && this.phoneNumber)
            sub = `${sub ? sub + "/" : ""}${this.phoneNumber}`;

        return sub ? `${main} (${sub})` : main;
    }

    getName() {
        if (!this.firstName && !this.lastName && this.nickName) return this.nickName;
        else if (!this.firstName && !this.lastName) return "";
        return `${this.firstName || ""} ${this.lastName || ""}`;
    }
}
