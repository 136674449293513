import { Owner } from "../owner";
import type moment from "moment-with-locales-es6";
import { ProductDistributionChannel, ProductSalesChannel } from "./channel";
import {
    Entity,
    Var,
    Input,
    EntityBase,
    useDoc,
} from "addeus-common-library/stores/firestore";
import { GroupType } from "../race/groupType";
import { Type as KartType } from "../race/kart";
import { Speed as KartSpeed } from "../race/speed";
import { useUserSession } from "../../stores/userSession";
import { MeasureUnit, getMeasureUnitFactor } from "./measureUnit";
import { EntityArray } from "addeus-common-library/stores/firestore/entity";

export class ProductCategory extends Entity {
    static collectionName = "productCategories";

    @Var(String)
    @Input("text", {
        required: true,
    })
    name: string = "";

    @Var(Owner)
    owner?: Owner;

    @Var(Array.of(ProductCategory))
    subCategories: ProductCategory[] = EntityArray();

    @Var(ProductCategory)
    parentCategory?: ProductCategory;

    @Var(Boolean)
    isRoot: boolean = false;

    @Var(Number)
    weight: number = 0;

    toString() {
        return this.name;
    }

    async getRootCategory(): Promise<ProductCategory> {
        await this.$getMetadata().refresh();

        if (this.isRoot) return this;

        return this.parentCategory!.getRootCategory();
    }
}

export enum QuantityType {
    Unitary = "Unitary",
    Weight = "Weight",
    Volume = "Volume",
    Service = "Service",
    NoQuantity = "NoQuantity",
}

export class ProductVariantComposition extends EntityBase {
    @Var(String)
    productVariantId?: string;

    @Var(Number)
    @Input("number", { required: true })
    quantity: number = 0;

    toString() {
        if (this.productVariantId === undefined) return `${this.quantity} unknown`;
        return `${this.quantity} ${useDoc(ProductVariant, this.productVariantId).name}`;
    }
}

export class StockAlert extends EntityBase {
    @Var(Number)
    @Input("number", { required: true })
    ratio: number = 0;
}

export class ProductVariant extends Entity {
    static collectionName = "productVariants";

    @Var(ProductCategory)
    category?: ProductCategory;

    @Var(String)
    @Input("file", {
        cropOptions: {
            aspectRatio: 1,
            minWidth: 140,
            minHeight: 140,
        },
    })
    picture?: string;

    // Default from previous product variant
    @Input("checkbox")
    @Var(Boolean)
    canBeSold: boolean = true;

    // Default from previous product variant
    @Input("checkbox")
    @Var(Boolean)
    canBeBought: boolean = true;

    @Var(String)
    @Input("text", {
        required: true,
    })
    name?: string;

    // Default from previous product variant
    @Var(Number)
    @Input("number", { required: true })
    price: number = 10;

    // Mirror of stock
    @Var(Number)
    availability: number = 0;

    // Default from previous product variant
    @Var(QuantityType)
    @Input("select", {
        options: QuantityType,
        required: true,
    })
    quantityType: QuantityType = QuantityType.Unitary;

    // Default from previous product variant
    @Var(MeasureUnit)
    measureUnit?: MeasureUnit;

    // Default from previous product variant
    @Var(String)
    @Input("textarea")
    comment: string = "";

    // Default from previous product variant
    @Var(Array.of(ProductVariantComposition))
    compositions: ProductVariantComposition[] = EntityArray();

    // Default from previous product variant
    @Var(Array.of(ProductDistributionChannel))
    distributionChannels: ProductDistributionChannel[] = EntityArray();

    // Default from previous product variant
    @Var(Array.of(ProductSalesChannel))
    salesChannels: ProductSalesChannel[] = EntityArray();

    @Var(Number)
    @Input("number", { required: true })
    quantityAlert: number = 0;

    deletedAt?: moment;

    @Var(GroupType)
    @Input("radio", {
        validate: [
            [
                "required",
                (value: string, row: ProductVariant) => {
                    return (
                        row.raceGroupType !== undefined ||
                        (row.raceGroupType === undefined &&
                            row.kartType === undefined &&
                            row.kartSpeed === undefined)
                    );
                },
            ],
        ],
        options: {
            entity: GroupType,
            where() {
                const userSession = useUserSession();
                return [["owner", "==", userSession.user?.owner?.$getID()]];
            },
            orders() {
                return [["weight", "asc"]];
            },
            limit: -1,
        },
    })
    raceGroupType?: GroupType;

    @Var(KartType)
    @Input("radio", {
        validate: [
            [
                "required",
                (value: string, row: ProductVariant) => {
                    return (
                        row.kartType !== undefined ||
                        (row.raceGroupType === undefined &&
                            row.kartType === undefined &&
                            row.kartSpeed === undefined)
                    );
                },
            ],
        ],
        options: {
            entity: KartType,
            orders() {
                return [["weight", "asc"]];
            },
            limit: -1,
        },
    })
    kartType?: KartType;

    @Var(Array.of(KartSpeed))
    @Input("select", {
        validate: [
            [
                "required",
                (value: any, row: ProductVariant) => {
                    return (
                        row.kartSpeeds.length !== 0 ||
                        (row.raceGroupType === undefined &&
                            row.kartType === undefined &&
                            row.kartSpeeds.length === 0)
                    );
                },
            ],
        ],
        multiple: true,
        options: {
            entity: KartSpeed,
            orders() {
                return [["weight", "asc"]];
            },
            limit: -1,
        },
    })
    kartSpeeds: KartSpeed[] = EntityArray();

    @Var(Number)
    @Input("number", {})
    giftCard?: number;

    @Var(Owner)
    owner?: Owner;

    @Var(String)
    @Input("text", {
        match: /^(?:[0-9]{8}([0-9]{5})?)?$/,
    })
    barcode?: string;

    @Var(String)
    productId?: string;

    toString() {
        return this.name;
    }

    get availabilityWithMeasureUnit() {
        if (
            this.quantityType === QuantityType.NoQuantity ||
            this.quantityType === QuantityType.Service ||
            this.quantityType === QuantityType.Unitary ||
            this.measureUnit === undefined
        )
            return `${this.availability || 0}`;

        const measureUnit = getMeasureUnitFactor(this.measureUnit);
        return `${this.availability / measureUnit} ${this.measureUnit}`;
    }
}

export class Product extends Entity {
    static collectionName = "products";

    @Var(ProductCategory)
    category?: ProductCategory;

    @Var(Number)
    @Input("number", { required: true })
    weight: number = 0;

    @Var(ProductVariant)
    mainVariant?: ProductVariant;

    @Var(Array.of(ProductVariant))
    variants: ProductVariant[] = EntityArray();

    @Var(Owner)
    owner?: Owner;

    @Var(Boolean)
    @Input("checkbox")
    favorite: boolean = false;

    get sellableVariants() {
        return [this.mainVariant!, ...this.variants].filter(
            (variant) => variant.canBeSold
        );
    }
}
