<script lang="ts">
import { type Ref, defineComponent, toRefs, ref, computed } from "vue";
import { useUserSession } from "/@src/stores/userSession";
import type { Group } from "../../../models/race/group";
import { mergeByRace } from "../../../models/race/group";
import { Driver } from "../../../models/race/group";
import { Customer } from "../../../models/customer";
import * as CustomerComponent from "/@src/components/models/customer.vue";
import { findDoc, newDoc } from "addeus-common-library/stores/firestore";
import { useTranslate } from "addeus-common-library/stores/translate";
import moment from "moment-with-locales-es6";
import { computedAsync, watchArray } from "@vueuse/core";
import * as yup from "yup";
import { Race, Status as RaceStatus } from "../../../models/race";
import type { Modal } from "addeus-common-library/stores/modal";
import { useModal } from "addeus-common-library/stores/modal";
import type { TranslateValues } from "../../modal/RaceMergeModal.vue";
import RaceMergeModalComponent from "../../modal/RaceMergeModal.vue";
import VButton from "addeus-common-library/components/base/button/VButton.vue";
import { useRaceShift } from "../../../stores/raceShift";
import { v4 as uuid } from "uuid";

export function modelEvents() {
    const modal = useModal();
    const { translate } = useTranslate();
    const userSession = useUserSession();
    const raceShiftAction = useRaceShift();

    async function getOverlapRaces(raceGroup: Group): Promise<Race[]> {
        const promises = raceGroup.races.map(async (race) => {
            const endDate = race.date.clone();
            endDate.add(10, "minutes");
            return await raceShiftAction.getRacesBySlot(race.date, endDate);
        });
        const races = (await Promise.all(promises)).reduce(
            (prev, current) => [...prev, ...current],
            [],
        );
        const raceIds: string[] = raceGroup.races
            .map((race: Race) => race.$getID())
            .filter((id) => id !== undefined);

        return races.filter((race: Race) => !raceIds.includes(race.$getID()));
    }

    async function isOverlap(raceGroup: Group): Promise<boolean> {
        return (await getOverlapRaces(raceGroup)).length > 0;
    }

    async function askUserRaceCompositionAction(
        canMerge = true,
        canSwap = true,
        defaultAction: string = "shift",
        translation?: TranslateValues,
    ): Promise<{ choice: string; isForAll: boolean }> {
        return new Promise((resolve, reject) => {
            let m: Modal;
            const compositionAction: Ref<string | undefined> = ref(undefined);
            const isForAllRaces = ref(false);
            const actions = [
                {
                    component: VButton,
                    content: translate(`model.raceGroup.raceMerge.cancel`).value,
                    events: {
                        click() {
                            reject();
                            m.close();
                        },
                    },
                },
                {
                    component: VButton,
                    content: translate(`model.raceGroup.raceMerge.next`).value,
                    props: {
                        color: "info",
                    },
                    events: {
                        click() {
                            if (compositionAction.value === undefined) {
                                reject();
                            } else {
                                resolve({
                                    choice: "next",
                                    isForAll: false,
                                });
                            }
                            m.close();
                        },
                    },
                },
                {
                    component: VButton,
                    content: translate(`model.raceGroup.raceMerge.confirm`).value,
                    props: {
                        color: "primary",
                    },
                    events: {
                        click() {
                            if (compositionAction.value === undefined) {
                                reject();
                            } else {
                                resolve({
                                    choice: compositionAction.value,
                                    isForAll: isForAllRaces.value,
                                });
                            }
                            m.close();
                        },
                    },
                },
            ];
            const events = {
                "update:action"(action: string) {
                    compositionAction.value = action;
                },
                "update:isForAll"(isForAll: boolean) {
                    isForAllRaces.value = isForAll;
                },
            };
            m = modal.createModal(RaceMergeModalComponent, {
                size: "large",
                props: { canMerge, canSwap, defaultAction, translateValue: translation },
                actions,
                events,
                onClose: reject,
            });
        });
    }

    function canMergeRace(race: Race, raceMergeOn: Race): boolean {
        if (
            raceMergeOn.type === undefined ||
            raceMergeOn.type !== race.type ||
            raceMergeOn.circuit === undefined ||
            raceMergeOn.circuit !== race.circuit ||
            raceMergeOn.status !== RaceStatus.waiting
        ) {
            return false;
        }
        const totalPotentialDriver = raceMergeOn.drivers.length + race.drivers.length;
        if (totalPotentialDriver > raceMergeOn.type.maxParticipants) return false;

        const hasSameDrivers = raceMergeOn.drivers.some((driver) => {
            return !!race.drivers.find((d) => d.driverID === driver.driverID);
        });

        if (hasSameDrivers) return false;
        return true;
    }

    function hasSameSpeeds(raceFrom: Race, raceTo: Race) {
        const speedTo: string[] = [];
        raceTo.drivers.forEach((driver) => {
            const speedID = driver.kartSpeed?.$getID();
            if (speedID !== undefined && speedTo.indexOf(speedID) < 0)
                speedTo.push(speedID);
        });

        return raceFrom.drivers.every((driver) => {
            const speedID = driver.kartSpeed?.$getID();
            if (speedID === undefined) return true;
            return speedTo.indexOf(speedID) >= 0;
        });
    }

    async function applyPlanningCompositionAction(
        raceFrom: Race,
        raceTo: Race,
        choice?: string,
    ): Promise<{ race: Race; choice?: string }> {
        const canMerge = canMergeRace(raceFrom, raceTo);
        let isForAll = choice !== undefined;
        if (choice === undefined || (choice === "merge" && !canMerge)) {
            const modalResult = await askUserRaceCompositionAction(
                canMerge,
                !raceFrom.$isNew(),
                choice,
                { toDate: raceTo.date.format("DD/MM/YYYY HH:mm") },
            );
            choice = modalResult.choice;
            isForAll = modalResult.isForAll;
        }
        if (choice === "merge" && !hasSameSpeeds(raceFrom, raceTo)) {
            try {
                await modal.prompt(
                    translate("planning.differentSpeed.title").value,
                    translate("planning.differentSpeed.sub").value,
                    translate("planning.differentSpeed.message").value,
                    translate("planning.differentSpeed.cancel").value,
                    translate("planning.differentSpeed.confirm").value,
                );
            } catch (e) {
                return applyPlanningCompositionAction(raceFrom, raceTo);
            }
        }

        switch (choice) {
            case "merge":
                mergeByRace(raceTo, raceFrom);
                await raceFrom.$delete();
                await raceTo.$save();
                return { race: raceTo, choice: isForAll ? choice : undefined };
            case "next":
                return findRaceAndApplyPlanningCompositionAction(
                    raceFrom,
                    raceTo.date.clone().add(10, "minutes"),
                );
            case "shift":
                raceFrom.date = raceTo.date.clone();
                await raceFrom.$save();
                const id = raceFrom.$getID();
                await raceShiftAction.shiftRaces(
                    raceTo.date,
                    raceTo.date.clone().add(10, "minutes"),
                    [id === undefined ? "" : id],
                );
                break;
            case "nextAvailable":
                const nextAvailableDate = await getNextAvailableSlot(raceTo.date);
                raceFrom.date = nextAvailableDate;
                await raceFrom.$save();
                break;
            case "swapDate":
                const newDate = raceTo.date;
                raceTo.date = raceFrom.date;
                raceFrom.date = newDate;
                await raceTo.$save();
                await raceFrom.$save();
        }
        return { race: raceFrom, choice: isForAll ? choice : undefined };
    }

    async function findRaceAndApplyPlanningCompositionAction(
        race: Race,
        date: moment,
        choice?: string,
    ) {
        if (userSession.user?.owner === undefined) throw new Error("User need to login");
        const raceDestination = await findDoc(Race, {
            wheres: [
                ["date", "==", date.toDate()],
                ["owner", "==", userSession.user?.owner?.$getID()],
            ],
        });

        if (raceDestination === undefined) {
            race.date = date;
            await race.$save();
            return { choice: undefined, race };
        }

        return applyPlanningCompositionAction(race, raceDestination, choice);
    }

    async function getNextAvailableSlot(date: moment) {
        const freeSlotDate = date.clone();
        let races = [];
        do {
            races = await raceShiftAction.getRacesBySlot(
                freeSlotDate,
                freeSlotDate.clone().add(10, "minutes"),
            );
            if (races.length > 0) freeSlotDate.add(10, "minutes");
        } while (races.length > 0);
        return freeSlotDate;
    }

    return {
        findRaceAndApplyPlanningCompositionAction,
        shiftRaces: raceShiftAction.shiftRaces,
        async onDelete(raceGroup: Group) {
            await raceGroup.races.reduce(async (acc, race) => {
                await acc;
                await race.$getMetadata().refresh();

                raceGroup.drivers.forEach((driver) => {
                    const index = race.drivers.findIndex(
                        (d) => d.driverID === driver.driverID,
                    );
                    if (index >= 0) race.drivers.splice(index, 1);
                });

                if (race.drivers.length <= 0) await race.$delete();
                else await race.$save();
            }, Promise.resolve());

            await raceGroup.drivers.reduce(async (acc, driver) => {
                await acc;
                if (driver.product !== undefined)
                    raceGroup.order?.removeItem(driver.product, 1);
            }, Promise.resolve());

            await raceGroup.order?.$save();
        },
        async onBeforeCreate(raceGroup: Group) {
            const { user } = useUserSession();
            if (user === null) throw new Error("No owner selected");
            raceGroup.owner = user.owner;
            let hasOwner = false;

            if (Array.isArray(raceGroup.bookBy?.owners)) {
                hasOwner = !!raceGroup.bookBy?.owners.find((o) => o.$isSame(user.owner));
            } else if (raceGroup.bookBy) raceGroup.bookBy.owners = [];
            if (!hasOwner && raceGroup.bookBy) {
                raceGroup.bookBy.owners.push(user.owner);
                await raceGroup.bookBy.$save();
            }
            if (raceGroup.bookBy?.$hasChanged()) {
                await raceGroup.bookBy.$save();
            }

            await raceGroup.drivers.reduce(async (acc, driver) => {
                await acc;
                if (driver.customer === undefined) return;
                await driver.customer.$getMetadata().refresh();
                const hasOwner = !!driver.customer.owners.find((o) =>
                    o.$isSame(user.owner),
                );
                if (!hasOwner) driver.customer.owners.push(user.owner);
                void driver.customer.$save();
            }, Promise.resolve());
        },
        async onCreate(raceGroup: Group) {
            await raceGroup.generateRaces();

            let choice: string | undefined;
            let date: moment | undefined = undefined;
            await raceGroup.races.reduce(async (acc, race, index) => {
                await acc;

                if (date !== undefined) {
                    race.date = date.clone();
                }
                const { choice: lastChoice, race: raceResult } =
                    await findRaceAndApplyPlanningCompositionAction(
                        race,
                        race.date,
                        choice,
                    );
                choice = lastChoice;
                date = raceResult.date.clone().add(10, "minutes");
                raceGroup.races[index] = raceResult;
                // raceResult.groups = { ...race.groups, ...raceResult.groups };
            }, Promise.resolve());

            const { user } = useUserSession();
            raceGroup.updatedBy = user;
            await raceGroup.$save();
        },
        async onBeforeEdit(raceGroup: Group) {
            const { user } = useUserSession();
            if (user === null) throw new Error("No owner selected");
            raceGroup.owner = user.owner;

            let hasOwner = false;
            if (Array.isArray(raceGroup.bookBy?.owners)) {
                hasOwner = !!raceGroup.bookBy?.owners.find((o) => o.$isSame(user.owner));
            } else if (raceGroup.bookBy) raceGroup.bookBy.owners = [];
            if (!hasOwner && raceGroup.bookBy) {
                raceGroup.bookBy.owners.push(user.owner);
                await raceGroup.bookBy.$save();
            }
            if (raceGroup.bookBy?.$hasChanged()) {
                await raceGroup.bookBy.$save();
            }
            if (raceGroup.drivers.some((driver) => driver.customer === undefined)) {
                const customerless = raceGroup.drivers.filter(
                    (driver) => driver.customer === undefined,
                );
                const IDs = customerless.map((driver) => driver.driverID);
                await raceGroup.races.reduce(async (acc, race) => {
                    await acc;
                    race.drivers.forEach((driver) => {
                        if (!IDs.includes(driver.driverID)) return;
                        const gDriver = customerless.find(
                            (d) => d.driverID === driver.driverID,
                        );
                        driver.nickName = gDriver?.nickName;
                        driver.driverTags = gDriver?.driverTags;
                    });
                    await race.$save();
                }, Promise.resolve());
            }

            await raceGroup.drivers.reduce(async (acc, driver) => {
                await acc;
                if (driver.customer === undefined) return;
                await driver.customer.$getMetadata().refresh();
                const hasOwner = !!driver.customer.owners.find((o) =>
                    o.$isSame(user.owner),
                );
                if (!hasOwner) driver.customer.owners.push(user.owner);
                await driver.customer.$save();
            }, Promise.resolve());
        },

        async onEdit(raceGroup: Group) {
            const previousDriverIds = (
                raceGroup.$getMetadata().previousOrigin.drivers.map((d: Driver) => {
                    return d.uid === undefined ? d.customer : d.uid;
                }) as string[]
            ).filter((id) => id !== undefined);
            const removedDriverIds = previousDriverIds.filter(
                (d) =>
                    raceGroup.drivers.findIndex((driver) => driver.driverID === d) === -1,
            );
            raceGroup.races
                .filter(
                    (race) =>
                        race.status === RaceStatus.assigning ||
                        race.status === RaceStatus.waiting,
                )
                .forEach((race) => {
                    race.drivers = race.drivers.filter((driver) => {
                        if (driver.driverID === undefined) {
                            return false;
                        }
                        const id = driver.driverID;
                        return !removedDriverIds.includes(id === undefined ? "" : id);
                    });
                });

            const previousDate =
                typeof raceGroup.$getMetadata().previousOrigin.date.toDate === "function"
                    ? raceGroup.$getMetadata().previousOrigin.date.toDate()
                    : raceGroup.$getMetadata().previousOrigin.date;
            const newDate =
                typeof raceGroup.$getMetadata().origin.date.toDate === "function"
                    ? raceGroup.$getMetadata().origin.date.toDate()
                    : raceGroup.$getMetadata().origin.date;

            if (newDate.toJSON() !== previousDate.toJSON()) {
                await raceGroup.generateRaces(true);
            } else {
                await raceGroup.generateRaces();
            }

            const overlap = await isOverlap(raceGroup);

            if (overlap) {
                await Promise.all(
                    raceGroup.races.map(async (race) => {
                        const endDate = race.date.clone();
                        endDate.add(10, "minutes");
                        const id = race.$getID();
                        await raceShiftAction.shiftRaces(race.date, endDate, [
                            id === undefined ? "" : id,
                        ]);
                    }),
                );
            }

            await raceGroup.races.reduce(async (acc, race) => {
                await acc;

                await Promise.all(
                    race.drivers.map(async (driver) => {
                        if (driver.driverID === undefined) return;

                        await driver.kartType?.$getMetadata().waitFullfilled();
                        await driver.kartSpeed?.$getMetadata().waitFullfilled();
                        const raceGroupDriver = raceGroup.drivers.find(
                            (d) => d.driverID === driver.driverID,
                        );
                        if (raceGroupDriver === undefined) return;

                        if (
                            raceGroupDriver.customer !== undefined &&
                            !raceGroupDriver.customer.$isSame(driver.customer)
                        ) {
                            driver.customer = raceGroupDriver.customer;
                        }

                        if (!raceGroupDriver.kartType?.$isSame(driver.kartType)) {
                            driver.kartType = raceGroupDriver.kartType;
                        }
                        if (!raceGroupDriver.kartSpeed?.$isSame(driver.kartSpeed)) {
                            driver.kartSpeed = raceGroupDriver.kartSpeed;
                        }
                    }),
                );

                return race.$save();
            }, Promise.resolve());

            const { user } = useUserSession();
            raceGroup.updatedBy = user;
            await raceGroup.$save();
        },
    };
}

export default defineComponent({
    props: {
        form: {
            type: Object,
            required: true,
        },
        checkout: {
            type: Boolean,
            default: false,
        },
    },
    setup(props: any) {
        const { form: raceGroup } = toRefs(props);

        const { translate } = useTranslate();
        const under18 = moment().subtract(18, "year");
        const errors = ref([]);
        const userSession = useUserSession();
        const countChild = computedAsync(async () => {
            await Promise.all(
                raceGroup.value.drivers.map((driver: Driver) => {
                    if (driver.customer !== undefined)
                        return driver.customer.$getMetadata().refresh();
                }),
            );
            return {
                numberOfAdult: raceGroup.value.drivers.filter((driver: Driver) => {
                    if (
                        driver.customer === undefined ||
                        driver.customer.birthdate === undefined
                    )
                        return true;
                    return driver.customer.birthdate.isSameOrAfter(under18);
                }).length,
                numberOfChildren: raceGroup.value.drivers.filter((driver: Driver) => {
                    if (
                        driver.customer === undefined ||
                        driver.customer.birthdate === undefined
                    )
                        return false;
                    return driver.customer.birthdate.isBefore(under18);
                }).length,
            };
        });

        const bookerIsInDriver = computed(() => {
            return raceGroup.value.drivers.some((driver: Driver) => {
                if (driver.customer === undefined) return false;
                return driver.customer.$isSame(raceGroup.value.bookBy);
            });
        });

        const numberOfDriversToGenerate = ref(raceGroup.value.drivers.length);

        const bookBySchema = yup.object().required();

        const disabledDrivers = computed(() => {
            const drivers: Driver[] = raceGroup.value.drivers;
            return drivers.map((driver: Driver) => {
                if (raceGroup.value.$isNew() === true) return false;
                if (addedDrivers.value.includes(driver)) return false;
                const races: Race[] = raceGroup.value.races.filter(
                    (race: Race) =>
                        race.status === RaceStatus.assigning ||
                        race.status === RaceStatus.waiting,
                );
                return !races.some((race: any) => {
                    return race.drivers
                        .map((d: any) => d.driverID)
                        .includes(driver.driverID);
                });
            });
        });

        const addedDrivers = ref<Driver[]>([]);

        // Schema to validate drivers array
        const schema = yup.object();
        const rootSchema = yup.array().of(schema).min(1, "validation.required");

        watchArray(
            raceGroup.value.drivers,
            async (
                value: Driver[],
                oldValue: Driver[],
                added: Driver[],
                removed: Driver[],
            ) => {
                addedDrivers.value.push(...added);
                addedDrivers.value = addedDrivers.value.filter(
                    (driver) => !removed.includes(driver),
                );

                await raceGroup.value.drivers.reduce(async (acc, driver: Driver) => {
                    await acc;
                    if (driver.customer !== undefined)
                        await driver.customer.$getMetadata().refresh();
                    if (driver.nickName === undefined) {
                        let exist = false;
                        let index = 0;
                        do {
                            index++;
                            let nickName = translate(".generated.nickname", {
                                index,
                            }).value;
                            exist = raceGroup.value.drivers.some(
                                (driver: Driver) => driver.nickName === nickName,
                            );
                        } while (exist);
                        driver.nickName = translate(".generated.nickname", {
                            index: index,
                        }).value;
                    }
                    if (driver.kartType === undefined)
                        driver.kartType = raceGroup.value.defaultKartType;
                    if (driver.kartSpeed === undefined)
                        driver.kartSpeed = raceGroup.value.defaultKartSpeed;
                    if (driver.product === undefined)
                        driver.product = raceGroup.value.defaultProduct;
                }, Promise.resolve());

                numberOfDriversToGenerate.value = raceGroup.value.drivers.length;
            },
        );
        const keys: any = {};

        return {
            keys,
            schema,
            rootSchema,
            raceGroup,
            countChild,
            bookerIsInDriver,
            errors,
            Customer,
            Driver,
            CustomerComponent,
            numberOfDriversToGenerate,
            translate,
            userSession,
            bookBySchema,
            disabledDrivers,
        };
    },
    methods: {
        generateDrivers() {
            if (this.numberOfDriversToGenerate < 0) return;

            // this.scope.off();

            const deltaDrivers =
                this.numberOfDriversToGenerate - this.raceGroup.drivers.length;

            if (deltaDrivers === 0) return;
            if (deltaDrivers > 0) {
                for (let i = 0; i < deltaDrivers; i++) {
                    const driver = new Driver();
                    driver.uid = uuid();
                    // driver.customer = newDoc(Customer);
                    driver.kartSpeed = this.raceGroup.defaultKartSpeed;
                    driver.kartType = this.raceGroup.defaultKartType;
                    driver.product = this.raceGroup.defaultProduct;
                    this.raceGroup.drivers.push(driver);
                }
            } else {
                const raceGroup: Group = this.raceGroup;

                for (let i = 0; i < -deltaDrivers; i++) {
                    const lastIndex = raceGroup.drivers.length - 1;
                    const lastDriver = raceGroup.drivers[lastIndex];
                    const isBookByDriver =
                        raceGroup.bookBy !== undefined &&
                        lastDriver.customer !== undefined &&
                        lastDriver.customer.$getID() === raceGroup.bookBy.$getID();
                    raceGroup.drivers.splice(
                        raceGroup.drivers.length - (isBookByDriver ? 2 : 1),
                        1,
                    );
                }
            }
            // this.scope.on();
        },
        addBookerToDriver() {
            const driver = newDoc(Driver);
            driver.uid = uuid();
            driver.customer = this.raceGroup.bookBy;
            this.raceGroup.drivers.push(driver);
        },
        addDriver(customer: Customer | undefined | null) {
            if (customer === undefined || customer === null) {
                return;
            }
            if (customer.phoneNumber === undefined && customer.email === undefined) {
                return;
            }
            let driver = new Driver();
            driver.kartSpeed = this.raceGroup.defaultKartSpeed;
            driver.kartType = this.raceGroup.defaultKartType;
            driver.product = this.raceGroup.defaultProduct;
            driver.customer = customer;
            driver.uid = uuid();
            this.raceGroup.drivers.push(driver);
            this.selectedCustomer = undefined;
        },
        onOverrideMaxParticipantsChange(e) {
            if (e.target.checked === true) {
                this.raceGroup.maxParticipants = Math.max(
                    this.raceGroup.type.raceTypes.map(
                        (raceType) => raceType.maxParticipants,
                    ),
                );
            } else {
                this.raceGroup.maxParticipants = undefined;
            }
        },
        getKey(value) {
            const findedKey = Object.keys(this.keys).find(
                (key) => this.keys[key] === value,
            );
            if (findedKey) return findedKey;
            const key = uuid();
            this.keys[key] = value;
            return key;
        },
    },
});
</script>

<template>
    <VMultipage
        v-model="raceGroup"
        mode="wizard"
        :steps="[
            translate('.wizard.stepRace').value,
            translate('.wizard.stepCustomer').value,
            translate('.wizard.stepPilots').value,
            translate('.wizard.stepComment').value,
        ]">
        <template #step-1="{ field }">
            <div v-if="field.value.updatedBy !== undefined">
                <Translate
                    :values="{
                        firstName: field.value.updatedBy.firstName,
                        lastName: field.value.updatedBy.lastName,
                    }"
                    >.updatedBy</Translate
                >
            </div>
            <VFieldModel
                v-if="field.value.$isNew()"
                v-model="field.value"
                property="date"
                :options="{ minuteStep: 10 }"></VFieldModel>
            <VField v-else label=".date.label">
                <VInput v-model="field.value.date" disabled></VInput>
            </VField>
            <VFieldModel
                v-if="field.value.$isNew()"
                v-model="field.value"
                property="type"></VFieldModel>
            <VField v-else label=".type.label">
                <VInput v-model="field.value.type" disabled></VInput>
            </VField>
            <VFieldModel
                v-if="field.value.$isNew()"
                v-model="field.value"
                property="defaultKartType"></VFieldModel>
            <VFieldModel
                v-if="field.value.$isNew()"
                v-model="field.value"
                property="defaultKartSpeed"></VFieldModel>
            <VFieldModel
                v-if="field.value.$isNew()"
                v-model="field.value"
                property="defaultProduct"></VFieldModel>
            <VField v-else label=".defaultProduct.label">
                <VInput v-model="field.value.defaultProduct" disabled></VInput>
            </VField>
            <VField label=".overrideMaxParticipants">
                <VFlex align-items="center">
                    <VFlexItem>
                        <VCheckbox @change="onOverrideMaxParticipantsChange"></VCheckbox
                    ></VFlexItem>
                    <VFlexItem :flex-grow="2">
                        <VField>
                            <VInput
                                v-model="field.value.maxParticipants"
                                :disabled="field.value.maxParticipants === undefined"
                                :placeholder="
                                    raceGroup.type?.raceTypes
                                        .map((raceType) => raceType.maxParticipants)
                                        .join(', ')
                                "
                                type="number"></VInput>
                        </VField>
                    </VFlexItem>
                </VFlex>
            </VField>
            <VFieldModel v-model="field.value" property="status"></VFieldModel>
            <VFieldModel v-model="field.value" property="circuit"></VFieldModel>
        </template>
        <template #step-2>
            <TranslateNamespace path=".bookBy">
                <VValidation
                    v-slot="{ field }"
                    v-model="raceGroup.bookBy"
                    property="bookBy"
                    :schema="bookBySchema">
                    <FindCustomer
                        v-model="field.value"
                        required
                        :options="{ booker: true }"></FindCustomer>
                </VValidation>
            </TranslateNamespace>

            <VButton
                v-if="raceGroup.bookBy !== undefined"
                icon="arrow_right_alt"
                @click="addBookerToDriver()">
                <Translate>.bookBy.addToDriver</Translate>
            </VButton>
        </template>
        <template #step-3>
            <VField addons label=".generate.label">
                <VControl expanded>
                    <VInput v-model="numberOfDriversToGenerate" type="number" />
                </VControl>
                <VControl>
                    <VIconButton
                        color="primary"
                        icon="check"
                        :disabled="numberOfDriversToGenerate <= 0"
                        @click="generateDrivers()"></VIconButton>
                </VControl>
            </VField>
            <PaginatedDrivers
                v-model="raceGroup.drivers"
                :disabled-drivers="disabledDrivers"
                :race-group-type="raceGroup.type"></PaginatedDrivers>
        </template>
        <template #step-4>
            <VFieldModel v-model="raceGroup" property="comment"></VFieldModel
        ></template>
    </VMultipage>
</template>

<style lang="scss">
.is-flex-basis-half {
    flex-basis: 49%;
    margin: 5px 0;
    border-radius: 6px;
    box-shadow: 2px 2px 10px 1px #a9a9a9;
}
</style>
