<script lang="ts">
import { defineComponent, toRefs } from "vue";
import { useUserSession } from "../../stores/userSession";
import { Customer } from "../../models/customer";

import Avatar1 from "../../assets/avatar/1.png";
import Avatar2 from "../../assets/avatar/2.png";
import Avatar3 from "../../assets/avatar/3.png";
import Avatar4 from "../../assets/avatar/4.png";
import Avatar5 from "../../assets/avatar/5.png";
import Avatar6 from "../../assets/avatar/6.png";
import Avatar7 from "../../assets/avatar/7.png";
import Avatar8 from "../../assets/avatar/8.png";

export default defineComponent({
    props: {
        form: {
            type: Object,
            required: true,
        },
        booker: {
            type: Boolean,
            default: false,
        },
        driver: {
            type: Boolean,
            default: false,
        },
        booking: {
            type: Boolean,
            default: false,
        },
        driverBooking: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["cancel"],
    setup(props: any, { emit }) {
        const { form: customer } = toRefs(props);
        const userSession = useUserSession();

        return {
            customer,
            userSession,
            Customer,
            avatars: [
                Avatar1,
                Avatar2,
                Avatar3,
                Avatar4,
                Avatar5,
                Avatar6,
                Avatar7,
                Avatar8,
            ],
            async selectExistingCustomer(customer) {
                const findOnwer = customer.owners.find((owner) =>
                    owner.$isSame(userSession.user.owner),
                );
                if (findOnwer === undefined) {
                    customer.owners.push(userSession.user.owner);
                }
                customer.$save();
                emit("cancel");
                await customer.$edit();
            },
        };
    },
});

export function modelEvents() {
    return {
        onBeforeCreate(customer: Customer) {
            const { user } = useUserSession();
            if (user === null || user?.owner === undefined)
                throw new Error("No owner selected");
            const hasOwner = customer.owners.some(
                (owner) => owner.$getID() === user.owner.$getID(),
            );
            if (!hasOwner) customer.owners.push(user.owner);
        },

        onBeforeEdit(customer: Customer) {
            const { user } = useUserSession();
            if (user === null || user?.owner === undefined)
                throw new Error("No owner selected");
            const hasOwner = customer.owners.some(
                (owner) => owner.$getID() === user.owner.$getID(),
            );
            if (!hasOwner) customer.owners.push(user.owner);
        },

        onNew(customer: Customer) {
            const { user } = useUserSession();
            customer.address.country = user.owner.address.country;
        },
    };
}
</script>

<template>
    <div v-if="!driverBooking">
        <VFieldModel
            v-if="!booking && !driver"
            v-model="customer"
            property="company"></VFieldModel>
        <VFieldModel
            v-if="!booker && !driver"
            v-slot="{ field }"
            v-model="customer"
            property="picture">
            <VFlex flex-direction="row" :flex-wrap="'wrap'">
                <VFlexItem v-for="avatar in avatars" :key="avatar">
                    <VRadio v-model="field.value" :value="avatar" name="avatar">
                        <VAvatar
                            use-direct-link
                            :picture="avatar"
                            :color="'primary'"></VAvatar>
                    </VRadio>
                </VFlexItem>
            </VFlex>
        </VFieldModel>
        <VFieldModel
            v-if="!booker && !driver"
            v-model="customer"
            property="gender"></VFieldModel>
        <VFieldModel v-model="customer" property="firstName"></VFieldModel>
        <VFieldModel v-model="customer" property="lastName"></VFieldModel>
        <VFieldModel
            v-if="!booking && !driver"
            v-model="customer"
            property="job"></VFieldModel>
        <VFieldModel
            v-if="!booker && !driver"
            v-model="customer"
            property="birthdate"></VFieldModel>
        <VFieldModel v-if="!booker" v-model="customer" property="nickName"></VFieldModel>
        <VFieldModel v-model="customer" property="tags" mode="tags"></VFieldModel>
        <VFieldModel v-model="customer" property="email"></VFieldModel>
        <VFieldModel
            v-if="!driver"
            v-model="customer"
            property="phoneNumber"></VFieldModel>

        <VFieldModel
            v-if="!driver"
            v-model="customer.address"
            property="street"></VFieldModel>
        <VFieldModel
            v-if="!driver"
            v-model="customer.address"
            property="postalCode"></VFieldModel>
        <VFieldModel
            v-if="!driver"
            v-model="customer.address"
            property="city"></VFieldModel>
        <VFieldModel
            v-if="!driver"
            v-model="customer.address"
            property="country"
            :options="{ searchable: true }"></VFieldModel>
        <VFieldModel
            v-if="!booking && !driver"
            v-model="customer"
            property="referralCode"></VFieldModel>
    </div>
</template>
