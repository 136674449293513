<script setup lang="ts">
import { useTitle } from "@vueuse/core";
import VideoPresentation from "../../assets/video/landing.webm";

useTitle("Speed2Max");
</script>

<template>
    <VPlyr
        :source="VideoPresentation"
        class="video-landing"
        :control="false"
        :options="{
            hideControls: true,
            controls: [],
            autoplay: true,
            muted: true,
            loop: { active: true },
        }"></VPlyr>
</template>

<style lang="scss" scoped>
.video-landing {
    width: 100%;
    min-height: 100vh;
    object-fit: cover;
    pointer-events: none;
}
</style>
