import { EntityBase, Input, Var } from "addeus-common-library/stores/firestore";
import { countries } from "countries-list";

const countryOptions = Object.keys(countries).map((countryCode: string) => {
    const country = countries[countryCode];
    return {
        label: country.emoji + " " + country.native,
        value: countryCode,
    };
});

export class Address extends EntityBase {
    @Var(String)
    @Input("text")
    street?: string;

    @Var(String)
    @Input("text")
    postalCode?: string;

    @Var(String)
    @Input("text")
    city?: string;

    @Var(String)
    @Input("select", {
        options: countryOptions,
    })
    country?: string = "FR";

    toString() {
        const country = countryOptions
            .find((countryRow) => {
                return countryRow.value === this.country;
            })
            ?.label.replace(/^[^\s]+\s/, "");

        let formattedAddress;
        if (typeof this.street === "string") formattedAddress = this.street + "\r\n";
        if (typeof this.postalCode === "string")
            formattedAddress += this.postalCode + " ";
        if (typeof this.city === "string") formattedAddress += this.city + "\r\n";
        if (typeof this.country === "string") formattedAddress += country;

        return formattedAddress;
    }
}
