import { Entity, Input, Var } from "addeus-common-library/stores/firestore/index";
import { Owner } from "./owner";
import { EntityArray } from "addeus-common-library/stores/firestore/entity";

export enum Permission {
    reporting_view = "REPORTING_VIEW",

    customer_view = "CUSTOMER_VIEW",
    customer_edit = "CUSTOMER_EDIT",
    device_view = "DEVICE_VIEW",
    device_edit = "DEVICE_EDIT",
    employee_view = "EMPLOYEE_VIEW",
    employee_edit = "EMPLOYEE_EDIT",
    kart_view = "KART_VIEW",
    kart_edit = "KART_EDIT",
    owner_view = "OWNER_VIEW",
    owner_edit = "OWNER_EDIT",
    planning_view = "PLANNING_VIEW",
    planning_edit = "PLANNING_EDIT",
    role_view = "ROLE_VIEW",
    role_edit = "ROLE_EDIT",
    display_view = "DISPLAY_VIEW",
    display_edit = "DISPLAY_EDIT",
    track_view = "TRACK_VIEW",
    track_edit = "TRACK_EDIT",
    log_view = "LOG_VIEW",
    tax_view = "TAX_VIEW",
    tax_edit = "TAX_EDIT",
    paymentMethod_edit = "PAYMENT_EDIT",
    paymentMethod_view = "PAYMENT_VIEW",
    distributionChannel_edit = "DISTRIBUTION_CHANNEL_EDIT",
    distributionChannel_view = "DISTRIBUTION_CHANNEL_VIEW",
    saleChannel_edit = "SALE_CHANNEL_EDIT",
    saleChannel_view = "SALE_CHANNEL_VIEW",
    product_edit = "PRODUCT_EDIT",
    product_view = "PRODUCT_VIEW",
    stock_edit = "STOCK_EDIT",
    stock_view = "STOCK_VIEW",
    discount_edit = "DISCOUNT_EDIT",
    discount_view = "DISCOUNT_VIEW",
    wallet_view = "WALLET_VIEW",
    wallet_edit = "WALLET_EDIT",
    raceGroup_edit = "RACEGROUP_EDIT",
    raceGroup_view = "RACEGROUP_VIEW",
    raceType_edit = "RACETYPE_EDIT",
    raceType_view = "RACETYPE_VIEW",
    checkout_view = "CHECKOUT_VIEW",
    checkoutValidatePayment_edit = "CHECKOUT_VALIDATE_PAYMENT_EDIT",
    category_edit = "CATEGORY_EDIT",
    category_view = "CATEGORY_VIEW",
    closure_view = "CLOSURE_VIEW",
    closure_edit = "CLOSURE_EDIT",
}

export class Role extends Entity {
    static collectionName = "roles";
    @Var(String)
    @Input("text")
    name?: string;

    @Var(Array.of(Permission))
    permissions?: Permission[] = EntityArray();

    @Var(Owner)
    owner?;

    toString() {
        return this.name;
    }
}
