<script setup lang="ts">
import { computed } from "vue";
import WebsiteSidebar from "./WebsiteSidebar.vue";
import WebsiteTopBar from "./WebsiteTopBar.vue";
import { useRouter } from "vue-router";

const router = useRouter();

const hidden = computed(() => {
    return router.currentRoute.value.meta?.hideSidebar;
});
</script>

<template>
    <VFlex flex-direction="row" :class="{ 'hide-sidebar': hidden }">
        <WebsiteTopBar v-if="!hidden"></WebsiteTopBar>
        <WebsiteSidebar v-if="!hidden"></WebsiteSidebar>
        <VFlexItem id="content">
            <slot></slot>
        </VFlexItem>
    </VFlex>
</template>

<style lang="scss" scoped>
#content {
    width: calc(100% - 320px);
    min-height: 100vh;
    margin-left: 320px;
    // padding: 20px;
    overflow-y: auto;
}

#content:has(div > .global-login) {
    margin-top: 0 !important;
}

.hide-sidebar {
    #content {
        width: 100vw;
        padding: 0;
        margin-left: 0;
    }
}

@media screen and (max-width: 1024px) {
    #content {
        width: 100%;
        margin-top: 80px;
        margin-left: 0;
        min-height: calc(100vh - 80px);
    }
}
</style>
