<script lang="ts">
import { defineComponent, toRefs } from "vue";
import type { Tax } from "/@src/models/product/tax";
import { useUserSession } from "/@src/stores/userSession";

export function modelEvents() {
    return {
        async onBeforeCreate(tax: Tax) {
            const { user } = useUserSession();
            if (user === undefined) throw new Error("No owner selected");
            tax.owner = user.owner;
        },
        async onBeforeEdit(tax: Tax) {
            const { user } = useUserSession();
            if (user === undefined) throw new Error("No owner selected");
            tax.owner = user.owner;
        },
    };
}
export default defineComponent({
    props: {
        form: {
            type: Object,
            required: true,
        },
    },
    setup(props: any) {
        const { form: tax } = toRefs(props);
        return {
            tax,
        };
    },
});
</script>

<template>
    <VFieldModel v-model="tax" property="name"></VFieldModel>
    <VFieldModel v-model="tax" property="tax"></VFieldModel>
</template>
