<script lang="ts">
import { defineComponent, toRefs } from "vue";
import type { DistributionChannel } from "../../../models/product/channel";
import { useUserSession } from "/@src/stores/userSession";

export function modelEvents() {
    return {
        async onBeforeCreate(distributionChannel: DistributionChannel) {
            const { user } = useUserSession();
            if (user === undefined) throw new Error("No owner selected");
            distributionChannel.owner = user.owner;
        },
        async onBeforeEdit(distributionChannel: DistributionChannel) {
            const { user } = useUserSession();
            if (user === undefined) throw new Error("No owner selected");
            distributionChannel.owner = user.owner;
        }
    };
}

export default defineComponent({
    props: {
        form: {
            type: Object,
            required: true,
        },
    },
    setup(props: any) {
        const { form: distributionChannel } = toRefs(props);
        return {
            distributionChannel,
        };
    },
});
</script>

<template>
    <div>
        <VFieldModel v-model="distributionChannel" property="name"></VFieldModel>
        <VFieldModel v-model="distributionChannel" property="type"></VFieldModel>
        <VFieldModel v-model="distributionChannel" property="weight"></VFieldModel>
    </div>
</template>
