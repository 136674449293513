import type moment from "moment-with-locales-es6";
import { Customer } from "../customer";
import { Owner } from "../owner";
import { Entity, Input, Var } from "addeus-common-library/stores/firestore";
import { randomNumber } from "addeus-common-library/utils/string";
import { Order } from "./order";
import { EntityBase } from "addeus-common-library/stores/firestore";
import { EntityArray } from "addeus-common-library/stores/firestore/entity";

export enum WalletRequestType {
    Debit,
    Credit,
}

export enum WalletType {
    giftCard = "giftCard",
}

export class WalletRequest extends EntityBase {
    type?: WalletRequestType;
    requestedAt?: moment;
    amount?: number;
}

export class Wallet extends Entity {
    static collectionName = "wallets";

    @Var(Customer)
    @Input("select", {})
    customer?: Customer;

    @Var(Number)
    @Input("number", {})
    initialAmount: number = 0;

    @Var(Number)
    @Input("number")
    currentAmount: number = 0;

    @Var(Array.of(WalletRequest))
    requests: WalletRequest[] = EntityArray();

    @Var(String)
    @Input("text", {})
    code: string = randomNumber(200, 299) + "" + randomNumber(1000000000, 9999999999);

    @Var(WalletType)
    type: WalletType = WalletType.giftCard;

    @Var(Order)
    order?: Order;

    @Var(Owner)
    owner: Owner;
}
