<script lang="ts">
import { defineComponent, toRefs } from "vue";
import type { ProductCategory } from "/@src/models/product";
import { useUserSession } from "/@src/stores/userSession";

export function modelEvents() {
    return {
        async onBeforeCreate(productCategory: ProductCategory) {
            const { user } = useUserSession();
            if (user === null) throw new Error("No owner selected");
            productCategory.owner = user.owner;
        },
        async onBeforeEdit(productCategory: ProductCategory) {
            const { user } = useUserSession();
            if (user === null) throw new Error("No owner selected");
            productCategory.owner = user.owner;
        },
    };
}
export default defineComponent({
    props: {
        form: {
            type: Object,
            required: true,
        },
    },
    setup(props: any) {
        const { form: productCategory } = toRefs(props);
        return {
            productCategory,
        };
    },
});
</script>

<template>
    <VFieldModel v-model="productCategory" property="name"></VFieldModel>
</template>
