<script setup lang="ts">
import { useStorage } from "addeus-common-library/stores/storage";
import { ref } from "vue";
import type { ProductVariant } from "../models/product";

type PreviewProductVariantProps = {
    productVariant: ProductVariant;
};

const props = defineProps<PreviewProductVariantProps>();

const productPictureURL = ref<string | undefined>();
if (typeof props.productVariant.picture === "string") {
    const storage = useStorage();
    void storage.fetchAsDataUrl(props.productVariant.picture).then((url) => {
        productPictureURL.value = url;
    });
}
</script>

<template>
    <VFlex flex-direction="column" align-items="center" class="preview-product m-4">
        <img
            v-if="productPictureURL !== undefined"
            :src="productPictureURL"
            alt="product" />
        <div class="is-justify-content-center">{{ productVariant.name }}</div>
        <div
            v-if="
                productVariant.salesChannels.length > 0 &&
                productVariant.salesChannels[0].tax !== undefined &&
                productVariant.salesChannels[0].tax.tax !== undefined
            "
            class="is-justify-content-center">
            <Translate>.preview.price</Translate> :
            <PriceView
                :price="
                    productVariant.price * (1 + productVariant.salesChannels[0].tax.tax)
                "
                :precision="2"
                :owner="productVariant.owner"
                with-currency></PriceView>
        </div>
    </VFlex>
</template>

<style lang="scss" scoped>
.preview-product {
    max-width: 10em;
}
</style>
