<script setup lang="ts">
import { useVModel, computedAsync, watchArray } from "@vueuse/core";
import * as yup from "yup";
import { v4 as uuid } from "uuid";
import moment from "moment-with-locales-es6";
import { ref, watch, computed, reactive } from "vue";
import { type GroupType, type Driver } from "../models/race/group";
import { useUserSession } from "../stores/userSession";

export interface PaginatedDriversProps {
    modelValue: Driver[];
    disabledDrivers: boolean[];
    raceGroupType: GroupType;
}

export interface PaginatedDriversEmits {
    (event: "update:modelValue", value?: any): void;
}

const PAGE_SIZE = 10;

const props = defineProps<PaginatedDriversProps>();
const emits = defineEmits<PaginatedDriversEmits>();

const isUpdated = ref(true);
const userSession = useUserSession();
const drivers = useVModel(props, "modelValue", emits);
const pageNumber = ref(0);
const pages = computed((): number[] => {
    const nbPage = Math.ceil(drivers.value.length / PAGE_SIZE);
    const result: number[] = [];
    for (let i = 0; i < nbPage; ++i) result.push(i);
    return result;
});
const startIndex = computed(() => PAGE_SIZE * pageNumber.value);
const endIndex = computed(() => startIndex.value + PAGE_SIZE);
const currentPage = reactive(new Array<Driver>());

function getPageContent() {
    return drivers.value.slice(startIndex.value, endIndex.value);
}

function setPageContent() {
    currentPage.length = 0;
    setTimeout(() => {
        currentPage.push(...getPageContent());
    }, 1);
}

/**
 * Takes the informations of the complete array updates and update the page accordingly
 * @param value The new value of the complete array
 * @param old  The old value of the complete array
 * @param added The elements added to the complete array
 * @param removed The elements removed from the complete array
 */
function updatePageContent(value: any[], old: any[], added: Driver[], removed: Driver[]) {
    if (removed.length > 0) {
        isUpdated.value = false;
        removed.forEach((driver) => {
            const pageIndex = currentPage.indexOf(driver);
            currentPage.splice(pageIndex, 1);
        });
        if (currentPage.length < PAGE_SIZE) {
            setTimeout(() => {
                const page = getPageContent().filter(
                    (driver) => !currentPage.includes(driver),
                );
                currentPage.push(...page);
                isUpdated.value = true;
            }, 1);
        }
    }
    if (added.length > 0) {
        if (currentPage.length < PAGE_SIZE) {
            const page = getPageContent().filter(
                (driver) => !currentPage.includes(driver),
            );
            currentPage.push(...page);
        }
    }
    if (currentPage.length === 0 && pageNumber.value !== 0) {
        changePage(pageNumber.value - 1);
    }
}

watch(pageNumber, setPageContent, { immediate: true });

watchArray(drivers, updatePageContent, { deep: true });

function deleteDriver(driver: Driver) {
    const removeIndex = drivers.value.indexOf(driver);
    drivers.value.splice(removeIndex, 1);
}

function changePage(pageNum: number) {
    pageNumber.value = pageNum;
}

const schema = yup.object();
const rootSchema = yup.array().of(schema).min(1, "validation.required");

const under18 = moment().subtract(18, "year");
const countChild = computedAsync(async () => {
    await Promise.all(
        drivers.value.map((driver: Driver) => {
            if (driver.customer !== undefined)
                return driver.customer.$getMetadata().refresh();
        }),
    );
    return {
        numberOfAdult: drivers.value.filter((driver: Driver) => {
            if (driver.customer === undefined || driver.customer.birthdate === undefined)
                return true;
            return driver.customer.birthdate.isSameOrAfter(under18);
        }).length,
        numberOfChildren: drivers.value.filter((driver: Driver) => {
            if (driver.customer === undefined || driver.customer.birthdate === undefined)
                return false;
            return driver.customer.birthdate.isBefore(under18);
        }).length,
    };
});

const keys: any = {};
function getKey(value) {
    const findedKey = Object.keys(keys).find((key) => keys[key] === value);
    if (findedKey) return findedKey;
    const key = uuid();
    keys[key] = value;
    return key;
}
</script>

<template>
    <VFlex :flex-direction="'column'">
        <VValidation
            v-slot="{ field: driversList }"
            v-model="currentPage"
            property="drivers"
            :schema="rootSchema">
            <TranslateNamespace path=".drivers">
                <VLabel>
                    <Translate :values="countChild">{{ ".label" }}</Translate>
                </VLabel>
                <VFlex
                    v-if="isUpdated"
                    class="is-flex-wrap-wrap is-justify-content-space-between">
                    <VFlex
                        v-for="(row, index) in driversList.value"
                        :key="index"
                        class="is-flex-basis-half py-2">
                        <VValidation
                            v-slot="{ field: driver }"
                            v-model="driversList.value[index]"
                            :property="'[' + getKey(row) + ']'"
                            :schema="schema"
                            class="is-flex-direction-column is-flex-grow-1">
                            <VFlex
                                v-if="driver.value !== undefined"
                                class="is-flex-direction-column is-flex-grow-1">
                                <VFlex>
                                    <FindCustomer
                                        v-model="driver.value.customer"
                                        required
                                        :options="{ driverBooking: true }"
                                        class="is-flex-grow-1 mx-1"></FindCustomer>
                                </VFlex>
                                <VFlex class="is-flex-grow-1">
                                    <VFieldModel
                                        v-model="driver.value"
                                        property="kartType"
                                        class="is-flex-grow-2 mx-1"></VFieldModel>
                                    <VFieldModel
                                        v-model="driver.value"
                                        property="kartSpeed"
                                        class="is-flex-grow-1 mx-1"></VFieldModel>
                                    <VFieldModel
                                        v-model="driver.value"
                                        property="product"
                                        :select-options-wheres="[
                                            [
                                                'owner',
                                                '==',
                                                userSession.user?.owner?.$getID(),
                                            ],
                                            [
                                                'raceGroupType',
                                                '==',
                                                raceGroupType?.$getID(),
                                            ],
                                            [
                                                'kartType',
                                                '==',
                                                driversList.value[
                                                    index
                                                ].kartType?.$getID(),
                                            ],
                                            [
                                                'kartSpeeds',
                                                'array-contains',
                                                driversList.value[
                                                    index
                                                ].kartSpeed?.$getID(),
                                            ],
                                        ]"
                                        class="is-flex-grow-2 mx-1"></VFieldModel>
                                </VFlex>
                                <VFlex>
                                    <VFieldModel
                                        v-model="driver.value"
                                        property="nickName"
                                        class="is-flex-grow-1 mx-1"></VFieldModel>
                                    <VFieldModel
                                        v-model="driver.value"
                                        property="driverTags"
                                        class="is-flex-grow-1 mx-1"></VFieldModel>
                                </VFlex>
                            </VFlex>
                        </VValidation>
                        <VFlex class="is-align-self-center mx-1">
                            <VIconButton
                                icon="delete"
                                color="danger"
                                :disabled="disabledDrivers[index]"
                                light
                                circle
                                delete
                                @click="
                                    deleteDriver(driversList.value[index])
                                "></VIconButton>
                        </VFlex>
                    </VFlex>
                </VFlex>
            </TranslateNamespace>
        </VValidation>
        <VFlex
            v-if="pages.length > 1"
            :flex-direction="'row'"
            :justify-content="'center'"
            class="page-selector">
            <VButton
                v-for="page of pages"
                :key="page"
                :class="{ 'is-active': pageNumber === page }"
                class="page-button"
                @click="changePage(page)"
                >{{ page + 1 }}</VButton
            >
        </VFlex>
    </VFlex>
</template>

<style lang="scss">
.page-selector {
    margin-top: 10px;
}

.page-button {
    margin: 0 2px;
}
</style>