<script lang="ts">
import type { Ref } from "vue";
import { computed, ref } from "vue";
import { defineComponent, inject, toRefs, watch } from "vue";
import { ProductVariantComposition } from "../../../models/product";
import { ProductVariant } from "../../../models/product";
import {
    ProductDistributionChannel,
    ProductSalesChannel,
} from "../../../models/product/channel";
import { useUserSession } from "../../../stores/userSession";
import * as yup from "yup";
import { StockAlert } from "../../../models/product/";
import { useTranslate } from "addeus-common-library/stores/translate";
import { QuantityType } from "../../../models/product";
import { useBarcode } from "../../../stores/barcode";
import { MeasureUnit } from "../../../models/product/measureUnit";
import { useDoc } from "addeus-common-library/stores/firestore";

export function modelEvents() {
    return {
        onBeforeCreate(productVariant: ProductVariant) {
            const { user } = useUserSession();
            if (user === null) throw new Error("No owner selected");
            productVariant.owner = user.owner;
        },
        onBeforeEdit(productVariant: ProductVariant) {
            const { user } = useUserSession();
            if (user === null) throw new Error("No owner selected");
            productVariant.owner = user.owner;
        },
    };
}
export default defineComponent({
    props: {
        form: {
            type: Object,
            required: true,
        },
    },
    setup(props: any) {
        const { translate } = useTranslate();
        const schemaObjectRequired = yup.object().required("validation.required");
        const schemaStringRequired = yup.string().required("validation.required");
        const productVariant: Ref<ProductVariant> = toRefs(props).form;
        const totalPrice = computed({
            get() {
                return (
                    productVariant.value.price +
                    productVariant.value.price *
                        productVariant.value.salesChannels[0].tax.tax
                );
            },
            set(value: number) {
                productVariant.value.price =
                    value / (1 + productVariant.value.salesChannels[0].tax.tax);
            },
        });
        const totalPriceSchema = yup
            .number()
            .required("validation.required")
            .transform((value) => (isNaN(value) ? undefined : value));

        const isEdit = inject<Ref<boolean>>("isEdit");

        const hasComposition = computed(
            () => productVariant.value.quantityType === QuantityType.NoQuantity,
        );

        const steps = computed(() => {
            const newSteps = [translate(".wizard.variant").value];
            if (hasComposition.value)
                newSteps.push(translate(".wizard.composition").value);
            newSteps.push(translate(".wizard.alerts").value);
            return newSteps;
        });

        const lastStepName = computed(() => `step-${hasComposition.value ? "3" : "2"}`);

        useBarcode((barcode) => {
            productVariant.value.barcode = barcode;
        });

        const measureSchema = yup.string().nullable().required("validation.required");

        const getMeasureOption = (measureUnit: MeasureUnit) => {
            return {
                label: measureUnit,
                value: measureUnit,
            };
        };

        const measureUnitOptions = computed(() => {
            if (productVariant.value.quantityType === QuantityType.Volume) {
                return [
                    getMeasureOption(MeasureUnit.L),
                    getMeasureOption(MeasureUnit.cL),
                    getMeasureOption(MeasureUnit.mL),
                ];
            } else if (productVariant.value.quantityType === QuantityType.Weight) {
                return [
                    getMeasureOption(MeasureUnit.Kg),
                    getMeasureOption(MeasureUnit.g),
                ];
            }
            return [];
        });

        watch(
            () => productVariant.value.quantityType,
            () => {
                const availableMeasureUnits = measureUnitOptions.value.map(
                    (option) => option.value,
                );
                if (
                    productVariant.value.measureUnit !== undefined &&
                    !availableMeasureUnits.includes(productVariant.value.measureUnit)
                ) {
                    productVariant.value.measureUnit = undefined;
                }
            },
        );

        const compositionQuantityLabels = ref(new Map<string, string>());
        const getQuantityLabel = async (productVariantId: string) => {
            const variant = useDoc(ProductVariant, productVariantId);
            await variant.$getMetadata().isFullfilling;
            return `${translate(".compositions.quantity.label").value}`;
        };
        const updateCompositionQuantityLabel = async () => {
            if (productVariant.value.compositions === undefined) return;
            const promises = productVariant.value.compositions.map(
                async (composition: ProductVariantComposition): Promise<void> => {
                    if (
                        composition.productVariantId !== undefined &&
                        !compositionQuantityLabels.value.has(composition.productVariantId)
                    ) {
                        compositionQuantityLabels.value.set(
                            composition.productVariantId,
                            await getQuantityLabel(composition.productVariantId),
                        );
                    }
                },
            );
            await Promise.all(promises);
        };
        void updateCompositionQuantityLabel();
        watch(
            () =>
                productVariant.value.compositions
                    .map(
                        (composition: ProductVariantComposition) =>
                            composition.productVariantId,
                    )
                    .filter(
                        (
                            id: string,
                            index: number,
                            array: Array<ProductVariantComposition>,
                        ) => array.indexOf(id) === index,
                    ),
            updateCompositionQuantityLabel,
        );

        const alerteLabel = computed(() => {
            if (productVariant.value.measureUnit === undefined) {
                return translate(".alerts.label").value;
            }
            return `${translate(".alerts.label").value} (${
                productVariant.value.measureUnit
            })`;
        });

        return {
            isEdit,
            schemaObjectRequired,
            schemaStringRequired,
            productVariant,
            ProductSalesChannel,
            ProductDistributionChannel,
            ProductVariant,
            ProductVariantComposition,
            StockAlert,
            hasComposition,
            steps,
            totalPrice,
            totalPriceSchema,
            lastStepName,
            translate,
            QuantityType,
            measureSchema,
            measureUnitOptions,
            compositionQuantityLabels,
            alerteLabel,
        };
    },
});
</script>

<template>
    <VFlex>
        <VFlexItem flex-grow="1" :flex-shrink="1">
            <VMultipage
                v-model="productVariant"
                :mode="isEdit ? 'tabs' : 'wizard'"
                :hide-steps="false"
                :hide-actions="false"
                :steps="steps">
                <template #step-1="{ field: productField }">
                    <VFieldModel
                        v-model="productField.value"
                        property="name"></VFieldModel>

                    <VFieldModel
                        v-model="productField.value"
                        property="picture"></VFieldModel>

                    <VEntities
                        v-slot="{ field }"
                        v-model="productField.value.salesChannels"
                        :sortable="false"
                        :only-ids="false"
                        :schema="schemaObjectRequired"
                        :model="ProductSalesChannel"
                        :opened="false"
                        property="salesChannels"
                        multiple
                        required>
                        <VFieldModel
                            v-model="field.value"
                            property="channel"
                            required></VFieldModel>
                        <VFieldModel
                            v-model="field.value"
                            property="additionnalPrice"
                            required></VFieldModel>
                        <VFieldModel
                            v-model="field.value"
                            property="tax"
                            required></VFieldModel>
                    </VEntities>

                    <VEntities
                        v-slot="{ field }"
                        v-model="productField.value.distributionChannels"
                        :sortable="false"
                        :only-ids="false"
                        :schema="schemaObjectRequired"
                        :model="ProductDistributionChannel"
                        :opened="false"
                        property="distributionChannels"
                        multiple
                        required>
                        <VFieldModel
                            v-model="field.value"
                            property="channel"
                            required></VFieldModel>
                        <VFieldModel
                            v-model="field.value"
                            property="additionnalPrice"
                            required></VFieldModel>
                    </VEntities>

                    <VFieldModel
                        v-model="productField.value"
                        property="canBeSold"></VFieldModel>
                    <VFieldModel
                        v-model="productField.value"
                        property="canBeBought"></VFieldModel>
                    <VFieldModel
                        v-model="productField.value"
                        property="quantityType"></VFieldModel>
                    <TranslateNamespace path=".measureUnit">
                        <VValidation
                            v-if="
                                productField.value.quantityType !==
                                    QuantityType.NoQuantity &&
                                productField.value.quantityType !==
                                    QuantityType.Service &&
                                productField.value.quantityType !== QuantityType.Unitary
                            "
                            v-slot="{ field: measureUnitField }"
                            v-model="productField.value.measureUnit"
                            property="measureUnit"
                            :schema="measureSchema">
                            <VField v-slot="{ id }" label=".label">
                                <VControl :has-error="measureUnitField.errors.length > 0">
                                    <Multiselect
                                        :id="id"
                                        ref="multiselect"
                                        v-model="measureUnitField.value"
                                        name="measureUnit"
                                        :required="true"
                                        :mode="'single'"
                                        :options="measureUnitOptions"
                                        :native-support="true"
                                        open-direction="top">
                                    </Multiselect>
                                    <p
                                        v-for="error in measureUnitField.errors"
                                        :key="error"
                                        class="help is-danger">
                                        <Translate>.{{ error }}</Translate>
                                    </p>
                                </VControl>
                            </VField>
                        </VValidation>
                    </TranslateNamespace>
                    <VValidation
                        v-slot="{ field }"
                        ref="validation"
                        v-model="totalPrice"
                        property="price"
                        :schema="totalPriceSchema">
                        <VField label=".price.label">
                            <VControl :has-error="field.errors.length > 0">
                                <VInput
                                    v-model="field.value"
                                    type="number"
                                    :required="true"
                                    class="input" />
                            </VControl>
                        </VField>
                    </VValidation>
                    <!--VFieldModel
                        v-model="{ price: totalPrice }"
                        property="price"></VFieldModel-->
                    <VFieldModel
                        v-if="
                            productField.value.quantityType !== QuantityType.NoQuantity &&
                            productField.value.quantityType !== QuantityType.Service
                        "
                        v-model="productField.value"
                        property="barcode"></VFieldModel>
                    <VFieldModel
                        v-model="productField.value"
                        property="comment"></VFieldModel>
                </template>
                <template v-if="hasComposition" #step-2="{ field: productField }">
                    <VEntities
                        v-slot="{ field: compositionField }"
                        v-model="productField.value.compositions"
                        :sortable="false"
                        :only-ids="false"
                        :schema="schemaObjectRequired"
                        :model="ProductVariantComposition"
                        :opened="false"
                        property="compositions"
                        multiple
                        :required="false">
                        <TranslateNamespace path=".variant">
                            <VLabel><Translate>.label</Translate></VLabel>
                            <VValidation
                                v-slot="{ field }"
                                v-model="compositionField.value.productVariantId"
                                property="productVariantId"
                                :schema="schemaStringRequired">
                                <VControl :has-error="field.errors.length > 0">
                                    <FindProductVariant
                                        v-model="field.value"
                                        :ignore="productVariant"
                                        required></FindProductVariant>
                                    <p
                                        v-for="error in field.errors"
                                        :key="error"
                                        class="help is-danger">
                                        <Translate>.{{ error }}</Translate>
                                    </p>
                                </VControl>
                            </VValidation>
                        </TranslateNamespace>

                        <VFieldModel
                            v-model="compositionField.value"
                            property="quantity"
                            :label="
                                compositionQuantityLabels.get(
                                    compositionField.value.productVariantId,
                                )
                            "
                            required></VFieldModel>
                    </VEntities>
                </template>
                <template #[lastStepName]="{ field: productField }">
                    <VFieldModel
                        v-model="productField.value"
                        :label="alerteLabel"
                        property="quantityAlert"></VFieldModel>
                </template>
            </VMultipage>
        </VFlexItem>
        <PreviewProductVariant :product-variant="productVariant"></PreviewProductVariant>
    </VFlex>
</template>
