import { acceptHMRUpdate, defineStore } from "pinia";
import { useUserSession } from "addeus-common-library/stores/userSession";
import {
    useCollection,
    findDoc,
    clearCache,
} from "addeus-common-library/stores/firestore";
import { Employee } from "../models/employee";
import { computed, ref } from "vue";
import { useStorage } from "@vueuse/core";
import functions from "addeus-common-library/stores/function";
import { useModal } from "addeus-common-library/stores/modal";
import { useLogger } from "./logs";
import type { Permission } from "../models/role";
import { AES, enc } from "crypto-js";
import PINComponent from "/@src/components/auth/pin.vue";

export type UserData = Record<string, any> | null;

export const useEmployeeSession = defineStore("employeeSession", () => {
    const userSession = useUserSession();
    const modal = useModal();
    const tokens = useStorage<{
        [name: string]: string;
    }>("firebase-multiple", {});
    const logger = useLogger();

    const wheres = computed(() => {
        const userIDs = Object.keys(tokens.value);
        return [["user", "in", userIDs]];
    });

    const employees = useCollection(Employee, {
        wheres,
    });
    const employee = ref<Employee | null>(null);

    userSession.configurePermissionManagement((user, permission) => {
        if (
            employee.value === null ||
            employee.value.role === undefined ||
            !Array.isArray(employee.value.role.permissions)
        )
            return false;

        return employee.value.role.permissions.includes(permission as Permission);
    });

    async function definePIN() {
        const promise = new Promise((resolve) => {
            const pinModal = modal.createModal(PINComponent, {
                title: "sidebar.user.newPin.title",
                events: {
                    code(pin: string) {
                        pinModal.close();
                        resolve(pin);
                    },
                },
            });
        });

        const pin = await promise;
        await functions.setPin(pin);
        return pin;
    }

    async function select(
        selectedEmployee: Employee,
        retry: boolean = false,
    ): Promise<void> {
        if (userSession.user === null || userSession.user.uid !== selectedEmployee.user) {
            if (tokens.value[selectedEmployee.user] === undefined)
                throw new Error("User not logged in");

            const promise = new Promise((resolve) => {
                const pinModal = modal.createModal(PINComponent, {
                    title: "sidebar.user.pin.title",
                    props: {
                        retry,
                    },
                    events: {
                        code(pin: string) {
                            pinModal.close();
                            resolve(pin);
                        },
                    },
                });
            });

            const pin = await promise;

            try {
                const decrypted = AES.decrypt(
                    tokens.value[selectedEmployee.user],
                    pin,
                ).toString(enc.Utf8);
                const userJSON = JSON.parse(decrypted);
                userSession.select(userJSON);

                void logger.logOnUserChangeAccountWithPIN(
                    employee.value.$getPlainForLogs(),
                    selectedEmployee.$getPlainForLogs(),
                    selectedEmployee.owner.$getID(),
                );
            } catch (e) {
                return select(selectedEmployee, true);
            }
        }
        employee.value = selectedEmployee;
        await functions.setCurrentOwner(selectedEmployee.owner.$getID());
    }

    void userSession.onUserChange(async (authUser, customAttributes) => {
        if (
            authUser !== null &&
            customAttributes !== null &&
            customAttributes.owners !== undefined &&
            authUser.uid.match(/^display-/) === null
        ) {
            if (typeof customAttributes.pin !== "string")
                customAttributes.pin = await definePIN();
            employees.isUpdating = true;
            tokens.value[authUser.uid] = AES.encrypt(
                JSON.stringify(authUser.toJSON()),
                customAttributes.pin,
            ).toString();

            if (employee.value === null || employee.value.user !== authUser.uid) {
                await employees.fetched();
                const otherCurrentEmployee = employees.find((e) => {
                    return (
                        e.user === authUser.uid &&
                        e.owner.$getID() === customAttributes.current
                    );
                });
                if (otherCurrentEmployee === undefined && employees.length > 0) {
                    employee.value = employees[0];
                    await functions.setCurrentOwner(employee.value.owner?.$getID());
                } else if (otherCurrentEmployee === undefined) {
                    employee.value = null;
                } else {
                    employee.value = otherCurrentEmployee;
                }
            }
        }
    });

    return {
        login: userSession.login,
        async logout() {
            const currentUid = userSession.user?.uid;
            await userSession.logout();
            delete tokens.value[currentUid];
            const userIds = Object.keys(tokens.value);
            if (userIds.length > 0) {
                await employees.fetched();
                await select(employees[0], false);
            } else {
                clearCache();
                employee.value = null;
            }
        },
        waitInitialized: userSession.waitInitialize.then(async () => {
            if (
                userSession.isLoggedIn === true &&
                employee.value !== null &&
                employee.value !== undefined
            ) {
                // employee.value = await findDoc(Employee, {
                //     wheres: [["user", "==", userSession.user.uid]],
                // });

                await employees.fetched();
                await employee.value?.role?.$getMetadata().isFullfilled;
                void logger.logOnUserConnected(
                    employee.value.$getPlainForLogs(),
                    employee.value.owner.$getID(),
                );
            } else if (userSession.isLoggedIn === true) {
                employee.value = await findDoc(Employee, {
                    wheres: [["user", "==", userSession.user.uid]],
                });
                await employees.fetched();
                await employee.value?.role?.$getMetadata().isFullfilled;
            }
        }),
        selectUser: select,
        user: employee,
        users: employees,
        hasPermission: userSession.hasPermission,
        isLoggedIn() {
            return userSession.isLoggedIn;
        },
    };
});

/**
 * Pinia supports Hot Module replacement so you can edit your stores and
 * interact with them directly in your app without reloading the page.
 *
 * @see https://pinia.esm.dev/cookbook/hot-module-replacement.html
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useEmployeeSession, import.meta.hot));
}
