import { acceptHMRUpdate, defineStore } from "pinia";
import { ref } from "vue";
import { findDoc } from "addeus-common-library/stores/firestore";
import { Customer } from "../models/customer";
import { useUserSession } from "addeus-common-library/stores/userSession";
import functions from "addeus-common-library/stores/function";

export const useCustomerSession = defineStore("customerSession", () => {
    const userSession = useUserSession();

    const customer = ref<Customer | null | undefined>(null);
    const waitInitialize = new Promise((resolve) => {
        void userSession.onUserChange(async (user) => {
            if (user !== undefined && user !== null) {
                customer.value = await findDoc(Customer, {
                    wheres: [["user", "==", user.uid]],
                });
            } else {
                customer.value = null;
            }
            resolve(customer.value);
        });
    });

    async function isEmailExist(email: string, id: string): Promise<boolean> {
        if (!email || email.length <= 0) return false;
        const customer = await findDoc(Customer, {
            wheres: [["email", "==", email]],
        });
        return !!customer && customer.$getID() !== id;
    }

    async function isPhoneNumberExist(phoneNumber: string, id: string): Promise<boolean> {
        if (!phoneNumber || phoneNumber.length <= 0) return false;
        const customer = await findDoc(Customer, {
            wheres: [["phoneNumber", "==", phoneNumber]],
        });
        return !!customer && customer.$getID() !== id;
    }

    const isLoggedIn = () => {
        if (userSession.isLoggedIn === false) return false;
        return true;
    };

    async function signup(customer: {
        lastName: string;
        firstName: string;
        email: string;
        phoneNumber: string;
        password: string;
        acceptGTC: boolean;
    }) {
        return functions.signUpCustomer(customer);
    }

    return {
        customer,
        waitInitialize,
        isEmailExist,
        isPhoneNumberExist,
        isLoggedIn,
        login: userSession.login,
        signup,
    } as const;
});

/**
 * Pinia supports Hot Module replacement so you can edit your stores and
 * interact with them directly in your app without reloading the page.
 *
 * @see https://pinia.esm.dev/cookbook/hot-module-replacement.html
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useCustomerSession, import.meta.hot));
}
