import { Entity, Var, EntityBase } from "addeus-common-library/stores/firestore";
import { Owner } from "../owner";
import { Input } from "addeus-common-library/stores/firestore";
import { Device } from "./device";
import { EntityArray } from "addeus-common-library/stores/firestore/entity";
import { useUserSession } from "../../stores/userSession";

export class Item extends Entity {
    static collectionName = "raceItems";

    @Var(String)
    name?: string;

    @Var(String)
    color?: string;

    @Var(String)
    icon?: string;

    @Var(Number)
    effectiveTime?: number = 3000;

    @Var(Number)
    ownSpeedRatio?: number = 1;

    @Var(Number)
    ownMoney?: number = 0;

    @Var(Number)
    costMoney?: number = 0;

    @Var(Number)
    allSpeedRatio?: number = 1;

    @Var(Boolean)
    ownImmunity?: boolean = false;

    @Var(Boolean)
    activateOnAcquisition?: boolean = true;

    @Var(Number)
    acceleration?: [number, number, number, number] = [0, 0.25, 0.5, 1];

    @Var(Owner)
    owner?: Owner;

    toString() {
        return this.name;
    }
}

export enum Status {
    idle = "idle",
    playing = "playing",
    paused = "paused",
}

export class ProjectorFrame extends EntityBase {
    @Var(Item)
    @Input("select", {
        required: true,
        options: {
            entity: Item,
            where() {
                const userSession = useUserSession();
                return [["owner", "==", userSession.user?.owner?.$getID()]];
            },
        },
    })
    item?: Item;

    @Var(Number)
    @Input("number", { required: true })
    duration: number = Infinity;

    @Var(Status)
    status: Status = Status.idle;
}

export class ProjectorScenario extends EntityBase {
    @Var(Array.of(ProjectorFrame))
    frames: ProjectorFrame[] = EntityArray();

    @Var(Device)
    @Input("select", {
        required: true,
        options: {
            entity: Device,
            where() {
                const userSession = useUserSession();
                return [
                    ["owner", "==", userSession.user?.owner?.$getID()],
                    ["kind", "==", "projector"],
                ];
            },
        },
    })
    projector?: Device;
}
