import { Entity, EntityBase, Input, Var } from "addeus-common-library/stores/firestore";
import { Device, Kind } from "./device";
import tinyColor from "tinycolor2";
import { EntityArray } from "addeus-common-library/stores/firestore/entity";

export class BatteryType extends Entity {
    static collectionName = "batteryTypes";
    @Var(String)
    name: string;

    @Var(Number)
    minVoltage: number;

    @Var(Number)
    maxVoltage: number;

    @Var(Number)
    maxConsecutiveRaces: number = 0;

    toString() {
        return this.name;
    }
}

export class Battery extends EntityBase {
    @Var(BatteryType)
    @Input("radio", {
        required: true,
        autoSelect: true,
        options: {
            entity: BatteryType,
        },
    })
    type: BatteryType;

    @Var(Number)
    @Input("number", {
        disabled: true,
    })
    voltage: number = 0;

    @Var(Number)
    consecutiveRaces: number = 0;

    getRemainingBatteryLevel(): number {
        return (
            (this.voltage / 10 - this.type.minVoltage) /
            (this.type.maxVoltage - this.type.minVoltage)
        );
        // return 1 - this.consecutiveRaces / this.type.maxConsecutiveRaces;
    }

    toString() {
        if (this.type === undefined) return "";
        return `${this.type.name} (${this.voltage}V)`;
    }
}

export class Type extends Entity {
    static collectionName = "kartTypes";

    @Var(String)
    name?: string;

    @Var(Number)
    numberOfPlaces: number = 1;

    @Var(Array.of(BatteryType))
    batteryTypes: BatteryType[] = EntityArray();

    @Var(String)
    icon?: string;

    @Var(String)
    color?: string;

    weight: number = 0;

    toString(): string | undefined {
        return this.name;
    }
}

export class Kart extends Device {
    static collectionName = "karts";

    kind: Kind = Kind.kart;

    @Var(String)
    @Input("color", { required: true })
    color?: string;

    @Var(Type)
    @Input("radio", {
        required: true,
        autoSelect: true,
        options: {
            entity: Type,
        },
        orders() {
            return [["weight", "asc"]];
        },
    })
    type?: Type;

    @Var(Array.of(Battery))
    @Input("array", { of: Battery })
    batteries: Battery[] = [];

    toString() {
        return `${this.name}`;
    }

    getRemainingBatteryLevel() {
        return (
            this.batteries.reduce((prev, battery) => {
                return prev + battery.getRemainingBatteryLevel();
            }, 0) / this.batteries.length
        );
    }

    getRemainingBatteryLevelString() {
        return `${(this.getRemainingBatteryLevel() * 100).toFixed(1)}%`;
    }

    getBatteryVoltages() {
        return `${(this.batteries[0].voltage / 10).toFixed(1)}V\t${(
            this.batteries[1].voltage / 10
        ).toFixed(1)}V\n${(this.batteries[2].voltage / 10).toFixed(1)}V\t${(
            this.batteries[3].voltage / 10
        ).toFixed(1)}V`;
    }

    isColorDark() {
        const color = tinyColor(this.color);
        return color.isDark();
    }
}
