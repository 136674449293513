import { createApp as createClientApp, h, Suspense } from "vue";

import { createHead } from "@vueuse/head";
import { createPinia } from "pinia";
import { createI18n } from "./i18n";
import { createRouter } from "./router";
import Track2MaxApp from "./components/Track2Max.vue";
import Speed2MaxApp from "./components/Speed2Max.vue";
import VProgress from "addeus-common-library/components/base/progress/VProgress.vue";
import filters from "addeus-common-library/stores/filters";
import "addeus-common-library/default-styles";
import "./scss/index.scss";
import * as Icon from "addeus-common-library/stores/icon";

// import { initDarkmode } from "addeus-common-library/stores/darkmode";
export type Track2MaxAppContext = Awaited<ReturnType<typeof createApp>>;

import { registerGlobalComponents } from "./app-custom";

Icon.setIconClasses(["material-icons-outlined"]);

export function createApp() {
    const app = createClientApp({
        // This is the global app setup function
        setup() {
            /**
             * Initialize the darkmode watcher
             *
addeus-common-library/stores/darkmode
             */
            // disable dark mode: initDarkmode();

            /**
             * Here we are creating a render function for our main app with
             * the main Track2MaxApp component, wrapped in a Suspense component
             * to handle async loading of the app.
             * Template equivalent would be:
             *
             * <template>
             *   <Susupense>
             *     <Track2MaxApp />
             *   </Susupense>
             * </template>
             */
            return () => {
                /**
                 * The Suspense component is needed to use async in child components setup
                 * @see https://v3.vuejs.org/guide/migration/suspense.html
                 */
                return h(Suspense, VProgress, {
                    default: () =>
                        h(
                            import.meta.env.VITE_APP === "Track2Max"
                                ? Track2MaxApp
                                : Speed2MaxApp
                        ),
                });
            };
        },
    });

    const pinia = createPinia();
    const head = createHead();
    const i18n = createI18n();
    const router = createRouter();

    const track2max = {
        app,
        router,
        i18n,
        head,
        pinia,
    };

    app.use(track2max.pinia);
    app.use(track2max.head);
    app.use(track2max.i18n);
    registerGlobalComponents(track2max);

    app.use(track2max.router);

    app.config.globalProperties.$filters = filters;

    return track2max;
}
