<script setup lang="ts">
import IconCourse from "../../assets/icons/icon_course.svg";
import IconKart from "../../assets/icons/icon-kart.svg";
import IconEventUs from "../../assets/icons/icon-event-us.svg";
import IconEventYour from "../../assets/icons/icon-event-your.svg";
import IconProductTechnology from "../../assets/icons/icon-product-technology.svg";
import IconDiscover from "../../assets/icons/icon-discover.svg";
import { useRouter } from "vue-router";
import { useOwner } from "../../stores/website/owner";
import { useCustomerSession } from "../../stores/customerSession";

const ownerManager = useOwner();
const router = useRouter();
const customerSession = useCustomerSession();
</script>

<template>
    <TranslateNamespace path=".sidebar">
        <VFlex id="sidebar" flex-direction="column" class="px-6 py-7">
            <img
                src="/images/sidebar/elements/SB_element_top.svg"
                alt=""
                class="top-element" />
            <img
                src="/images/sidebar/elements/SB_element_bot.svg"
                alt=""
                class="bot-element" />
            <VFlex flex-direction="column" class="mb-6">
                <a href="javascript:void(0)" @click="router.push('/')">
                    <img
                        src="/images/sidebar/T2M_logo_white.svg"
                        alt="logo"
                        class="mw-logo z-10 logo-header" />
                </a>
                <VFlex v-if="ownerManager.owner" flex-direction="row" class="mt-6 mb-5">
                    <VFlex flex-direction="column">
                        <VFlex
                            flex-direction="row"
                            row-gap="1rem"
                            column-gap="1rem"
                            align-items="flex-start">
                            <img
                                :src="IconCourse"
                                alt="piste"
                                style="height: 30px"
                                class="mt-3" />
                            <div class="is-size-6 is-weight-600">
                                <!-- MA : the value must be dynamic here ! -->
                                <span class="has-text-primary">{{
                                    ownerManager.owner?.address.city
                                }}</span>
                                <div>
                                    <RouterLink
                                        to="/owners"
                                        class="is-size-6 is-weight-400 has-text-white is-underline">
                                        <Translate path=".editTrack"></Translate>
                                    </RouterLink>
                                </div>
                            </div>
                        </VFlex>
                    </VFlex>
                </VFlex>
                <ButtonPrimary
                    v-if="!ownerManager.owner"
                    color="info"
                    class="mt-6 mb-5 is-align-self-flex-start btn-info-hover is-size-6"
                    style="font-family: mohol !important"
                    @click="router.push('/owners')">
                    <!-- <VIcon icon="/images/sidebar/icons/icon_course.svg"></VIcon> -->
                    <span class="is-uppercase is-size-6 is-asap">
                        <Translate path=".choose"></Translate>
                    </span>
                </ButtonPrimary>

                <ButtonPrimary
                    v-if="ownerManager.owner && ownerManager.owner.bookableOnline"
                    color="primary"
                    class="is-align-self-flex-start btn-primary-hover is-size-6"
                    style="font-family: mohol !important"
                    @click="$router.push('/booking')">
                    <!-- <VIcon icon="/images/kart/book-button-1.png"></VIcon> -->
                    <span class="is-uppercase is-size-6 is-asap">
                        <Translate path=".book"></Translate>
                    </span>
                </ButtonPrimary>

                <!-- <ButtonPrimary
                    v-if="ownerManager.owner"
                    color="info"
                    class="mt-6"
                    @click="router.push('/booking')">
                    <VIcon icon="/images/sidebar/icons/icon_course.svg"></VIcon>
                    <Translate>.book</Translate>
                </ButtonPrimary> -->
            </VFlex>
            <VFlex flex-direction="column" class="mb-6">
                <RouterLink
                    to="/kart"
                    class="menu-link has-text-white has-text-weight-bold mb-5">
                    <VFlex flex-direction="row">
                        <div class="mr-3">
                            <img :src="IconKart" alt="logo" />
                        </div>
                        <div>
                            <Translate>.kart</Translate>
                        </div>
                    </VFlex>
                </RouterLink>
                <RouterLink
                    to="/event-us"
                    class="menu-link has-text-white has-text-weight-bold mb-5">
                    <VFlex flex-direction="row">
                        <div class="mr-3">
                            <img :src="IconEventUs" alt="logo" />
                        </div>
                        <div>
                            <Translate>.eventUs</Translate>
                        </div>
                    </VFlex>
                </RouterLink>
                <RouterLink
                    to="/event-your/enterprise"
                    class="menu-link has-text-white has-text-weight-bold mb-5">
                    <VFlex flex-direction="row">
                        <div class="mr-3">
                            <img :src="IconEventYour" alt="logo" />
                        </div>
                        <div>
                            <Translate>.eventYour</Translate>
                        </div>
                    </VFlex>
                </RouterLink>
                <RouterLink
                    to="/product-technology/karting"
                    class="menu-link has-text-white has-text-weight-bold mb-5">
                    <VFlex flex-direction="row">
                        <div class="mr-3">
                            <img :src="IconProductTechnology" alt="logo" />
                        </div>
                        <div>
                            <Translate>.productAndTechnology</Translate>
                        </div>
                    </VFlex>
                </RouterLink>
                <!-- <RouterLink
                    to="/shop"
                    class="menu-link has-text-white has-text-weight-bold mb-5">
                    <VFlex flex-direction="row">
                        <div class="mr-3">
                            <img :src="IconShop" alt="logo" />
                        </div>
                        <div>
                            <Translate>.shop</Translate>
                        </div>
                    </VFlex>
                </RouterLink> -->
                <RouterLink
                    to="/discover/history"
                    class="menu-link has-text-white has-text-weight-bold">
                    <VFlex flex-direction="row">
                        <div class="mr-3">
                            <img :src="IconDiscover" alt="logo" />
                        </div>
                        <div>
                            <Translate>.discover</Translate>
                        </div>
                    </VFlex>
                </RouterLink>
            </VFlex>
            <VFlex flex-direction="column">
                <!-- <VButton to="/account">
                    <img src="" alt="logo" />
                    <Translate>.account</Translate>
                </VButton> -->
                <RouterLink
                    v-if="!customerSession.customer"
                    to="/auth/login"
                    class="menu-link has-text-white has-text-weight-bold">
                    <VFlex flex-direction="row">
                        <div class="mr-3">
                            <img src="/images/sidebar/icons/icon_login.svg" alt="logo" />
                        </div>
                        <div>
                            <Translate>.login</Translate>
                        </div>
                    </VFlex>
                </RouterLink>
                <RouterLink
                    v-if="customerSession.customer"
                    to="/account"
                    class="menu-link has-text-white has-text-weight-bold">
                    <VFlex flex-direction="row">
                        <div class="mr-3">
                            <img src="/images/sidebar/icons/icon_login.svg" alt="logo" />
                        </div>
                        <div>
                            <Translate>.account</Translate>
                        </div>
                    </VFlex>
                </RouterLink>
                <!-- <VDropdown class="mt-3">
                    <template #button>
                        <VButton>Francais</VButton>
                    </template>
                    <template #content>
                        <VDropdownItem>Francais</VDropdownItem>
                    </template>
                </VDropdown> -->
            </VFlex>
        </VFlex>
    </TranslateNamespace>
</template>

<style lang="scss" scoped>
@use "sass:color";

.logo-header {
    cursor: pointer;
}

.is-underline {
    text-decoration: underline;
}

.course-btn-fill {
    width: 250px;
}

#sidebar {
    background: url("/images/sidebar/elements/SB_bg.svg"), $dark;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: inset 0 0 0 1000px rgb(0 12 17 / 80%);
    overflow: auto;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 320px;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    .top-element {
        position: absolute;
        width: 175px;
        z-index: -1;
        top: 0;
        right: 0;
    }

    .bot-element {
        position: fixed;
        z-index: 0;
        width: 90px;
        bottom: 0;
        left: 0;
    }
}

.py-7 {
    padding: 5rem 0;
}

// @media screen and (max-height: 730px) {
//     .py-7 {
//         padding: 3rem 0;
//     }
// }
@media screen and (max-height: 750px) {
    #sidebar {
        .bot-element {
            display: none;
        }
    }
}

@media screen and (max-width: 1024px) {
    #sidebar {
        display: none;
    }
}

.menu-link {
    font-size: 1.3rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
        width: 25px;
    }

    &:hover {
        color: $primary !important;
    }
}

.mw-logo {
    max-width: 200px;
    z-index: 10;
}

.btn-info-hover {
    cursor: pointer;

    &:hover {
        background-color: $primary;
    }
}

.btn-primary-hover {
    &:hover {
        background-color: $info;
    }
}
</style>
