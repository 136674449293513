import { acceptHMRUpdate, defineStore } from "pinia";
import { computedAsync, useStorage } from "@vueuse/core";
import { useDoc } from "addeus-common-library/stores/firestore";
import { Owner } from "../../models/owner";

export const useOwner = defineStore("owner", () => {
    const ownerID = useStorage<string | null>("owner", null);

    const owner = computedAsync(async () => {
        if (ownerID.value === null) return null;
        const o = useDoc(Owner, ownerID.value);
        await o.$getMetadata().refresh();
        return o;
    });

    function select(owner: Owner | string) {
        if (owner instanceof Owner) {
            const id = owner.$getID();
            if (id === undefined) throw new Error("Owner has no ID");
            owner = id;
        }

        if (owner === undefined || typeof owner !== "string") {
            throw new Error("Owner has no ID");
        }
        ownerID.value = owner;
    }

    return {
        select,
        owner,
    };
});

/**
 * Pinia supports Hot Module replacement so you can edit your stores and
 * interact with them directly in your app without reloading the page.
 *
 * @see https://pinia.esm.dev/cookbook/hot-module-replacement.html
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useOwner as any, import.meta.hot));
}
