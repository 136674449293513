import {
    Entity,
    EntityBase,
    Input,
    newDoc,
    Var,
} from "addeus-common-library/stores/firestore/index";
import { uniqueArrayFilter } from "addeus-common-library/utils/array";
import moment from "moment-with-locales-es6";
import { computed } from "vue";
import type { Type as RaceType } from "./index";
import {
    Race,
    Driver as RaceDriver,
    OrderParticipantsType,
    Status as RaceStatus,
} from "./index";
import { Customer, Tag } from "../customer";
import { Owner } from "../owner";
import { Type as KartType } from "./kart";
import { Circuit } from "./circuit";
import { useUserSession } from "/@src/stores/userSession";
import { Employee } from "../employee";
import { Invoice, Order } from "../product/order";
import { GroupType } from "./groupType";
import { Speed as KartSpeed } from "./speed";
import { EntityArray } from "addeus-common-library/stores/firestore/entity";
import { ProductVariant } from "../product";
import { OrderStatus } from "../product/order/orderStatus";

export enum Status {
    confirmed,
    optionnal,
    canceled,
}

export { GroupType } from "./groupType";

export class Driver extends EntityBase {
    @Var(KartType)
    @Input("select", {
        required: true,
        options: {
            entity: KartType,
            orders() {
                return [["weight", "asc"]];
            },
        },
    })
    kartType?: KartType;

    @Var(KartSpeed)
    @Input("select", {
        required: true,
        options: {
            entity: KartSpeed,
            orders() {
                return [["weight", "asc"]];
            },
        },
    })
    kartSpeed?: KartSpeed;

    @Var(ProductVariant)
    @Input("select", {
        required: true,
        autoSelect: true,
        options: {
            entity: ProductVariant,
            orders() {
                return [["price", "desc"]];
            },
            limit: -1,
        },
    })
    product?: ProductVariant;

    @Var(Customer)
    // customer: Customer = newDoc(Customer);
    customer?: Customer;

    @Var(String)
    byName?: string;

    @Var(String)
    uid?: string;

    @Var(Array.of(String))
    tags?: Tag[] = [];

    @Input("select", { options: Tag, multiple: true })
    get driverTags() {
        if (this.customer !== undefined) {
            this.tags = this.customer.tags;
        }
        return this.tags;
    }

    set driverTags(value: Tag[] | undefined) {
        if (value === undefined) return;
        if (this.customer !== undefined) {
            this.customer.tags = value;
        }
        this.tags = value;
    }

    @Input("text", { required: true })
    get nickName() {
        if (this.customer !== undefined) {
            this.byName = this.customer.nickName;
        }
        return this.byName;
    }

    set nickName(value: string | undefined) {
        if (this.customer !== undefined) {
            this.customer.nickName = value;
        }
        this.byName = value;
    }

    get driverID() {
        if (this.uid === undefined && this.customer !== undefined) {
            return this.customer?.$getID();
        }
        return this.uid;
    }

    toString() {
        if (this.kartType === undefined) return this.nickName;
        return `${
            this.nickName
        } (${this.kartType?.toString()} - ${this.kartSpeed?.toString()})`;
    }
}

export class Group extends Entity {
    static collectionName = "raceGroups";
    @Var(moment)
    @Input("datetime", { required: true })
    date?: moment;

    @Var(Number)
    @Input("number", { required: true })
    raceTypePerUser = 1;

    @Var(Status)
    @Input("radio", { required: true, options: Status })
    status: Status = Status.confirmed;

    @Var(Circuit)
    @Input("radio", {
        required: true,
        autoSelect: true,
        options: {
            entity: Circuit,
            where() {
                const userSession = useUserSession();
                return [["owner", "==", userSession.user.owner?.$getID()]];
            },
        },
    })
    circuit?: Circuit;

    @Var(Array.of(Race))
    races: Race[] = EntityArray();

    @Var(Array.of(Driver))
    drivers: Driver[] = EntityArray();

    @Var(Customer)
    bookBy?: Customer;

    @Var(Owner)
    owner?: Owner;

    @Var(GroupType)
    @Input("radio", {
        required: true,
        autoSelect: true,
        options: {
            entity: GroupType,
            where() {
                const userSession = useUserSession();
                return [["owner", "==", userSession.user.owner?.$getID()]];
            },
            orders() {
                return [["weight", "asc"]];
            },
            limit: -1,
        },
    })
    type?: GroupType;

    @Var(KartType)
    @Input("radio", {
        required: true,
        autoSelect: true,
        options: {
            entity: KartType,
            orders() {
                return [["weight", "asc"]];
            },
            limit: -1,
        },
    })
    defaultKartType?: KartType;

    @Var(KartSpeed)
    @Input("radio", {
        required: true,
        autoSelect: true,
        options: {
            entity: KartSpeed,
            orders() {
                return [["weight", "asc"]];
            },
            limit: -1,
        },
    })
    defaultKartSpeed?: KartSpeed;

    @Var(ProductVariant)
    @Input("radio", {
        required: true,
        autoSelect: true,
        options: {
            entity: ProductVariant,
            where(props: { modelValue: Group }) {
                const userSession = useUserSession();
                return computed(() => {
                    return [
                        ["owner", "==", userSession.user.owner?.$getID()],
                        ["raceGroupType", "==", props.modelValue.type?.$getID()],
                        ["kartType", "==", props.modelValue.defaultKartType?.$getID()],
                        [
                            "kartSpeeds",
                            "array-contains",
                            props.modelValue.defaultKartSpeed?.$getID(),
                        ],
                    ];
                });
            },
            orders() {
                return [["price", "desc"]];
            },
            limit: -1,
        },
    })
    defaultProduct?: ProductVariant;

    @Var(String)
    @Input("textarea")
    comment = "";

    @Var(Number)
    @Input("number", { required: false })
    maxParticipants?: number;

    @Var(Employee)
    updatedBy?: Employee;

    @Var(Order)
    order?: Order;

    @Var(Boolean)
    isWeb?: boolean;

    @Var(Invoice)
    invoice?: Invoice;

    /**
     * Get Number of drivers per races from group configuration
     * @param raceType RaceType
     * @returns number
     */
    private async getInitialNumberOfDriversPerRace(raceType: RaceType): Promise<number> {
        const numberOfRaces = await this.getNumberOfRacesPerRaceType(raceType);
        // Divide drivers fairly inside each races
        return Math.ceil(this.drivers.length / numberOfRaces);
    }

    /**
     * Get Number of races from group configuration
     * @param raceType RaceType
     * @returns number
     */
    private async getNumberOfRacesPerRaceType(raceType: RaceType): Promise<number> {
        if (this.maxParticipants === undefined && raceType.maxParticipants === undefined)
            return 1;

        let maxParticipants = this.maxParticipants;
        if (maxParticipants === undefined) {
            await raceType.$getMetadata().refresh();
            maxParticipants = raceType.maxParticipants;
        }
        return Math.ceil(this.drivers.length / maxParticipants);
    }

    async getNumberOfRaces(): Promise<number> {
        let nbRaces = 0;
        if (this.type !== undefined) {
            nbRaces += await this.type.raceTypes.reduce(async (acc, raceType) => {
                const prev = await acc;
                const current = await this.getNumberOfRacesPerRaceType(raceType);

                return prev + current;
            }, Promise.resolve(0));
        }
        return this.raceTypePerUser * nbRaces;
    }

    async getOrderedDrivers(
        group: number,
        raceType: RaceType,
    ): Promise<(RaceDriver | Driver)[]> {
        const drivers: RaceDriver[] = [];

        if (group < 0) return this.drivers;

        let isWaiting = true;
        const raceGroupID = this.$getID();
        if (raceGroupID === undefined) throw new Error("Group ID is undefined");

        this.races.forEach((race) => {
            if (
                race.status !== RaceStatus.waiting &&
                race.status !== RaceStatus.assigning
            )
                isWaiting = false;
            if (race.groups[raceGroupID] !== group) return;
            this.drivers.forEach((driver) => {
                const isExist = !!drivers.find((d) => d.driverID === driver.driverID);
                const raceDriver = race.drivers.find(
                    (d) => d.driverID === driver.driverID,
                );
                if (!isExist && raceDriver !== undefined) drivers.push(raceDriver);
            });
        });

        if (drivers.length === 0 || isWaiting) return this.drivers;

        await raceType.$getMetadata().refresh();
        await this.circuit?.$getMetadata().refresh();

        if (raceType.orderParticipantsType === OrderParticipantsType.previousOrder)
            return drivers;

        return drivers.sort((driverA, driverB) => {
            if (
                raceType.orderParticipantsType ===
                    OrderParticipantsType.previousRankings &&
                driverA.driverID &&
                driverB.driverID
            ) {
                return driverA.getTotalTime() - driverB.getTotalTime();
            } else if (
                raceType.orderParticipantsType ===
                    OrderParticipantsType.previousRankingsReverse &&
                driverA.driverID &&
                driverB.driverID
            ) {
                return driverB.getTotalTime() - driverA.getTotalTime();
            } else if (
                raceType.orderParticipantsType ===
                    OrderParticipantsType.previousBestTimes &&
                driverA.driverID &&
                driverB.driverID
            ) {
                return (
                    driverB.getBestLapTime(this.circuit?.removeFirstLap) -
                    driverA.getBestLapTime(this.circuit?.removeFirstLap)
                );
            } else if (
                raceType.orderParticipantsType === OrderParticipantsType.earnedMoney
            ) {
                return driverA.getEarnedMoney() - driverB.getEarnedMoney();
            }
            return 0;
        });
    }

    /**
     * Generate a Race from arguments and group configuration
     * @param drivers Driver[]
     * @param date moment
     * @param raceType RaceType
     * @returns Race
     */
    private generateDryRace(
        drivers: Driver[],
        date: moment,
        raceType: RaceType,
        circuit: Circuit,
        group: number,
    ): Race {
        const { user } = useUserSession();
        const race = newDoc(Race);
        const raceGroupID = this.$getID();

        if (raceGroupID === undefined) throw new Error("Group ID is undefined");

        // Generate race
        race.date = date;
        race.circuit = circuit;
        race.type = raceType;
        race.owner = user?.owner;
        race.createdBy = user;
        race.groups[raceGroupID] = group;
        race.circuit = circuit;

        // Generate race drivers
        race.drivers = drivers.map((raceGroupDriver) => {
            const driver = new RaceDriver();
            driver.customer = raceGroupDriver.customer;
            driver.uid = raceGroupDriver.uid;
            driver.nickName = raceGroupDriver.nickName;
            driver.driverTags = raceGroupDriver.driverTags;
            driver.kartType = raceGroupDriver.kartType;
            driver.kartSpeed = raceGroupDriver.kartSpeed;
            return driver;
        });

        return race;
    }

    driverRepartition(numberOfDrivers: number, maxPerRace: number): Array<number> {
        const numberOfRaces = Math.ceil(numberOfDrivers / maxPerRace);
        const minimumOfDrivers = Math.floor(numberOfDrivers / numberOfRaces);
        const numberOfRacesWithOneMoreDriver = numberOfDrivers % numberOfRaces;

        const driverPerRaces: number[] = [];

        for (let i = 0; i < numberOfRaces; i++) {
            driverPerRaces.push(minimumOfDrivers);
            if (i < numberOfRacesWithOneMoreDriver) {
                driverPerRaces[i]++;
            }
        }

        return driverPerRaces;
    }

    async generateDryRaces(): Promise<{ errors: Error[]; races: Race[] }> {
        const races: Race[] = [];
        const errors: Error[] = [];
        if (!this.type) {
            errors.push(new GenerationNoTypeError());
            return { errors, races };
        }
        if (this.drivers.length <= 0) {
            errors.push(new GenerationNoDriversError());
            return { errors, races };
        }

        const startDate = this.date.clone();
        await this.type.$getMetadata().refresh();
        for (let index = 0; index < this.raceTypePerUser; index++) {
            await Promise.all(
                this.type.raceTypes.map(async (raceType, raceTypeIndex) => {
                    const chunkSize = await this.getInitialNumberOfDriversPerRace(
                        raceType,
                    );
                    const driversPerRace = this.driverRepartition(
                        this.drivers.length,
                        chunkSize,
                    );

                    const sortedDrivers = (await this.getOrderedDrivers(
                        raceTypeIndex - 1,
                        raceType,
                    )) as Driver[];

                    let driversIndex = 0;
                    for (let i = 0; i < driversPerRace.length; i++) {
                        const drivers = sortedDrivers.slice(
                            driversIndex,
                            driversIndex + driversPerRace[i],
                        );
                        driversIndex += driversPerRace[i];
                        try {
                            const race = this.generateDryRace(
                                drivers,
                                startDate.clone(),
                                raceType,
                                this.circuit,
                                raceTypeIndex,
                            );
                            races.push(race);
                        } catch (err: any) {
                            if (
                                !errors.find(
                                    (error) => error.constructor === err.constructor,
                                )
                            ) {
                                errors.push(err);
                            }
                        }

                        startDate.add(10, "minutes");
                    }
                }),
            );
        }

        return { errors, races };
    }

    async generateRaces(forceOriginDate = false) {
        const { races: dryRaces } = await this.generateDryRaces();
        const raceGroupID = this.$getID();

        if (
            this.races !== undefined &&
            this.races.length > 0 &&
            raceGroupID !== undefined
        ) {
            await Promise.all(
                this.races.map((race) => {
                    return race.$getMetadata().refresh();
                }),
            );
            let newRaces: Race[] = [];
            let ended = false;
            for (let group = 0; !ended; group++) {
                // Fetch race from in same group
                const startIndexFrom = this.races.findIndex(
                    (race) => race.groups[raceGroupID] === group,
                );

                const lastIndexFrom =
                    this.races.findLastIndex(
                        (race) => race.groups[raceGroupID] === group,
                    ) + 1;

                let groupRacesFrom: Race[] = [];
                if (startIndexFrom < 0 || lastIndexFrom < 0) groupRacesFrom = [];
                else groupRacesFrom = this.races.slice(startIndexFrom, lastIndexFrom);

                // Fetch race to in same group
                const startIndexTo = dryRaces.findIndex(
                    (race) => race.groups[raceGroupID] === group,
                );
                const lastIndexTo =
                    dryRaces.findLastIndex((race) => race.groups[raceGroupID] === group) +
                    1;

                let groupRacesTo: Race[] = [];
                if (startIndexTo < 0 || lastIndexTo < 0) groupRacesTo = [];
                else groupRacesTo = dryRaces.slice(startIndexTo, lastIndexTo);

                // If there is races in two group, merge it
                if (groupRacesFrom.length > 0 || groupRacesTo.length > 0) {
                    groupRacesFrom.forEach((raceFrom, index) => {
                        if (
                            raceFrom.status === RaceStatus.assigning ||
                            raceFrom.status === RaceStatus.waiting
                        ) {
                            const raceTo = groupRacesTo[index];

                            raceFrom.drivers.forEach((driver) => {
                                // const driverTo = raceTo.drivers[index];
                                const driverTo = raceTo?.drivers.find(
                                    (d) => d.driverID === driver.driverID,
                                );

                                if (driverTo !== undefined) {
                                    driverTo.kartSpeed = driver.kartSpeed;
                                    driverTo.kartType = driver.kartType;
                                    driverTo.kart = driver.kart;
                                }
                            });
                            raceFrom.drivers = raceFrom.drivers.filter((driver) => {
                                return (
                                    this.drivers.findIndex(
                                        (d) => d.driverID === driver.driverID,
                                    ) < 0
                                );
                            });
                        }
                    });
                    const mergedGroupRaces = mergeByGroup(
                        groupRacesFrom,
                        groupRacesTo,
                        forceOriginDate,
                    );
                    mergedGroupRaces.forEach((mergedRace) => {
                        mergedRace.groups[raceGroupID] = group;
                    });
                    newRaces = newRaces.concat(mergedGroupRaces);
                } else ended = true;
            }
            this.races = newRaces;
        } else {
            this.races = dryRaces;
        }
    }

    canStartRace(race: Race): boolean {
        if (!this.type?.isOrderRaceBlocked) return true;

        const groupID = this.$getID();
        if (groupID === undefined) return false;
        return this.races.every((r) => {
            if (r.groups[groupID] >= race.groups[groupID]) return true;
            return r.status === RaceStatus.finished || r.status === RaceStatus.canceled;
        });
    }

    async cancel(): Promise<void> {
        this.status = Status.canceled;
        await this.races.reduce(async (acc, race) => {
            await acc;
            await race.$getMetadata().refresh();
            if (race.status === RaceStatus.finished) {
                return;
            }
            if (Object.keys(race.groups).length === 1) {
                await race.$delete();
                return;
            }
            this.drivers.forEach((driver) => {
                const index = race.drivers.findIndex(
                    (d) => d.driverID === driver.driverID,
                );
                if (index >= 0) race.drivers.splice(index, 1);
            });

            if (race.drivers.length <= 0) await race.$delete();
            else await race.$save();
        }, Promise.resolve());
        this.races.splice(0, this.races.length);
        await this.$save();

        await this.drivers.reduce(async (acc, driver) => {
            await acc;
            if (this.order?.status !== OrderStatus.Draft) {
                return;
            }
            if (driver.product !== undefined) this.order?.removeItem(driver.product, 1);
        }, Promise.resolve());

        await this.order?.$save();
    }
}

export class GenerationNoTypeError extends Error {
    constructor() {
        super(".noType");
    }

    getValues() {
        return {};
    }
}

export class GenerationNoDriversError extends Error {
    constructor() {
        super(".noDrivers");
    }

    getValues() {
        return {};
    }
}

export function mergeByRace(raceFrom: Race, raceTo: Race, forceOriginDate = false) {
    if (!forceOriginDate) raceTo.date = raceFrom.date.clone();

    let drivers: RaceDriver[] = EntityArray();
    drivers = drivers.concat(raceTo.drivers).concat(raceFrom.drivers);
    drivers = uniqueArrayFilter(drivers, (driver: RaceDriver) => {
        return (value: Driver) => {
            return value.driverID === driver.driverID;
        };
    });

    for (let index = 0; index < drivers.length; index++) {
        const driver = drivers[index];
        const driverFrom = raceFrom.drivers.find((d) => d.driverID === driver.driverID);
        const driverTo = raceTo.drivers.find((d) => d.driverID === driver.driverID);

        if (driverFrom !== undefined && driverTo !== undefined) {
            driver.kartSpeed = driverFrom.kartSpeed;
            driver.kartType = driverFrom.kartType;
            driver.kart = driverFrom.kart;
        } else if (driverFrom !== undefined) {
            driver.kartSpeed = driverFrom.kartSpeed;
            driver.kartType = driverFrom.kartType;
            driver.kart = driverFrom.kart;
        } else if (driverTo !== undefined) {
            driver.kartSpeed = driverTo.kartSpeed;
            driver.kartType = driverTo.kartType;
            driver.kart = driverTo.kart;
        }
    }
    raceFrom.drivers = drivers.sort((a, b) => {
        const indexA = raceTo.drivers.findIndex((d) => d.driverID === a.driverID);
        const indexB = raceTo.drivers.findIndex((d) => d.driverID === b.driverID);
        if (indexA === undefined) return 1;
        if (indexB === undefined) return -1;
        return indexB - indexA;
    });
    raceFrom.type = raceTo.type;
    raceFrom.date = raceTo.date;
    raceFrom.circuit = raceTo.circuit;
    raceFrom.groups = { ...raceTo.groups, ...raceFrom.groups };
}

export function mergeByGroup(
    racesFrom: Race[],
    racesTo: Race[],
    forceOriginDate = false,
) {
    const newRaces = [];
    for (
        let index = 0;
        racesFrom[index] !== undefined || racesTo[index] !== undefined;
        index++
    ) {
        if (
            racesFrom[index] !== undefined &&
            (racesFrom[index].status === RaceStatus.started ||
                racesFrom[index].status === RaceStatus.finished ||
                racesFrom[index].status === RaceStatus.canceled)
        ) {
            newRaces.push(racesFrom[index]);
        } else if (racesTo[index] !== undefined && racesFrom[index] !== undefined) {
            mergeByRace(racesFrom[index], racesTo[index], forceOriginDate);
            newRaces.push(racesFrom[index]);

            // if (racesFrom[index] !== undefined)
            //     // eslint-disable-next-line no-console
            //     racesFrom[index].$delete().catch(console.error);
        } else if (racesFrom[index] !== undefined) {
            // eslint-disable-next-line no-console
            racesFrom[index].$delete().catch(console.error);
        } else if (racesTo[index] !== undefined) {
            newRaces.push(racesTo[index]);
        }
    }
    return newRaces;
}
