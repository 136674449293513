<script lang="ts">
import { defineComponent, toRefs } from "vue";
import { useUserSession } from "/@src/stores/userSession";

export function modelEvents() {
    return {
        async onBeforeCreate(screen: any) {
            const { user } = useUserSession();
            if (!user) throw new Error("No owner selected");
            screen.owner = user.owner;
        },

        async onBeforeEdit(screen: any) {
            const { user } = useUserSession();
            if (!user) throw new Error("No owner selected");
            screen.owner = user.owner;
        },
    };
}

export default defineComponent({
    props: {
        form: {
            type: Object,
            required: true,
        },
    },
    setup(props: any) {
        const { form: device } = toRefs(props);

        return {
            device,
        };
    },
});
</script>

<template>
    <VFieldModel v-model="device" property="name"></VFieldModel>
    <VFieldModel v-model="device" property="kind"></VFieldModel>
</template>
