<script setup lang="ts">
import IconCourse from "../../assets/icons/icon_course.svg";
import IconKart from "../../assets/icons/icon-kart.svg";
import IconEventUs from "../../assets/icons/icon-event-us.svg";
import IconEventYour from "../../assets/icons/icon-event-your.svg";
import IconProductTechnology from "../../assets/icons/icon-product-technology.svg";
import IconDiscover from "../../assets/icons/icon-discover.svg";
import { useRouter } from "vue-router";
import { useOwner } from "../../stores/website/owner";
import { ref } from "vue";
const ownerManager = useOwner();
const router = useRouter();

const isOpen = ref(false);
</script>

<template>
    <TranslateNamespace path=".topbar">
        <VFlex id="topbar" flex-direction="row" justify-content="space-between">
            <!-- <img :src="Logo" alt="logo" /> -->
            <!-- <img src="/images/sidebar/T2M_logo_white.svg" alt="logo" class="mw-logo z-10" />
            <VFlex flex-direction="column">
                <RouterLink to="/enterprise" class="menu-link has-text-white has-text-weight-bold mb-4">
                    <img src="/images/sidebar/icons/icon_1.svg" alt="logo" class="pr-3"/>
                    <Translate>.enterprise</Translate>
                </RouterLink>
                <RouterLink to="/event-in-town">
                    <VIcon></VIcon>
                    <Translate>.eventInTown</Translate>
                </RouterLink>
                <RouterLink to="/product-and-technology">
                    <VIcon></VIcon>
                    <Translate>.productAndTechnology</Translate>
                </RouterLink>
                <RouterLink to="/shop">
                    <VIcon></VIcon>
                    <Translate>.shop</Translate>
                </RouterLink>
                <RouterLink to="/discover">
                    <VIcon></VIcon>
                    <Translate>.discover</Translate>
                </RouterLink>
                <RouterLink to="/brandLicense">
                    <VIcon></VIcon>
                    <Translate>.brandLicense</Translate>
                </RouterLink>
            </VFlex>
            <VFlex flex-direction="column">
                <RouterLink to="/account">
                    <VIcon></VIcon>
                    <Translate>.account</Translate>
                </RouterLink>
            </VFlex> -->
            <nav class="navbar w-100" role="navigation">
                <div class="navbar-brand px-4">
                    <a class="navbar-item">
                        <img
                            src="/images/sidebar/T2M_logo_white.svg"
                            alt="logo"
                            class="mh-logo" />
                    </a>

                    <a
                        role="button"
                        class="navbar-burger"
                        aria-label="menu"
                        :aria-expanded="isOpen"
                        data-bs-toggle="collapse"
                        data-bs-target="#mobileMenu"
                        aria-controls="mobileMenu"
                        :class="{ 'is-active': isOpen }"
                        tabindex="-1"
                        @keyPress="isOpen = !isOpen"
                        @click="isOpen = !isOpen">
                        <span aria-hidden="true" class="1"></span>
                        <span aria-hidden="true" class="2"></span>
                        <span aria-hidden="true" class="3"></span>
                    </a>
                </div>

                <div
                    id="mobileMenu"
                    class="navbar-menu collapse"
                    :class="{ 'is-active': isOpen }">
                    <VFlex flex-direction="column" class="px-5 pt-5">
                        <VFlex flex-direction="column" class="mb-5">
                            <VFlex
                                v-if="ownerManager.owner"
                                flex-direction="row"
                                row-gap="1rem"
                                column-gap="1rem"
                                align-items="flex-start">
                                <img
                                    :src="IconCourse"
                                    alt="piste"
                                    style="height: 30px"
                                    class="mt-3" />
                                <div class="is-size-6 is-weight-600">
                                    <!-- MA : the value must be dynamic here ! -->
                                    <span class="has-text-primary">{{
                                        ownerManager.owner?.address.city
                                    }}</span>
                                    <div>
                                        <RouterLink
                                            to="/owners"
                                            class="is-size-6 is-weight-400 has-text-white is-underline"
                                            @click="isOpen = false">
                                            <Translate path=".editTrack"></Translate>
                                        </RouterLink>
                                    </div>
                                </div>
                            </VFlex>
                        </VFlex>
                        <!-- -->
                        <ButtonPrimary
                            v-if="!ownerManager.owner"
                            color="info"
                            class="mb-5 is-align-self-flex-start w-100 btn-info-hover is-size-6"
                            style="font-family: mohol !important"
                            @click="
                                router.push('/owners');
                                isOpen = false;
                            ">
                            <!-- <VIcon icon="/images/sidebar/icons/icon_course.svg"></VIcon> -->
                            <span class="is-uppercase is-size-6 is-asap">
                                <Translate path=".choose"></Translate>
                            </span>
                        </ButtonPrimary>

                        <ButtonPrimary
                            v-if="ownerManager.owner && ownerManager.owner.bookableOnline"
                            color="primary"
                            class="is-align-self-flex-start mb-6 w-100 btn-primary-hover is-size-6"
                            style="font-family: mohol !important"
                            @click="
                                $router.push('/booking');
                                isOpen = false;
                            ">
                            <!-- <VIcon icon="/images/kart/book-button-1.png"></VIcon> -->
                            <span class="is-uppercase is-size-6 is-asap">
                                <Translate path=".book"></Translate>
                            </span>
                        </ButtonPrimary>
                        <RouterLink
                            to="/kart"
                            class="menu-link has-text-white has-text-weight-bold mb-5"
                            @click="isOpen = false">
                            <VFlex flex-direction="row" align-items="center">
                                <div class="mr-3">
                                    <img :src="IconKart" alt="logo" />
                                </div>
                                <div>
                                    <Translate>.kart</Translate>
                                </div>
                            </VFlex>
                        </RouterLink>
                        <RouterLink
                            to="/event-us"
                            class="menu-link has-text-white has-text-weight-bold mb-5"
                            @click="isOpen = false">
                            <VFlex flex-direction="row" align-items="center">
                                <div class="mr-3">
                                    <img :src="IconEventUs" alt="logo" />
                                </div>
                                <div>
                                    <Translate>.eventUs</Translate>
                                </div>
                            </VFlex>
                        </RouterLink>
                        <RouterLink
                            to="/event-your/enterprise"
                            class="menu-link has-text-white has-text-weight-bold mb-5"
                            @click="isOpen = false">
                            <VFlex flex-direction="row" align-items="center">
                                <div class="mr-3">
                                    <img :src="IconEventYour" alt="logo" />
                                </div>
                                <div>
                                    <Translate>.eventYour</Translate>
                                </div>
                            </VFlex>
                        </RouterLink>
                        <RouterLink
                            to="/product-technology/karting"
                            class="menu-link has-text-white has-text-weight-bold mb-5"
                            @click="isOpen = false">
                            <VFlex flex-direction="row" align-items="center">
                                <div class="mr-3">
                                    <img :src="IconProductTechnology" alt="logo" />
                                </div>
                                <div>
                                    <Translate>.productAndTechnology</Translate>
                                </div>
                            </VFlex>
                        </RouterLink>

                        <RouterLink
                            to="/discover/history"
                            class="menu-link has-text-white has-text-weight-bold"
                            @click="isOpen = false">
                            <VFlex flex-direction="row" align-items="center">
                                <div class="mr-3">
                                    <img :src="IconDiscover" alt="logo" />
                                </div>
                                <div>
                                    <Translate>.discover</Translate>
                                </div>
                            </VFlex>
                        </RouterLink>
                        <!-- <VButton to="/account">
                    <img src="" alt="logo" />
                    <Translate>.account</Translate>
                </VButton> -->
                        <RouterLink
                            to="/"
                            class="menu-link has-text-white has-text-weight-bold mt-6"
                            @click="isOpen = false">
                            <VFlex flex-direction="row" align-items="center">
                                <div class="mr-3">
                                    <img
                                        src="/images/sidebar/icons/icon_login.svg"
                                        alt="logo" />
                                </div>
                                <div>
                                    <Translate>.account</Translate>
                                </div>
                            </VFlex>
                        </RouterLink>
                        <!-- <VDropdown class="mt-3">
                            <template #button>
                                <VButton>Francais</VButton>
                            </template>
                            <template #content>
                                <VDropdownItem>Francais</VDropdownItem>
                            </template>
                        </VDropdown> -->
                    </VFlex>
                </div>
            </nav>
        </VFlex>
    </TranslateNamespace>
</template>

<style lang="scss" scoped>
@use "sass:color";

.w-100 {
    width: 100%;
}

@media screen and (min-width: 1024px) {
    #topbar {
        display: none;
    }
}

@media only screen and (max-width: 1024px) and (orientation: landscape) {
    .navbar-brand {
        justify-content: space-between;
    }
}

#topbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 10;
}

.navbar {
    background: url("/images/sidebar/elements/SB_bg_mobile.svg"), $dark;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: inset 0 0 0 1000px #000c11cc;
    width: 100%;
    height: 80px;

    .navbar-menu {
        background: $dark;
        height: 100vh;
    }

    .navbar-brand {
        height: 80px;

        .navbar-burger:hover {
            background-color: initial;
        }

        .navbar-burger span {
            height: 5px;
            width: 25px;
            border-radius: 10px;
            background-color: white;
        }

        .navbar-burger span:nth-child(1) {
            top: calc(50% - 16px);
        }

        .navbar-burger span:nth-child(2) {
            top: calc(50% - 6px);
            background-color: $primary;
            width: 20px;
        }

        .navbar-burger.is-active span:nth-child(1) {
            transform: translateY(10px) rotate(45deg);
        }

        .navbar-burger.is-active span:nth-child(3) {
            transform: translateY(-10px) rotate(-45deg);
            background-color: $primary;
        }
    }
}

.mh-logo {
    height: 50px;
    max-height: 100%;
}

.py-7 {
    padding: 5rem 0;
}

@media screen and (max-height: 730px) {
    .py-7 {
        padding: 3rem 0;
    }
}

@media screen and (max-width: 768px) {
    #sidebar {
        display: none;
    }
}

.menu-link {
    font-size: 1.3rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
        width: 35px;
    }

    &:hover {
        color: $primary !important;
    }
}

.mw-logo {
    max-width: 200px;
    z-index: 10;
}

.btn-info-hover {
    cursor: pointer;

    &:hover {
        background-color: $primary;
    }
}

.btn-primary-hover {
    &:hover {
        background-color: $info;
    }
}
</style>
