<script lang="ts" setup>
export interface MergeModalProps {
    entity: Object;
    collection: Object[];
    selected: Object;
}
const props = defineProps<MergeModalProps>();
const emit = defineEmits(["update:select"]);
function logEven(event: any) {
    const selected: HTMLCollection = event.srcElement.selectedOptions;
    if (selected.length > 0) {
        emit("update:select", selected[0]._value);
    }
}
</script>

<template>
    <VPlaceholderSection>
        <template #title>{{ props.entity.name }}</template>
    </VPlaceholderSection>
    <VSelect @change="logEven($event)">
        <VOption v-for="(opt, index) in props.collection" :key="index" :value="opt">
            {{ opt.name }}
        </VOption>
    </VSelect>
</template>
