<script lang="ts">
import { defineComponent, toRefs } from "vue";
import { useUserSession } from "/@src/stores/userSession";
import { Type as RaceType } from "/@src/models/race";
import * as yup from "yup";

export function modelEvents() {
    return {
        async onBeforeCreate(groupType: any) {
            const { user } = useUserSession();
            if (user === undefined || user === null) throw new Error("No owner selected");
            groupType.owner = user.owner;
        },

        async onBeforeEdit(groupType: any) {
            const { user } = useUserSession();
            if (user === undefined || user === null) throw new Error("No owner selected");
            groupType.owner = user.owner;
        },
    };
}

export default defineComponent({
    props: {
        form: {
            type: Object,
            required: true,
        },
    },
    setup(props: any) {
        const { form: groupType } = toRefs(props);
        yup.addMethod(yup.object, "requireNoUndefined", function requireNoUndefined() {
            return this.test({
                message: "validation.required",
                name: "requireNoUndefined",
                test(value) {
                    return value !== undefined;
                },
            });
        });
        let schema = yup.object();
        schema = schema.typeError("validation.required");
        schema = schema.requireNoUndefined();
        const { user } = useUserSession();
        return {
            groupType,
            schema,
            RaceType,
            user,
        };
    },
});
</script>

<template>
    <div>
        <VFieldModel v-model="groupType" property="name"></VFieldModel>
        <VFieldModel v-model="groupType" property="weight"></VFieldModel>
        <VFieldModel v-model="groupType" property="isOrderRaceBlocked"></VFieldModel>
        <VEntities
            v-slot="{ field }"
            v-model="groupType.raceTypes"
            property="raceTypes"
            :opened="true"
            :save="false"
            :schema="schema"
            required
            multiple
            sortable>
            <VField label=".label">
                <VControl :has-error="field.errors.length > 0">
                    <VSelectModel
                        v-model="field.value"
                        :model="RaceType"
                        required
                        :limit="-1"
                        :where="[['owner', '==', user.owner.$getID()]]"></VSelectModel>
                    <p v-for="error in field.errors" :key="error" class="help is-danger">
                        <Translate>.{{ error }}</Translate>
                    </p>
                </VControl>
            </VField>
        </VEntities>
    </div>
</template>
