<script lang="ts">
import { defineComponent, toRefs } from "vue";
import type { Wallet } from "/@src/models/product/wallet";
import { useUserSession } from "/@src/stores/userSession";

export function modelEvents() {
    return {
        async onBeforeCreate(wallet: Wallet) {
            const { user } = useUserSession();
            if (user === undefined) throw new Error("No owner selected");
            wallet.owner = user.owner;
            wallet.currentAmount = wallet.initialAmount;
        },
        async onBeforeEdit(wallet: Wallet) {
            const { user } = useUserSession();
            if (user === undefined) throw new Error("No owner selected");
            wallet.owner = user.owner;
        },
    };
}
export default defineComponent({
    props: {
        form: {
            type: Object,
            required: true,
        },
    },
    setup(props: any) {
        const { form: wallet } = toRefs(props);
        return {
            wallet,
        };
    },
});
</script>

<template>
    <TranslateNamespace path=".customer">
        <VValidation v-slot="{ field }" v-model="wallet.customer" property="customer">
            <FindCustomer v-model="field.value" required></FindCustomer>
        </VValidation>
    </TranslateNamespace>
    <VFieldModel
        v-if="wallet.$isNew()"
        v-model="wallet"
        property="initialAmount"></VFieldModel>
    <VFieldModel
        v-else
        :options="{ disabled: true }"
        v-model="wallet"
        property="currentAmount"></VFieldModel>
    <VFieldModel
        v-model="wallet"
        :options="{ disabled: true }"
        property="code"></VFieldModel>
</template>
