<script setup lang="ts">
import { useCurrencySymbol } from "addeus-common-library/stores/currency";
import { computed } from "vue";
import type { Owner } from "../../models/owner";

interface PriceViewProps {
    price: number;
    precision?: number;
    withCurrency: boolean;
    owner?: Owner;
}

const props = defineProps<PriceViewProps>();

function getPrecision(): number {
    if (props.precision === undefined || props.precision < 0) {
        return 0;
    }
    if (props.precision > 20) {
        return 20;
    }
    return props.precision;
}

const currencySymbol = useCurrencySymbol(
    computed(() => {
        return props.owner?.currency;
    }),
);

const spacedNumber = computed(() => {
    const precision: number = getPrecision();
    let num: number = props.price;
    let isNegativ = false;
    if (num === undefined || num === null) {
        return "";
    }
    if (num < 0) {
        isNegativ = true;
        num = -num;
    }
    const stringify = num.toFixed(precision);
    let nums: string[] = stringify.split(".");
    nums[0] = nums[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    if (nums.length > 1) {
        // Reverse the decimal
        nums[1] = nums[1].split("").reverse().join("");
        // Apply the regex to the reversed string
        nums[1] = nums[1].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        // Put the string in the right order
        nums[1] = nums[1].split("").reverse().join("");
    }
    let value = nums.join(".");
    value =
        (isNegativ ? "-" : "") + value + (props.withCurrency ? currencySymbol.value : "");
    return value;
});
</script>

<template>
    <span>
        {{ spacedNumber }}
    </span>
</template>
