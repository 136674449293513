<script lang="ts">
import moment from "moment-with-locales-es6";
import { defineComponent, toRefs } from "vue";
import { useTranslate } from "addeus-common-library/stores/translate";
import { StockType, type Stock } from "../../../models/product/stock";
import { useEmployeeSession } from "../../../stores/employeeSession";
import type { Ref } from "vue";
import { computed } from "vue";
import { QuantityType } from "../../../models/product";
import { getMeasureUnitFactor } from "../../../models/product/measureUnit";

export function modelEvents() {
    return {
        async onBeforeCreate(stock: Stock) {
            const { user } = useEmployeeSession();
            if (user === null) throw new Error("No owner selected");

            if (stock.createdBy !== undefined) return;

            stock.owner = user.owner;
            stock.createdAt = moment();
            stock.createdBy = user;
            stock.productQuantityBefore = stock.product!.availability;
            if (
                stock.product!.quantityType !== QuantityType.NoQuantity &&
                stock.product!.quantityType !== QuantityType.Service &&
                stock.product!.quantityType !== QuantityType.Unitary &&
                stock.product!.measureUnit !== undefined
            ) {
                stock.quantity *= getMeasureUnitFactor(stock.product!.measureUnit);
            }
            stock.product!.availability += stock.deltaQuantity;
            await stock.product?.$save();
        },
    };
}

export default defineComponent({
    props: {
        form: {
            type: Object,
            required: true,
        },
    },
    setup(props: any) {
        const { translate } = useTranslate();
        const stock: Ref<Stock> = toRefs(props).form;
        const quantityLabel = computed(() => {
            if (stock.value.product?.measureUnit === undefined) {
                return translate(".quantity.label").value;
            }
            return `${translate(".quantity.label").value} (${
                stock.value.product.measureUnit
            })`;
        });
        return {
            stock,
            translate,
            quantityLabel,
            StockType,
        };
    },
});
</script>

<template>
    <VFlex flex-direction="column">
        <VFieldModel
            v-model="stock"
            property="type"
            :options="{ openDirection: 'bottom' }"></VFieldModel>
        <VFieldModel
            v-model="stock"
            property="quantity"
            :label="quantityLabel"></VFieldModel>
        <VFieldModel
            v-if="stock.type === StockType.Replenishment"
            v-model="stock"
            property="buyPrice"></VFieldModel>
    </VFlex>
</template>
