import { toRaw } from "vue";
import type { Database, QueryConstraint, Unsubscribe } from "firebase/database";
import { endAt, startAt } from "firebase/database";
import { orderByChild } from "firebase/database";
import { limitToLast, query } from "firebase/database";
import { push, ref, onValue } from "firebase/database";
import { useFirebase } from "addeus-common-library/stores/firebase";
import type moment from "moment-with-locales-es6";

const LOGS_NODE_NAME = "logs";
const LIMIT = 30;

export enum LogType {
    DocumentUpdated = "documentUpdated",
    DocumentCreated = "documentCreated",
    DocumentDeleted = "documentDeleted",
    UserConnected = "userConnected",
    UserChangeAccount = "userChangeAccount",
    KartAssign = "kartAssign",
    KartUnassign = "kartUnassign",
    KartPassesUnderCheckpoint = "kartPassesUnderCheckpoint",
    RaceStart = "RaceStart",
    RaceEnd = "RaceEnd",
    RaceCancel = "RaceCancel",
    UnknownDriverRemoved = "UnknownDriverRemoved",
    RaceGroupCanceled = "RaceGroupCanceled",
    KartSpeedChanged = "KartSpeedChanged",
}

interface LogsOptions {
    start?: moment;
    end?: moment;
    limit?: number;
}

export const useLogger = (database?: Database) => {
    const getDatabase = (): Database => {
        return database === undefined ? useFirebase().database : database;
    };

    const registerOnLogs = (
        ownerId: string,
        callback: (logs: any) => void,
        options?: LogsOptions,
    ): Unsubscribe => {
        const databaseRef = ref(toRaw(getDatabase()), `${LOGS_NODE_NAME}/${ownerId}/`);
        const constraints: QueryConstraint[] = [orderByChild("timestamp")];

        if (options?.limit === undefined) {
            constraints.push(limitToLast(LIMIT));
        } else if (options?.limit !== -1) {
            constraints.push(limitToLast(options.limit));
        }
        if (options?.start !== undefined) {
            constraints.push(startAt(options.start.valueOf()));
        }
        if (options?.end !== undefined) {
            constraints.push(endAt(options.end.valueOf()));
        }
        const logQuery = query(databaseRef, ...constraints);
        return onValue(
            logQuery,
            (snapshot) => {
                callback(snapshot.val());
            },
            (error) => {
                // eslint-disable-next-line no-console
                console.error(error);
            },
        );
    };

    const logToRealTimeDatabase = async (
        logType: LogType,
        userId: string | undefined,
        ownerId: string | undefined,
        data: any,
    ) => {
        const databaseRef = ref(toRaw(getDatabase()), `${LOGS_NODE_NAME}/${ownerId}/`);
        await push(databaseRef, {
            timestamp: Date.now(),
            userId: userId === undefined ? null : userId,
            data,
            logType,
        });
    };

    const logOnRaceGroupCanceled = async (
        userId: string,
        ownerId: string,
        document: any,
    ): Promise<void> => {
        await logToRealTimeDatabase(LogType.RaceGroupCanceled, userId, ownerId, {
            document,
        });
    };

    const logOnDocumentCreated = async (
        userId: string,
        ownerId: string,
        document: any,
    ): Promise<void> => {
        await logToRealTimeDatabase(LogType.DocumentCreated, userId, ownerId, {
            document,
        });
    };

    const documentUpdatedDictionary = new Map<string, string[]>();
    const beforeDocumentUpdated = async (
        documentPropsChange: any,
        documentId: string,
    ) => {
        documentUpdatedDictionary.set(documentId, documentPropsChange);
    };

    const logOnDocumentUpdated = async (
        userId: string,
        ownerId: string,
        documentAfter: any,
        documentBefore: any,
    ): Promise<void> => {
        const documentChange: any = { docName: documentBefore.docName };
        const propsChanged = documentUpdatedDictionary.get(documentAfter.uid as string);
        if (propsChanged !== undefined) {
            propsChanged.forEach((propName: string) => {
                documentChange[propName] = documentAfter[propName];
            });
        }
        await logToRealTimeDatabase(LogType.DocumentUpdated, userId, ownerId, {
            documentBefore,
            documentChange,
        });
    };

    const logOnDocumentDeleted = async (
        userId: string,
        ownerId: string,
        document: any,
    ): Promise<void> => {
        await logToRealTimeDatabase(LogType.DocumentDeleted, userId, ownerId, {
            document,
        });
    };

    const logOnKartSpeedChanged = async (
        userId: string,
        ownerId: string,
        race: any,
        driver: any,
        oldSpeed: any,
        newSpeed: any,
    ) => {
        const payload = {
            race,
            driver,
            oldSpeed,
            newSpeed,
        };
        await logToRealTimeDatabase(LogType.KartSpeedChanged, userId, ownerId, payload);
    };

    const logOnUserConnected = async (user: any, ownerId: string): Promise<void> => {
        await logToRealTimeDatabase(LogType.UserConnected, user.uid, ownerId, { user });
    };

    const logOnUserChangeAccountWithPIN = async (
        precedentUser: any,
        newUser: any,
        ownerId: string,
    ): Promise<void> => {
        await logToRealTimeDatabase(LogType.UserChangeAccount, newUser.uid, ownerId, {
            precedentUser,
            newUser,
        });
    };

    const logOnKartAssign = async (
        userId: string,
        ownerId: string,
        kart: any,
        driver: any,
    ): Promise<void> => {
        await logToRealTimeDatabase(LogType.KartAssign, userId, ownerId, {
            kart,
            driver,
        });
    };

    const logOnKartUnassign = async (
        userId: string,
        ownerId: string,
        driver: any,
    ): Promise<void> => {
        await logToRealTimeDatabase(LogType.KartUnassign, userId, ownerId, {
            driver,
        });
    };

    const logOnKartPassesUnderCheckpoint = async (
        userId: string | undefined,
        ownerId: string | undefined,
        gate: any,
        kart: any,
    ): Promise<void> => {
        await logToRealTimeDatabase(LogType.KartPassesUnderCheckpoint, userId, ownerId, {
            gate,
            kart,
        });
    };

    const logOnRaceStart = async (
        user: any,
        ownerId: string,
        race: any,
    ): Promise<void> => {
        await logToRealTimeDatabase(LogType.RaceStart, user, ownerId, {
            race,
        });
    };

    const logOnRaceEnd = async (
        userId: string | undefined,
        ownerId: string,
        race: any,
    ): Promise<void> => {
        await logToRealTimeDatabase(LogType.RaceEnd, userId, ownerId, {
            race,
        });
    };

    const logOnRaceCancel = async (
        user: any,
        ownerId: string,
        race: any,
    ): Promise<void> => {
        await logToRealTimeDatabase(LogType.RaceCancel, user, ownerId, {
            race,
        });
    };

    const logOnUnknownDriverRemoved = async (userId: any, ownerId: string, race: any) => {
        await logToRealTimeDatabase(LogType.UnknownDriverRemoved, userId, ownerId, {
            race,
        });
    };

    return {
        logOnDocumentCreated,
        logOnDocumentUpdated,
        beforeDocumentUpdated,
        logOnDocumentDeleted,
        logOnUserChangeAccountWithPIN,
        logOnUserConnected,
        logOnKartAssign,
        logOnKartUnassign,
        logOnKartSpeedChanged,
        logOnKartPassesUnderCheckpoint,
        logOnRaceGroupCanceled,
        logOnRaceStart,
        logOnRaceEnd,
        logOnRaceCancel,
        registerOnLogs,
        logOnUnknownDriverRemoved,
    };
};
