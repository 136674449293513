import { Entity, Input, Var } from "addeus-common-library/stores/firestore";
import { Owner } from "../owner";

export enum Status {
    online = "online",
    inuse = "inuse",
    offline = "offline",
    issuing = "issuing",
}

export enum Kind {
    trafficSignal = "trafficSignal",
    gate = "gate",
    kart = "kart",
    projector = "projector",
}

export class Device extends Entity {
    static collectionName = "devices";

    @Var(String)
    @Input("text", { required: true })
    name: string = "";

    @Var(Kind)
    @Input("select", { required: true, options: Kind })
    kind: Kind = Kind.gate;

    @Var(Number)
    number: number = 0;

    @Var(String)
    reference?: string;

    @Var(String)
    version?: string;

    @Var(Status)
    status?: Status;

    @Var(Owner)
    owner?: Owner;

    toString() {
        return `${this.reference} (${this.kind}/${
            this.version !== undefined ? this.version : ""
        })`;
    }
}
