import { Owner } from "../owner";
import { Entity, Var, Input } from "addeus-common-library/stores/firestore";

export class Tax extends Entity {
    static collectionName = "taxes";

    @Var(String)
    @Input("text", { required: true })
    name?: string;

    @Var(Number)
    @Input("percent", { required: true })
    tax?: number;

    @Var(Owner)
    owner?: Owner;

    $getModelName() {
        return "tax";
    }

    toString() {
        return this.name;
    }
}
