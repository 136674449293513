<script lang="ts">
import { computed, defineComponent, inject, toRefs, ref } from "vue";
import type { Ref } from "vue";
import { watch } from "vue";
import * as yup from "yup";
import { newDoc, useDoc } from "addeus-common-library/stores/firestore";
import { useTranslate } from "addeus-common-library/stores/translate";
import type { Product } from "../../../models/product";
import {
    ProductVariant,
    ProductVariantComposition,
    QuantityType,
    StockAlert,
} from "../../../models/product";
import { useBarcode } from "../../../stores/barcode";
import {
    ProductDistributionChannel,
    ProductSalesChannel,
} from "../../../models/product/channel";
import { useEmployeeSession } from "../../../stores/employeeSession";
import { MeasureUnit } from "../../../models/product/measureUnit";

export function modelEvents() {
    return {
        async onBeforeCreate(product: Product) {
            const { user } = useEmployeeSession();
            if (user === null) throw new Error("No owner selected");
            product.owner = user.owner;
            await product.mainVariant?.$save();
        },
        async onCreate(product: Product) {
            product.mainVariant!.productId = product.$getID();
            product.mainVariant!.owner = product.owner;
            await Promise.all([
                ...product.variants.map((variant) => {
                    variant.productId = product.$getID();
                    variant.owner = product.owner;
                    return variant.$save();
                }),
                product.mainVariant?.$save(),
            ]);
        },
        async onBeforeEdit(product: Product) {
            const { user } = useEmployeeSession();
            if (user === null) throw new Error("No owner selected");
            product.owner = user.owner;
            await product.mainVariant?.$save();
        },
        async onEdit(product: Product) {
            product.mainVariant!.productId = product.$getID();
            product.mainVariant!.owner = product.owner;
            await Promise.all([
                ...product.variants.map((variant) => {
                    variant.productId = product.$getID();
                    variant.category = product.category;
                    variant.owner = product.owner;
                    return variant.$save();
                }),
                product.mainVariant?.$save(),
            ]);
        },
    };
}
export default defineComponent({
    props: {
        form: {
            type: Object,
            required: true,
        },
    },
    setup(props: any) {
        const { translate } = useTranslate();
        const schemaObjectRequired = yup.object();
        const schemaStringRequired = yup.string().required("validation.required");
        const product: Ref<Product> = toRefs(props).form;
        if (product.value.mainVariant === undefined) {
            product.value.mainVariant = newDoc(ProductVariant);
            product.value.mainVariant.salesChannels.push(new ProductSalesChannel());
            product.value.mainVariant.distributionChannels.push(
                new ProductDistributionChannel(),
            );
        }

        const newVariant = () => {
            const mainVariant: ProductVariant = product.value.mainVariant!;
            const variant = newDoc(ProductVariant);
            variant.canBeSold = mainVariant.canBeSold;
            variant.canBeBought = mainVariant.canBeBought;
            variant.price = mainVariant.price;
            variant.category = mainVariant.category;
            variant.quantityType = mainVariant.quantityType;
            variant.measureUnit = mainVariant.measureUnit;
            variant.comment = mainVariant.comment;
            variant.barcode = mainVariant.barcode;
            variant.compositions = mainVariant.compositions.map((composition) => {
                const newCompo = new ProductVariantComposition();
                newCompo.quantity = composition.quantity;
                newCompo.productVariantId = composition.productVariantId;
                return newCompo;
            });
            variant.distributionChannels = mainVariant.distributionChannels.map(
                (distribChannel) => {
                    const newChannel = new ProductDistributionChannel();
                    newChannel.channel = distribChannel.channel;
                    newChannel.additionnalPrice = distribChannel.additionnalPrice;
                    return newChannel;
                },
            );
            variant.salesChannels = mainVariant.salesChannels.map((salesChannel) => {
                const newChannel = new ProductSalesChannel();
                newChannel.channel = salesChannel.channel;
                newChannel.tax = salesChannel.tax;
                newChannel.additionnalPrice = salesChannel.additionnalPrice;
                return newChannel;
            });
            variant.quantityAlert = mainVariant.quantityAlert;

            variant.$edit();
            return variant;
        };

        const totalPrice = computed({
            get() {
                if (product.value?.mainVariant?.salesChannels[0]?.tax?.tax === undefined)
                    return 0;
                return (
                    Math.round(
                        (product.value?.mainVariant.price +
                            product.value?.mainVariant.price *
                                product.value?.mainVariant.salesChannels[0].tax.tax) *
                            100,
                    ) / 100
                );
            },
            set(value: number) {
                if (!product.value.mainVariant) return;
                product.value.mainVariant.price =
                    value / (1 + product.value.mainVariant.salesChannels[0]?.tax?.tax);
            },
        });
        const totalPriceSchema = yup
            .number()
            .required("validation.required")
            .transform((value) => (isNaN(value) ? undefined : value));

        const isEdit = inject<Ref<boolean>>("isEdit");

        const hasComposition = computed(
            () => product.value.mainVariant?.quantityType === QuantityType.NoQuantity,
        );

        const hasAlert = computed(
            () =>
                product.value.mainVariant?.quantityType !== QuantityType.NoQuantity &&
                product.value.mainVariant?.quantityType !== QuantityType.Service,
        );

        const steps = computed(() => {
            const newSteps = [
                translate(".wizard.product").value,
                translate(".wizard.variants").value,
            ];
            if (hasComposition.value)
                newSteps.push(translate(".wizard.compositions").value);

            if (hasAlert.value) newSteps.push(translate(".wizard.alerts").value);
            return newSteps;
        });

        const lastStepName = computed(() => `step-${hasComposition.value ? "4" : "3"}`);

        useBarcode((barcode) => {
            product.value.mainVariant!.barcode = barcode;
        });

        const measureSchema = yup.string().nullable().required("validation.required");

        const getMeasureOption = (measureUnit: MeasureUnit) => {
            return {
                label: measureUnit,
                value: measureUnit,
            };
        };

        const measureUnitOptions = computed(() => {
            if (product.value.mainVariant?.quantityType === QuantityType.Volume) {
                return [
                    getMeasureOption(MeasureUnit.L),
                    getMeasureOption(MeasureUnit.cL),
                    getMeasureOption(MeasureUnit.mL),
                ];
            } else if (product.value.mainVariant?.quantityType === QuantityType.Weight) {
                return [
                    getMeasureOption(MeasureUnit.Kg),
                    getMeasureOption(MeasureUnit.g),
                ];
            }
            return [];
        });

        watch(
            () => product.value.mainVariant?.quantityType,
            () => {
                const availableMeasureUnits = measureUnitOptions.value.map(
                    (option) => option.value,
                );
                if (
                    product.value.mainVariant?.measureUnit !== undefined &&
                    !availableMeasureUnits.includes(product.value.mainVariant.measureUnit)
                ) {
                    product.value.mainVariant.measureUnit = undefined;
                }
            },
        );

        const compositionQuantityLabels = ref(new Map<string, string>());
        const getQuantityLabel = async (productVariantId: string) => {
            const variant = useDoc(ProductVariant, productVariantId);
            await variant.$getMetadata().isFullfilling;
            if (variant?.measureUnit === undefined) {
                return translate(".compositions.quantity.label").value;
            }
            return `${translate(".compositions.quantity.label").value} (${
                variant.measureUnit
            })`;
        };
        const updateCompositionQuantityLabel = async () => {
            if (product.value.mainVariant?.compositions === undefined) return;
            const promises = product.value.mainVariant.compositions.map(
                async (composition): Promise<void> => {
                    if (
                        composition.productVariantId !== undefined &&
                        !compositionQuantityLabels.value.has(composition.productVariantId)
                    ) {
                        compositionQuantityLabels.value.set(
                            composition.productVariantId,
                            await getQuantityLabel(composition.productVariantId),
                        );
                    }
                },
            );
            await Promise.all(promises);
        };
        void updateCompositionQuantityLabel();
        watch(
            () =>
                product.value.mainVariant?.compositions
                    .map((composition) => composition.productVariantId)
                    .filter((id, index, array) => array.indexOf(id) === index),
            updateCompositionQuantityLabel,
        );

        const alerteLabel = computed(() => {
            if (product.value.mainVariant?.measureUnit === undefined) {
                return translate(".alerts.label").value;
            }
            return `${translate(".alerts.label").value} (${
                product.value.mainVariant.measureUnit
            })`;
        });

        return {
            isEdit,
            schemaObjectRequired,
            schemaStringRequired,
            product,
            ProductSalesChannel,
            ProductDistributionChannel,
            ProductVariant,
            ProductVariantComposition,
            QuantityType,
            StockAlert,
            hasComposition,
            totalPrice,
            totalPriceSchema,
            steps,
            lastStepName,
            translate,
            newVariant,
            measureUnitOptions,
            measureSchema,
            compositionQuantityLabels,
            alerteLabel,
        };
    },
});
</script>

<template>
    <VFlex>
        <VFlexItem flex-grow="1" :flex-shrink="1">
            <VMultipage
                v-model="product"
                :mode="isEdit ? 'tabs' : 'wizard'"
                :hide-steps="false"
                :hide-actions="false"
                :steps="steps">
                <template #step-1="{ field: productField }">
                    <VFieldModel
                        v-model="productField.value.mainVariant"
                        property="name"></VFieldModel>

                    <VFieldModel
                        v-model="productField.value"
                        property="weight"></VFieldModel>

                    <TranslateNamespace path=".category">
                        <VLabel><Translate>.label</Translate></VLabel>
                        <VValidation
                            v-slot="{ field }"
                            v-model="productField.value.category"
                            property="category"
                            :schema="schemaObjectRequired">
                            <VControl :has-error="field.errors.length > 0">
                                <FindProductCategory
                                    v-model="field.value"
                                    :edit="false"
                                    required></FindProductCategory>
                                <p
                                    v-for="error in field.errors"
                                    :key="error"
                                    class="help is-danger">
                                    <Translate>.{{ error }}</Translate>
                                </p>
                            </VControl>
                        </VValidation>
                    </TranslateNamespace>

                    <VFieldModel
                        v-model="productField.value.mainVariant"
                        property="picture"></VFieldModel>

                    <VEntities
                        v-slot="{ field }"
                        v-model="productField.value.mainVariant.salesChannels"
                        :sortable="false"
                        :only-ids="false"
                        :schema="schemaObjectRequired"
                        :model="ProductSalesChannel"
                        :opened="productField.value.mainVariant.$isNew()"
                        property="salesChannels"
                        multiple
                        required>
                        <VFieldModel
                            v-model="field.value"
                            property="channel"
                            required></VFieldModel>
                        <VFieldModel
                            v-model="field.value"
                            property="additionnalPrice"
                            required></VFieldModel>
                        <VFieldModel
                            v-model="field.value"
                            property="tax"
                            required></VFieldModel>
                    </VEntities>

                    <VEntities
                        v-slot="{ field }"
                        v-model="productField.value.mainVariant.distributionChannels"
                        :sortable="false"
                        :only-ids="false"
                        :schema="schemaObjectRequired"
                        :model="ProductDistributionChannel"
                        :opened="productField.value.mainVariant.$isNew()"
                        property="distributionChannels"
                        multiple
                        required>
                        <VFieldModel
                            v-model="field.value"
                            property="channel"
                            required></VFieldModel>
                        <VFieldModel
                            v-model="field.value"
                            property="additionnalPrice"
                            required></VFieldModel>
                    </VEntities>

                    <VFieldModel
                        v-model="productField.value.mainVariant"
                        property="canBeSold"></VFieldModel>
                    <VFieldModel
                        v-model="productField.value.mainVariant"
                        property="canBeBought"></VFieldModel>
                    <VFieldModel
                        v-model="productField.value.mainVariant"
                        property="quantityType"></VFieldModel>
                    <TranslateNamespace path=".measureUnit">
                        <VValidation
                            v-if="
                                productField.value.mainVariant.quantityType !==
                                    QuantityType.NoQuantity &&
                                productField.value.mainVariant.quantityType !==
                                    QuantityType.Service &&
                                productField.value.mainVariant.quantityType !==
                                    QuantityType.Unitary
                            "
                            v-slot="{ field: measureUnitField }"
                            v-model="productField.value.mainVariant.measureUnit"
                            property="measureUnit"
                            :schema="measureSchema">
                            <VField v-slot="{ id }" label=".label">
                                <VControl :has-error="measureUnitField.errors.length > 0">
                                    <Multiselect
                                        :id="id"
                                        ref="multiselect"
                                        v-model="measureUnitField.value"
                                        name="measureUnit"
                                        :required="true"
                                        :mode="'single'"
                                        :options="measureUnitOptions"
                                        :native-support="true"
                                        open-direction="top">
                                    </Multiselect>
                                    <p
                                        v-for="error in measureUnitField.errors"
                                        :key="error"
                                        class="help is-danger">
                                        <Translate>.{{ error }}</Translate>
                                    </p>
                                </VControl>
                            </VField>
                        </VValidation>
                    </TranslateNamespace>
                    <VFlex flex-direction="column">
                        <VFieldModel
                            v-if="
                                QuantityType.Service ===
                                productField.value.mainVariant.quantityType
                            "
                            v-model="productField.value.mainVariant"
                            property="raceGroupType"></VFieldModel>
                        <VFieldModel
                            v-if="
                                QuantityType.Service ===
                                productField.value.mainVariant.quantityType
                            "
                            v-model="productField.value.mainVariant"
                            property="kartType"></VFieldModel>
                        <VFieldModel
                            v-if="
                                QuantityType.Service ===
                                productField.value.mainVariant.quantityType
                            "
                            v-model="productField.value.mainVariant"
                            property="kartSpeeds"></VFieldModel>
                        <VFieldModel
                            v-if="
                                QuantityType.Service ===
                                productField.value.mainVariant.quantityType
                            "
                            v-model="productField.value.mainVariant"
                            property="giftCard"></VFieldModel>
                    </VFlex>

                    <VValidation
                        v-slot="{ field }"
                        ref="validation"
                        v-model="totalPrice"
                        property="price"
                        :schema="totalPriceSchema">
                        <VField label=".price.label">
                            <VControl :has-error="field.errors.length > 0">
                                <VInput
                                    v-model="field.value"
                                    type="number"
                                    :required="true"
                                    class="input" />
                            </VControl>
                        </VField>
                    </VValidation>
                    <VFieldModel
                        v-if="
                            productField.value.mainVariant.quantityType !==
                                QuantityType.NoQuantity &&
                            productField.value.mainVariant.quantityType !==
                                QuantityType.Service
                        "
                        v-model="productField.value.mainVariant"
                        property="barcode"></VFieldModel>
                    <VFieldModel
                        v-model="productField.value.mainVariant"
                        property="comment"></VFieldModel>
                    <VFieldModel
                        v-model="productField.value"
                        property="favorite"></VFieldModel>
                </template>
                <template #step-2="{ field: productField }">
                    <VEntities
                        v-slot="{ field }"
                        v-model="productField.value.variants"
                        :sortable="false"
                        :only-ids="false"
                        :schema="schemaObjectRequired"
                        :model="ProductVariant"
                        :opened="true"
                        property="variants"
                        multiple
                        :can-open="false"
                        :required="false"
                        :default-new-value="newVariant">
                        <VFlex align-items="end">
                            <VFlexItem flex-grow="1" class="p-0 m-1">
                                <VLabel>{{ field.value.name }}</VLabel>
                            </VFlexItem>
                            <VButton
                                class="p-0 m-1 edit-variant-btn"
                                icon="arrow_outward"
                                @click="() => field.value.$edit()"></VButton
                        ></VFlex>
                    </VEntities>
                </template>
                <template v-if="hasComposition" #step-3="{ field: productField }">
                    <VEntities
                        v-slot="{ field: compositionField }"
                        v-model="productField.value.mainVariant.compositions"
                        :sortable="false"
                        :only-ids="false"
                        :schema="schemaObjectRequired"
                        :model="ProductVariantComposition"
                        :opened="false"
                        property="compositions"
                        multiple
                        :required="false">
                        <TranslateNamespace path=".variant">
                            <VLabel><Translate>.label</Translate></VLabel>
                            <VValidation
                                v-slot="{ field }"
                                v-model="compositionField.value.productVariantId"
                                property="productVariantId"
                                :schema="schemaStringRequired">
                                <VControl :has-error="field.errors.length > 0">
                                    <FindProductVariant
                                        v-model="field.value"
                                        :ignore="product.mainVariant"
                                        required></FindProductVariant>
                                    <p
                                        v-for="error in field.errors"
                                        :key="error"
                                        class="help is-danger">
                                        <Translate>.{{ error }}</Translate>
                                    </p>
                                </VControl>
                            </VValidation>
                        </TranslateNamespace>
                        <VFieldModel
                            v-model="compositionField.value"
                            property="quantity"
                            :label="
                                compositionQuantityLabels.get(
                                    compositionField.value.productVariantId,
                                )
                            "
                            required></VFieldModel>
                    </VEntities>
                </template>
                <template #[lastStepName]="{ field: productField }">
                    <VFieldModel
                        v-model="productField.value.mainVariant"
                        :label="alerteLabel"
                        property="quantityAlert"></VFieldModel>
                </template>
            </VMultipage>
        </VFlexItem>
        <PreviewProductVariant
            :product-variant="product.mainVariant!"></PreviewProductVariant>
    </VFlex>
</template>

<style lang="scss">
.edit-variant-btn > i {
    padding: 0 !important;
    margin: 0 !important;
}
</style>
