import { useDebounceFn } from "@vueuse/core";
import { onUnmounted, onMounted } from "vue";

type BarcodeStopHandle = () => void;
const MAX_DELAY = 10;

export const useBarcode = (
    callback: (bacode: string) => void | Promise<void>
): BarcodeStopHandle => {
    let currentBarcode: string = "";
    const resetBarcodeDebounce = useDebounceFn(() => (currentBarcode = ""), MAX_DELAY);
    const keyDown = (e: KeyboardEvent) => {
        const isNumber = /^[0-9]$/i.test(e.key);
        if (isNumber) {
            currentBarcode += e.key;
        } else {
            if (
                e.key === "Enter" &&
                (currentBarcode.length === 8 || currentBarcode.length === 13)
            ) {
                void callback(currentBarcode);
            }
            currentBarcode = "";
        }
        void resetBarcodeDebounce();
    };
    const start = () => window.addEventListener("keydown", keyDown);
    const stop = () => window.removeEventListener("keydown", keyDown);
    onMounted(start);
    onUnmounted(stop);
    return stop;
};
