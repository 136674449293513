import type { Driver } from "../models/race";
import { RankingType } from "../models/race";

function isUnknownDriver(driver: Driver) {
    return driver.driverID === undefined;
}

function sortFirstArrived(drivers: Driver[]) {
    return drivers.slice(0).sort((a, b) => {
        if (isUnknownDriver(a)) return 1;
        else if (isUnknownDriver(b)) return -1;
        if (a.laps.length === b.laps.length) {
            return a.getTotalTime() - b.getTotalTime();
        } else {
            return b.laps.length - a.laps.length;
        }
    });
}

function sortBestTime(drivers: Driver[], removeFirstLap: boolean = true) {
    return drivers.slice(0).sort((a, b) => {
        if (isUnknownDriver(a)) {
            return 1;
        } else if (isUnknownDriver(b)) {
            return -1;
        }
        return a.getBestLapTime(removeFirstLap) - b.getBestLapTime(removeFirstLap);
    });
}

function sortMoney(drivers: Driver[]) {
    return drivers.slice(0).sort((a, b) => {
        if (isUnknownDriver(a)) return 1;
        else if (isUnknownDriver(b)) return -1;
        return b.getEarnedMoney() - a.getEarnedMoney();
    });
}

function sortDrivers(
    type: RankingType,
    drivers: Driver[],
    removeFirstLap: boolean = true,
): Driver[] {
    let driversSorted: Driver[] = [];
    switch (type) {
        case RankingType.bestTime:
            driversSorted = sortBestTime(drivers, removeFirstLap);
            break;
        case RankingType.firstArrived:
            driversSorted = sortFirstArrived(drivers);
            break;
        case RankingType.earnedMoney:
            driversSorted = sortMoney(drivers);
            break;
        default:
            driversSorted = drivers;
            break;
    }
    return driversSorted;
}

export { sortDrivers };
