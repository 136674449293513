export enum MeasureUnit {
    Kg = "Kg",
    g = "g",
    L = "L",
    mL = "mL",
    cL = "cL",
}

export const getMeasureUnitFactor = (measureUnit: MeasureUnit) => {
    switch (measureUnit) {
        case MeasureUnit.Kg:
            return 1000;
        case MeasureUnit.g:
            return 1;
        case MeasureUnit.L:
            return 1;
        case MeasureUnit.mL:
            return 0.001;
        case MeasureUnit.cL:
            return 0.01;
    }
};
