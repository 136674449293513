<script setup lang="ts">
import { computed, ref } from "vue";
import { useVModel } from "@vueuse/core";
import ProductCategoryComponent from "./models/product/productCategory.vue";
import { ProductCategory } from "../models/product";
import { useDoc } from "addeus-common-library/stores/firestore";
import { useRootProductCategories } from "../stores/productCategories";

export interface FindProductCategoryProps {
    modelValue: any;
    required: boolean;
    multiple?: boolean;
}

export interface FindProductCategoryEmits {
    (event: "update:modelValue", value?: any): void;
}

const props = defineProps<FindProductCategoryProps>();
const emit = defineEmits<FindProductCategoryEmits>();

const modelValue = useVModel(props, "modelValue", emit);
// if (props.multiple && modelValue.value === undefined) modelValue.value = [];
const productCategories = ref<ProductCategory[]>([]);
const allCategories = useRootProductCategories().categories;

const productCategoryIDs = computed(() => {
    if (props.multiple)
        return modelValue.value.map((category: ProductCategory) => category.$getID());
    if (modelValue.value !== undefined) return modelValue.value.$getID();
    return undefined;
});

function findProductCategory(text: string) {
    if (text === undefined) return;
    productCategories.value = allCategories.filter(
        (category) =>
            category.name !== undefined &&
            category.name.toLocaleLowerCase().includes(text.toLocaleLowerCase())
    );
}
void allCategories.fetched().then(() => {
    findProductCategory("");
});

interface SelectOption {
    label: string;
    value: string;
    prefix: PrefixOptionType[];
}

enum PrefixOptionType {
    Intersection,
    Line,
    Corner,
    Empty,
}

const getOptionsFromCategory = (
    category: ProductCategory,
    prefix: PrefixOptionType[],
    isLast: boolean
) => {
    const options: SelectOption[] = [];
    options.push({
        label: category.toString(),
        value: category.$getID()!,
        prefix: [
            ...prefix,
            isLast ? PrefixOptionType.Corner : PrefixOptionType.Intersection,
        ],
    });
    category.subCategories.forEach((c, i) => {
        const isLastSubCategory = i === category.subCategories.length - 1;
        options.push(
            ...getOptionsFromCategory(
                c,
                [...prefix, isLast ? PrefixOptionType.Empty : PrefixOptionType.Line],
                isLastSubCategory
            )
        );
    });
    return options;
};

const productCategorysOptions = computed(() => {
    const options: SelectOption[] = [];
    productCategories.value.forEach((c, i) => {
        const isLastCategory = i === productCategories.value.length - 1;
        options.push(...getOptionsFromCategory(c, [], isLastCategory));
    });

    if (props.multiple) {
        modelValue.value.forEach((cat: ProductCategory) => {
            if (options.every((o) => o.value !== cat.$getID())) {
                options.push({
                    label: cat.name,
                    value: cat.$getID()!,
                    prefix: [],
                });
            }
        });
    } else {
        let hasProductCategory = !options.every(
            (o) => o.value !== productCategoryIDs.value
        );
        if (!hasProductCategory && modelValue.value !== undefined) {
            options.push({
                label: modelValue.value.toString(),
                value: modelValue.value.$getID(),
                prefix: [],
            });
        }
    }
    return options;
});

async function selectProductCategory(selected: string) {
    if (selected === undefined) return;

    let found = useDoc(ProductCategory, selected);

    if (props.multiple) {
        modelValue.value.push(found);
    } else {
        modelValue.value = found;
    }
}

async function clearProductCategory() {
    modelValue.value = props.multiple ? [] : undefined;
}
async function deselectProductCategory(deselected: string) {
    if (!props.multiple) {
        await clearProductCategory();
        return;
    }
    modelValue.value = modelValue.value.filter(
        (category: ProductCategory) => category.$getID() !== deselected
    );
}
</script>

<template>
    <div>
        <VField v-slot="{ id }" class="is-autocomplete-select">
            <VControl icon="person_search">
                <Multiselect
                    :id="id"
                    :value="productCategoryIDs"
                    :options="productCategorysOptions"
                    :searchable="true"
                    :filterResults="false"
                    :label-attr="{}"
                    :hide-selected="false"
                    :mode="props.multiple ? 'tags' : undefined"
                    @select="selectProductCategory"
                    @deselect="deselectProductCategory"
                    @clear="clearProductCategory"
                    @search-change="findProductCategory">
                    <template #option="{ option }">
                        <div class="is-flex is-align-items-flex-start">
                            <template v-for="(prefix, i) in option.prefix" :key="i">
                                <template v-if="prefix === PrefixOptionType.Intersection">
                                    <div class="empty-space">
                                        <div class="full-vertical-line"></div>
                                    </div>
                                    <div class="full-horizontal-line"></div>
                                </template>
                                <template v-if="prefix === PrefixOptionType.Corner">
                                    <div class="empty-space">
                                        <div class="mid-vertical-line"></div>
                                    </div>
                                    <div class="full-horizontal-line"></div>
                                </template>
                                <template v-if="prefix === PrefixOptionType.Empty">
                                    <div class="empty-space"></div>
                                </template>
                                <template v-if="prefix === PrefixOptionType.Line">
                                    <div class="empty-space">
                                        <div class="full-vertical-line"></div>
                                    </div>
                                </template>
                            </template>
                        </div>
                        <div>
                            {{ option.label }}
                        </div>
                    </template>
                </Multiselect>
            </VControl>
        </VField>

        <VModel
            v-if="edit && modelValue"
            :is-saving="false"
            :component="ProductCategoryComponent"
            :model="modelValue"
            :options="{}"></VModel>
    </div>
</template>

<style lang="scss" scoped>
$tree-color: #ccc;

.full-vertical-line {
    width: 1px;
    height: 3em;
    background-color: $tree-color;
    position: absolute;
    top: -1em;
    left: 1em;
}

.full-horizontal-line {
    width: 1em;
    height: 1px;
    background-color: $tree-color;
    align-self: center;
}

.mid-vertical-line {
    width: 1px;
    height: 1.5em;
    background-color: $tree-color;
    position: absolute;
    top: -1em;
    left: 1em;
}

.empty-space {
    width: 1em;
    height: 1em;
    position: relative;
}
</style>
