<script lang="ts" setup>
import moment from "moment-timezone";
import { type Ref, ref, watch } from "vue";
enum Action {
    nextAvailable = "nextAvailable",
    swapDate = "swapDate",
    shift = "shift",
    merge = "merge",
}

export interface TranslateValues {
    toDate?: string;
}

export interface RaceMergeModalProps {
    translateValue?: TranslateValues;
    defaultAction?: Action;
    canMerge?: boolean;
    canSwap?: boolean;
}
const props = withDefaults(defineProps<RaceMergeModalProps>(), {
    translateValue: () => {
        return { toDate: moment.now().toString() };
    },
    defaultAction: Action.nextAvailable,
    canMerge: false,
    canSwap: false,
});
const emit = defineEmits(["update:action", "update:isForAll"]);

const action: Ref<Action> = ref(props.defaultAction);
const applyToAll: Ref<boolean> = ref(false);

watch(
    action,
    () => {
        emit("update:action", action.value);
    },
    { immediate: true }
);
watch(
    applyToAll,
    () => {
        emit("update:isForAll", applyToAll.value);
    },
    { immediate: true }
);
</script>

<template>
    <VPlaceholderSection>
        <template #title>
            <div>
                <div>
                    <Translate :values="translateValue"
                        >model.raceGroup.raceMerge.title</Translate
                    >
                </div>
                <VField v-slot="{ id }">
                    <slot>
                        <VFlex flex-direction="row" flex-wrap="wrap">
                            <template v-for="(opt, index) in Action" :key="index">
                                <VRadio
                                    v-if="
                                        (opt === Action.merge && canMerge) ||
                                        (opt === Action.swapDate && canSwap) ||
                                        (opt !== Action.merge && opt !== Action.swapDate)
                                    "
                                    v-model="action"
                                    :value="opt"
                                    :name="id">
                                    <Translate
                                        :path="
                                            'model.raceGroup.raceMerge.' + opt
                                        "></Translate
                                ></VRadio>
                            </template>
                        </VFlex>
                    </slot>
                </VField>
                <VField>
                    <slot>
                        <VFlex :align-content="'flex-start'" :flex-direction="'row'">
                            <VCheckbox v-model="applyToAll"
                                ><Translate
                                    >model.raceGroup.raceMerge.isForAll</Translate
                                ></VCheckbox
                            >
                        </VFlex>
                    </slot>
                </VField>
            </div>
        </template>
    </VPlaceholderSection>
</template>
