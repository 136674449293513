<script lang="ts">
import { defineComponent, toRefs } from "vue";
import type { SalesChannel } from "../../../models/product/channel";
import { useUserSession } from "/@src/stores/userSession";

export function modelEvents() {
    return {
        async onBeforeCreate(salesChannel: SalesChannel) {
            const { user } = useUserSession();
            if (user === undefined) throw new Error("No owner selected");
            salesChannel.owner = user.owner;
        },

        async onBeforeEdit(salesChannel: SalesChannel) {
            const { user } = useUserSession();
            if (user === undefined) throw new Error("No owner selected");
            salesChannel.owner = user.owner;
        },
    };
}

export default defineComponent({
    props: {
        form: {
            type: Object,
            required: true,
        },
    },
    setup(props: any) {
        const { form: salesChannel } = toRefs(props);
        return {
            salesChannel,
        };
    },
});
</script>

<template>
    <VFieldModel v-model="salesChannel" property="name"></VFieldModel>
    <VFieldModel v-model="salesChannel" property="type"></VFieldModel>
</template>
