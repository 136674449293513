<script lang="ts">
import { defineComponent, toRefs } from "vue";
import { useUserSession } from "/@src/stores/userSession";

export function modelEvents() {
    return {
        async onBeforeCreate(employee: any) {
            const { user } = useUserSession();
            if (!user) throw new Error("No owner selected");
            employee.owner = user.owner;
        },

        async onBeforeEdit(employee: any) {
            const { user } = useUserSession();
            if (!user) throw new Error("No owner selected");
            employee.owner = user.owner;
        },
    };
}

export default defineComponent({
    props: {
        form: {
            type: Object,
            required: true,
        },
        fromOwner: {
            type: Boolean,
            default: false,
        },
    },
    setup(props: any) {
        const { form: employee } = toRefs(props);

        return {
            employee,
        };
    },
});
</script>

<template>
    <VFieldModel v-if="!fromOwner" v-model="employee" property="picture"></VFieldModel>
    <VFieldModel v-model="employee" property="firstName"></VFieldModel>
    <VFieldModel v-model="employee" property="lastName"></VFieldModel>
    <VFieldModel v-model="employee" property="email"></VFieldModel>

    <VFieldModel v-model="employee" property="phoneNumber"></VFieldModel>
    <VFieldModel v-model="employee" property="isExternal"></VFieldModel>
    <VFieldModel v-if="!fromOwner" v-model="employee.size" property="shirt"></VFieldModel>
    <VFieldModel v-if="!fromOwner" v-model="employee.size" property="pant"></VFieldModel>
    <VFieldModel v-if="!fromOwner" v-model="employee.size" property="shoe"></VFieldModel>
    <VFieldModel v-if="!fromOwner" v-model="employee" property="role"></VFieldModel>
</template>

<style lang="scss"></style>
