import { Message, Type, Kind } from "./socketMessage";
import type { Ref } from "vue";
import { ref } from "vue";
import { useThrottleFn } from "@vueuse/core";
import type { Race } from "../../../models/race";
import { useMessageManager } from "./messageManager";
import type { Device } from "../../../models/race/device";
import type { Kart } from "../../../models/race/kart";

const END_MESSAGE: any = new Message(Type.END, Kind.broadcast, "");
const START_MESSAGE: any = new Message(Type.START, Kind.broadcast, "");

const useSocketStore = (
    currentRace: Ref<Race | null>,
    onNewDevice: (device: Device | Kart, model: any) => {},
) => {
    let socket: WebSocket | null = null;
    const messageManager = useMessageManager(currentRace, onNewDevice);
    const isSocketOpen = ref(false);

    // watch(user, (usr) => {
    //     if (usr === null) {
    //         closeSocket();
    //     }
    // });

    // function isSocketOpen() {
    //     return socket !== null && socket?.readyState === WebSocket.OPEN;
    // }

    let attempts = 0;
    function restartSocket() {
        attempts += attempts >= 5 ? 0 : 1;
        setTimeout(() => void startSocket(), 2000 * attempts);
    }

    function onSocketError(this: WebSocket, error: Event) {
        // eslint-disable-next-line no-console
        console.error(error);
    }
    const onSocketClose = useThrottleFn(function (this: WebSocket, close: CloseEvent) {
        isSocketOpen.value = false;
        socket = null;

        // eslint-disable-next-line no-console
        console.log("Socket closed", close);
        if (close.code === 1006) {
            // abnormal closure
            restartSocket();
        }
    }, 100);
    function onSocketOpen(this: WebSocket, open: Event) {
        isSocketOpen.value = true;
        // eslint-disable-next-line no-console
        console.log("Socket openned", open);
        attempts = 0;
    }
    function onSocketMessage(this: WebSocket, message: MessageEvent<any>) {
        // eslint-disable-next-line no-console
        // console.log(message.data);
        const data: Message = JSON.parse(message.data);
        messageManager.handleMessage(data);
    }

    function addListener() {
        if (socket === null) return;
        socket.addEventListener("error", onSocketError);
        socket.addEventListener("close", (...a) => void onSocketClose(...a));
        socket.addEventListener("open", onSocketOpen);
        socket.addEventListener("message", onSocketMessage);
    }

    function removeListener() {
        if (socket === null) return;
        socket.removeEventListener("error", onSocketError);
        socket.removeEventListener("close", (...a) => void onSocketClose(...a));
        socket.removeEventListener("open", onSocketOpen);
        socket.removeEventListener("message", onSocketMessage);
    }

    async function startSocket() {
        if (isSocketOpen.value) return;

        await messageManager.waitInitialization();

        if (socket === null) {
            socket = new WebSocket("ws://localhost:30000");
            addListener();
        }
    }

    function closeSocket() {
        if (socket === null) return;

        socket.close();
        removeListener();
    }

    function sendStartSignal() {
        if (isSocketOpen.value) {
            socket?.send(START_MESSAGE.toJson());
        }
    }

    function sendEndSignal() {
        if (isSocketOpen.value) {
            socket?.send(END_MESSAGE.toJson());
        }
    }

    function sendAssignement(
        reference: string,
        number: number,
        speed: string = "1",
        color: { r: number; g: number; b: number } = { r: 0, g: 255, b: 0 },
    ) {
        if (isSocketOpen.value) {
            const message: Message = new Message(Type.ASSIGNED, Kind.kart, reference);
            message.values["red"] = color.r;
            message.values["green"] = color.g;
            message.values["blue"] = color.b;
            message.values["speed"] = speed;
            message.values["number"] = number;
            socket?.send(message.toJson());
        }
    }

    function sendSpeed(reference: string, number: number, speed: string = "1") {
        if (isSocketOpen.value) {
            const message: Message = new Message(Type.SPEED, Kind.kart, reference);
            message.values["speed"] = speed;
            message.values["number"] = number;
            socket?.send(message.toJson());
        }
    }

    function sendLightStatus(status: boolean, kart?: Kart) {
        if (!isSocketOpen.value) {
            return;
        }
        let message: Message;
        if (kart === undefined) {
            message = new Message(Type.LIGHT, Kind.broadcast, "");
        } else {
            message = new Message(Type.LIGHT, Kind.kart, kart.reference);
            message.values["number"] = kart.number;
        }
        message.values["on"] = status === true ? 1 : 0;
        socket?.send(message.toJson());
    }

    function sendGlobalSpeed(speed: string) {
        if (isSocketOpen.value) {
            const message: Message = new Message(Type.SPEED, Kind.broadcast, "");
            message.values["speed"] = speed;
            socket?.send(message.toJson());
        }
    }

    function sendGo() {
        sendGlobalSpeed("/");
    }

    function sendSlow() {
        sendGlobalSpeed("4");
    }

    function sendPitStop() {
        sendGlobalSpeed("5");
    }

    function sendStop() {
        sendGlobalSpeed("0");
    }

    function activateKart(reference: string, number: number) {
        if (isSocketOpen.value) {
            const message: Message = new Message(Type.STATUS, Kind.kart, reference);
            message.values["number"] = number;
            message.values["status"] = "online";
            socket?.send(message.toJson());
        }
    }

    function deactivateKart(reference: string, number: number) {
        if (isSocketOpen.value) {
            const message: Message = new Message(Type.STATUS, Kind.kart, reference);
            message.values["number"] = number;
            message.values["status"] = "offline";
            socket?.send(message.toJson());
        }
    }

    window.sendSpeed = sendSpeed;
    window.sendAssignement = sendAssignement;

    return {
        closeSocket,
        startSocket,
        sendEndSignal,
        sendStartSignal,
        activateKart,
        deactivateKart,
        sendAssignement,
        sendLightStatus,
        sendSpeed,
        sendGo,
        sendSlow,
        sendPitStop,
        sendStop,
        onItemCatched: (callback: (item: any, projector: any, kart: any) => any) => {
            void callback;
        },
        onItemReleased: (callback: (item: any, kart: any) => any) => {
            void callback;
        },
        setItemProjector: (item: any, projector: any) => {
            void item;
            void projector;
        },
        isSocketOpen,
    };
};

export { useSocketStore };
