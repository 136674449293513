import ComponentModelEdit from "addeus-common-library/components/modal/ModelEdit.vue";
import { useModal } from "addeus-common-library/stores/modal";
import type { Ref } from "vue";
import { until } from "@vueuse/core";

import { Employee } from "./models/employee";
import * as ComponentEmployee from "./components/models/employee.vue";
import { Customer } from "./models/customer";
import * as ComponentCustomer from "./components/models/customer.vue";
import { Owner } from "./models/owner";
import * as ComponentOwner from "./components/models/owner.vue";
import { Track } from "./models/race/track";
import * as ComponentTrack from "./components/models/track.vue";
import { GroupType } from "./models/race/group";
import * as ComponentGroupType from "./components/models/race/groupType.vue";
import { Display } from "./models/display";
import * as ComponentDisplay from "./components/models/display.vue";
import { Device } from "./models/race/device";
import * as ComponentDevice from "./components/models/device.vue";
import { Kart } from "./models/race/kart";
import * as ComponentKart from "./components/models/kart.vue";
import { Group as RaceGroup } from "./models/race/group";
import * as ComponentRaceGroup from "./components/models/race/group.vue";
import { Role } from "./models/role";
import * as ComponentRole from "./components/models/role.vue";
import { useLogger } from "./stores/logs";
import { useUserSession } from "./stores/userSession";
import { Tax } from "./models/product/tax";
import * as ComponentTax from "./components/models/product/tax.vue";
import { PaymentMethod } from "./models/product/paymentMethod";
import * as ComponentPaymentMethod from "./components/models/product/paymentMethod.vue";
import { DistributionChannel, SalesChannel } from "./models/product/channel";
import * as ComponentDistributionChannel from "./components/models/product/distributionChannel.vue";
import * as ComponentSalesChannel from "./components/models/product/salesChannel.vue";
import { Product, ProductCategory, ProductVariant } from "./models/product";
import * as ComponentProduct from "./components/models/product/product.vue";
import * as ComponentProductVariant from "./components/models/product/productVariant.vue";
import { useTranslate } from "addeus-common-library/stores/translate";
import { useComponent } from "addeus-common-library/stores/component";
import type { ModelComponentDeclaration } from "addeus-common-library/components/modal/ModelEdit.vue";
import { Stock } from "./models/product/stock";
import * as ComponentStock from "./components/models/product/stock.vue";
import { Type as RaceType } from "./models/race";
import * as ComponentRaceType from "./components/models/race/raceType.vue";
import type { VModalSize } from "addeus-common-library/components/modal/VModal.vue";
import * as ComponentDiscount from "./components/models/product/discount.vue";
import { Discount } from "./models/product/discount";
import { Wallet } from "./models/product/wallet";
import * as ComponentWallet from "./components/models/product/wallet.vue";

import { Closure } from "./models/closure";
import * as ComponentClosure from "./components/models/closure.vue";

export function extend(
    Model: any,
    ModelComponentDeclaration?: ModelComponentDeclaration,
    size?: VModalSize,
) {
    const logger = useLogger();
    const { translate } = useTranslate();
    const component = useComponent();
    const userSession = useUserSession();
    const modelEvents = ModelComponentDeclaration?.modelEvents();

    if (ModelComponentDeclaration !== undefined)
        Model.addMethod("edit", async function (this: any, options: any) {
            if (size !== undefined) {
                if (options === undefined) options = {};
                options.size = size;
            }
            const vNode = component.initialize(ComponentModelEdit, {
                entity: this,
                component: ModelComponentDeclaration,
                options,
            });
            if (vNode.component === undefined || vNode.component === null) return;

            const { cancelReason, successReason, isEdit } = vNode.component.exposed as {
                cancelReason: Ref<any>;
                successReason: Ref<any>;
                isEdit: Ref<boolean>;
            };

            try {
                await new Promise((resolve, reject) => {
                    void until(cancelReason)
                        .not.toBeNull()
                        .then(() => {
                            reject(cancelReason.value);
                            component.destroy(vNode);
                        });

                    void until(successReason)
                        .not.toBeNull()
                        .then(() => {
                            resolve(successReason.value);
                            component.destroy(vNode);
                        });
                });

                if (
                    userSession.user === undefined ||
                    userSession.user === null ||
                    userSession.user.owner === undefined ||
                    userSession.user.owner === null
                )
                    return;

                const userID = userSession.user.$getID();
                if (userID === undefined || userID === null) return;

                const ownerID = userSession.user.owner.$getID();

                if (ownerID === undefined || ownerID === null) return;

                if (!isEdit.value) {
                    void logger.logOnDocumentCreated(
                        userID,
                        ownerID,
                        this.$getPlainForLogs(),
                    );
                } else {
                    const documentBeforeWithId = {
                        docName: this.$getModelName(),
                        uid: this.$getID(),
                        ...this.$getMetadata().previousOrigin,
                    };

                    const plain = this.$getPlainForLogs();

                    void logger.logOnDocumentUpdated(
                        userID,
                        ownerID,
                        plain,
                        documentBeforeWithId,
                    );
                }
            } catch (error) {
                this.$reset();
                throw error;
            }
        });

    Model.addMethod("promptAndDelete", async function (this: any): Promise<boolean> {
        const modal = useModal();
        const modelName = this.$getModelName();
        const translateNamespace = `model.${modelName}`;

        try {
            await modal.prompt(
                translate(translateNamespace + ".remove.title", this).value,
                translate(translateNamespace + ".remove.subtitle", this).value,
                translate(translateNamespace + ".remove.message", this).value,
                translate(translateNamespace + ".remove.cancel", this).value,
                translate(translateNamespace + ".remove.confirm", this).value,
                "danger",
            );
        } catch {
            return false;
        }
        await this.$delete();
        if (userSession.user !== undefined) {
            void logger.logOnDocumentDeleted(
                userSession.user.$getID(),
                userSession.user.owner.$getID(),
                this.$getPlainForLogs(),
            );
        }

        if (modelEvents.onDelete !== undefined) {
            await modelEvents.onDelete(this);
        }
        return true;
    });
}

export default function () {
    const { translate } = useTranslate();
    extend(Employee, ComponentEmployee);
    extend(Customer, ComponentCustomer);
    extend(Owner, ComponentOwner);
    extend(Track, ComponentTrack);
    extend(Display, ComponentDisplay);
    extend(Device, ComponentDevice);
    extend(Kart, ComponentKart);
    extend(RaceGroup, ComponentRaceGroup, "giant");
    RaceGroup.addMethod("promptAndCancel", async function (this: any): Promise<boolean> {
        if (this.cancel === undefined) {
            return false;
        }
        const userSession = useUserSession();
        const logger = useLogger();
        const modal = useModal();
        const modelName = this.$getModelName();
        const translateNamespace = `model.${modelName}.cancel`;

        try {
            await modal.prompt(
                translate(translateNamespace + ".title", this).value,
                translate(translateNamespace + ".subtitle", this).value,
                translate(translateNamespace + ".message", this).value,
                translate(translateNamespace + ".cancel", this).value,
                translate(translateNamespace + ".confirm", this).value,
                "danger",
            );
        } catch {
            return false;
        }
        await this.cancel();
        if (userSession.user !== undefined) {
            void logger.logOnRaceGroupCanceled(
                userSession.user.$getID(),
                userSession.user.owner.$getID(),
                this.$getPlainForLogs(),
            );
        }
        return true;
    });
    extend(GroupType, ComponentGroupType);
    extend(RaceType, ComponentRaceType);
    extend(Role, ComponentRole);
    extend(Tax, ComponentTax);
    extend(PaymentMethod, ComponentPaymentMethod);
    extend(DistributionChannel, ComponentDistributionChannel);
    extend(SalesChannel, ComponentSalesChannel);
    extend(Product, ComponentProduct, "large");
    extend(ProductVariant, ComponentProductVariant, "large");
    extend(Stock, ComponentStock);
    extend(Discount, ComponentDiscount, "large");
    extend(Wallet, ComponentWallet);
    extend(Closure, ComponentClosure);
    extend(ProductCategory);
}
