import type { Driver } from "../../models/race";
import { Group as RaceGroup } from "../../models/race/group";
import { Status as RaceStatus, Race } from "../../models/race";
import moment from "moment-with-locales-es6";
import { acceptHMRUpdate, defineStore } from "pinia";
import type { Ref } from "vue";
import { ref, watch, reactive } from "vue";
import { useCollection, useDoc } from "addeus-common-library/stores/firestore";
import { documentId } from "firebase/firestore";
import { Kart } from "../../models/race/kart";
import { Kind } from "../../models/race/device";
import type { Device } from "../../models/race/device";
import type { Pausable } from "@vueuse/core";
import { tryOnUnmounted, useIntervalFn, useStorage, useThrottleFn } from "@vueuse/core";
import { useSocketStore } from "./socket";
import { useTranslate } from "addeus-common-library/stores/translate";
import { useModal } from "addeus-common-library/stores/modal";
import VButton from "addeus-common-library/components/base/button/VButton.vue";
import MergeModal from "../../components/modal/MergeModal.vue";
import PromptComponent from "addeus-common-library/components/modal/Prompt.vue";
import { useRouter } from "vue-router";
import { useUserSession } from "../userSession";
import { useReporting } from "../reporting";
import { OrderStatus } from "../../models/product/order/orderStatus";
import { useItemManagement } from "./itemManagement";
import { usePeriodRanking } from "./periodRanking";

export const TIME_LIGHT_DURATION = 4500;

export class ColorLED {
    public static RED: ColorLED = new ColorLED("#FF0000");
    public static RED_GREEN: ColorLED = new ColorLED("#FFFF00");
    public static GREEN: ColorLED = new ColorLED("#00FF00");
    public static GREEN_BLUE: ColorLED = new ColorLED("#00FFFF");
    public static BLUE: ColorLED = new ColorLED("#0000FF");
    public static BLUE_RED: ColorLED = new ColorLED("#FF00FF");
    public static OFF: ColorLED = new ColorLED("#000000");

    public static COLORS: ColorLED[] = [
        ColorLED.RED,
        ColorLED.GREEN,
        ColorLED.BLUE,
        ColorLED.RED_GREEN,
        ColorLED.BLUE_RED,
        ColorLED.GREEN_BLUE,
    ];

    public static getRandomColor(): ColorLED {
        const index = Math.floor(Math.random() * 6);
        console.log(index);
        return ColorLED.COLORS[index];
    }

    color: string;

    constructor(color: string) {
        this.color = color;
    }

    getRGB(): { r: number; g: number; b: number } {
        const split = this.color.substring(1).match(/.{1,2}/g);
        return {
            r: parseInt(split[0], 16),
            g: parseInt(split[1], 16),
            b: parseInt(split[2], 16),
        };
    }
}

export const useRace = defineStore("race", () => {
    const reporting = useReporting();
    const currentRace: Ref<Race | null> = useStorage("current-race", null, undefined, {
            serializer: {
                read: (v: any) => (v === null ? null : useDoc(Race, v)),
                write: (v: any) => (v === null ? null : v.$getID()),
            },
            shallow: true,
        }),
        currentGroup: Ref<RaceGroup | null> = useStorage(
            "current-race-group",
            null,
            undefined,
            {
                serializer: {
                    read: (v: any) => (v === null ? null : useDoc(RaceGroup, v)),
                    write: (v: any) => (v === null ? null : v.$getID()),
                },
                shallow: true,
            },
        ),
        duration = ref<moment | null>(null),
        lap = ref<number>(0);

    const router = useRouter();
    const hasTrackConnection = useStorage<boolean>("hasTrackConnection", false);
    const socket = useSocketStore(currentRace, openChoiceModal);
    const itemManagement = useItemManagement(socket, currentRace);
    const modalManager = useModal();
    const { translate } = useTranslate();
    const userSession = useUserSession();
    const periodRanking = usePeriodRanking();
    let interval: Pausable | undefined;

    const preventCloseMessage = translate("model.track.preventClose");

    if (
        hasTrackConnection.value === true ||
        router.currentRoute.value.query.hasOwnProperty("has-track-connection")
    ) {
        hasTrackConnection.value = true;
        void socket.startSocket();

        interval = useIntervalFn(updateRace, 99, {
            immediate: false,
        });

        function watchStarted() {
            if (
                currentRace.value !== null &&
                currentRace.value.status === RaceStatus.started
            ) {
                interval?.resume();
                window.onbeforeunload = function () {
                    return preventCloseMessage.value;
                };
            } else if (
                currentRace.value !== null &&
                currentRace.value.status === RaceStatus.finished
            ) {
                window.onbeforeunload = null;
                updateRace();
            } else {
                window.onbeforeunload = null;
            }
        }

        watch(() => currentRace.value?.status, watchStarted);
        watchStarted();
    }

    function updateRace() {
        if (currentRace.value === null) {
            return;
        }

        duration.value = moment.duration(
            (currentRace.value.endedAt !== undefined
                ? currentRace.value.endedAt
                : moment()
            ).diff(currentRace.value.startedAt) - TIME_LIGHT_DURATION,
        ); // Add lighter time to switch on in green

        if (Array.isArray(currentRace.value.modifiers))
            currentRace.value.modifiers.forEach((modifier) => {
                if (modifier.type === "seconds")
                    duration.value = duration.value.add(modifier.value, "s");
            });

        // if (duration.value.asMilliseconds() < 0) duration.value = moment.duration(0);

        let currentLap = 0;
        currentRace.value.drivers.forEach((driver) => {
            if (driver.laps.length > currentLap) currentLap = driver.laps.length;
        });
        lap.value = currentLap;

        if (currentRace.value.maxLaps && lap.value > currentRace.value.maxLaps) {
            void end();
        }

        if (
            currentRace.value.type?.duration &&
            duration.value.asMilliseconds() >= currentRace.value.type.duration
        ) {
            void end();
        }
    }

    function startTrackCommunication() {
        void socket.startSocket();
    }

    function endTrackCommunication() {
        socket.closeSocket();
    }

    async function start(race: Race, group: RaceGroup) {
        currentRace.value = race;
        currentGroup.value = group;

        currentRace.value.status = RaceStatus.started;
        currentRace.value.startedAt = moment();
        currentRace.value.modifiers = [];
        await currentRace.value.$save();
        currentRace.value.drivers.forEach((driver) => {
            if (!Array.isArray(driver.kart?.batteries)) return;
            driver.kart?.batteries.forEach((battery) => {
                battery.consecutiveRaces++;
            });
            void driver.kart?.$save();
        });
        socket.sendStartSignal();
        itemManagement.start();

        await currentGroup.value.$getMetadata().refresh();

        await currentRace.value.type?.$getMetadata().refresh();
        await currentGroup.value.type?.$getMetadata().refresh();
        void reporting.add("startedRace", {
            groupID: currentGroup.value?.$getID(),
            raceID: currentRace.value.$getID(),
            typeID: currentRace.value.type?.$getID(),
            groupType: currentGroup.value.type?.$getID(),
            groupTypeName: currentGroup.value.type?.name,
            originDate: currentRace.value.date.valueOf(),
            startedDate: currentRace.value.startedAt.valueOf(),
            numberOfDrivers: currentRace.value.drivers.length,
            payed:
                currentGroup.value.order?.status === OrderStatus.Closed ||
                currentGroup.value.order?.status === OrderStatus.WaitingPayment,
            numberMaxOfDrivers:
                currentGroup.value.maxParticipants ||
                currentRace.value.type?.maxParticipants,
        });
    }

    const cancel = async () => {
        if (currentRace.value === null) return;
        currentRace.value.status = RaceStatus.assigning;
        currentRace.value.startedAt = undefined;
        currentRace.value.drivers = currentRace.value.drivers.filter(
            (driver) => driver.driverID !== undefined,
        );
        currentRace.value.drivers.forEach((driver) => {
            driver.laps = [];
        });
        await currentRace.value.$save();
        currentGroup.value = null;
        currentRace.value = null;
        socket.sendEndSignal();
    };

    const end = useThrottleFn(
        () =>
            void (async function () {
                interval?.pause();

                if (currentRace.value === null || currentGroup.value === null) return;

                if (
                    currentRace.value.endedAt !== undefined &&
                    currentRace.value.status === RaceStatus.finished
                )
                    return;

                socket.sendEndSignal();
                itemManagement.end();
                currentRace.value.endedAt = currentRace.value.type?.duration
                    ? currentRace.value.startedAt
                          .clone()
                          .add(
                              currentRace.value.type.duration + TIME_LIGHT_DURATION,
                              "milliseconds",
                          )
                    : moment();
                currentRace.value.status = RaceStatus.finished;
                await currentRace.value.$save();
                await periodRanking.updateRanking(
                    currentRace.value.drivers.filter((driver) => {
                        return driver.driverID !== undefined;
                    }),
                    currentRace.value.type?.rankingPeriods,
                );
                const currentKartIDs = currentRace.value.drivers
                    .filter((driver) => driver.kart !== undefined)
                    .map((driver: Driver) => driver.kart!.$getID());

                const allKarts = useCollection(Kart, {
                    wheres: [
                        [documentId(), "not-in", currentKartIDs],
                        ["owner", "==", currentRace.value.owner?.$getID()],
                    ],
                });
                await allKarts.fetched();

                allKarts.forEach((kart) => {
                    if (!Array.isArray(kart.batteries)) return;
                    kart.batteries.forEach((battery) => {
                        if (battery.consecutiveRaces <= 0) return;
                        battery.consecutiveRaces--;
                    });
                    void kart.$save();
                });

                const raceGroupID = currentGroup.value.$getID();
                if (raceGroupID === undefined) return;
                const groupRaces = currentGroup.value.races.filter(
                    (race) =>
                        race.groups[raceGroupID] ===
                        currentRace.value?.groups[raceGroupID],
                );
                if (
                    groupRaces.every(
                        (race) =>
                            race.status === RaceStatus.finished ||
                            race.status === RaceStatus.canceled,
                    )
                ) {
                    await currentGroup.value.generateRaces();
                    await currentGroup.value.races.reduce(async (acc, race) => {
                        await acc;
                        return race.$save();
                    }, Promise.resolve());
                    await currentGroup.value.$save();
                }

                currentGroup.value = null;
                currentRace.value = null;
            })(),
        200,
    );

    function activateKart(kart: Kart) {
        if (kart.reference === undefined)
            throw new Error(`Kart ${kart.$getID()} as no reference`);
        socket.activateKart(
            kart.reference,
            Number.parseInt(kart.reference !== undefined ? kart.reference : "0"),
        );
    }

    function deactivateKart(kart: Kart) {
        if (kart.reference === undefined)
            throw new Error(`Kart ${kart.$getID()} as no reference`);
        socket.deactivateKart(
            kart.reference,
            Number.parseInt(kart.reference !== undefined ? kart.reference : "0"),
        );
    }

    function assignKart(
        kart: Kart,
        speed: string,
        color: { r: number; g: number; b: number },
    ) {
        if (!kart.reference?.startsWith("0013a200")) return;
        // throw new Error(`Kart ${kart.$getID()} as no reference`);
        socket.sendAssignement(kart.reference, kart.number, speed, color);
    }

    function setSpeed(kart: Kart, speed: string) {
        if (!kart.reference?.startsWith("0013a200")) return;
        socket.sendSpeed(kart.reference, kart.number, speed);
    }

    function setKartLights(kart: Kart, status: boolean) {
        if (!kart.reference?.startsWith("0013a200")) return;
        socket.sendLightStatus(status, kart);
    }

    function setKartsLights(status: boolean) {
        socket.sendLightStatus(status);
    }

    function sendGoSpeed() {
        socket.sendGo();
    }
    function sendSlowSpeed() {
        socket.sendSlow();
    }
    function sendPitStopSpeed() {
        socket.sendPitStop();
    }
    function sendStopSpeed() {
        socket.sendStop();
    }

    function openDeviceMergeModal(device: Device | Kart, model: any) {
        const translateNamespace = `model.${model === Kart ? "kart" : "device"}.merge`;
        // obtenir la liste des composants
        device.owner = currentRace.value?.owner;
        const selected = ref(null);
        const devices = useCollection(model, {
            wheres: [
                ["owner", "==", device.owner?.$getID()],
                ["kind", "==", device.kind],
                ["name", "!=", device.name],
            ],
            limit: -1,
        });
        const options = reactive({
            title: translate(`${translateNamespace}.title`, device).value,
            props: {
                entity: device,
                collection: devices,
            },
            isCloseDisabled: true,
            events: {
                "update:select"(select) {
                    selected.value = select;
                },
            },
            actions: [
                {
                    component: VButton,
                    content: translate(`${translateNamespace}.save`).value,
                    props: {},
                    events: {
                        click() {
                            device.$edit();
                            modal.close();
                        },
                    },
                },
                {
                    component: VButton,
                    content: translate(`${translateNamespace}.merge`).value,
                    props: {
                        placeload: false,
                        color: "primary",
                    },
                    events: {
                        click() {
                            if (selected.value !== null) {
                                const entity: Device = selected.value;
                                entity.reference = device.reference;
                                if (device.version !== "0.0.0") {
                                    entity.version === device.version;
                                }
                                void entity.$save();
                                void device.$delete();
                            }
                            modal.close();
                        },
                    },
                },
            ],
        });
        const modal = modalManager.createModal(MergeModal, options);
    }

    function openChoiceModal(device: Device | Kart, model: any) {
        const translateNamespace = `model.${model === Kart ? "kart" : "device"}.choice`;
        if (userSession.isLoggedIn().value === false) return;
        device.owner = currentRace.value?.owner;
        const options = reactive({
            title: translate(`${translateNamespace}.title`).value,
            props: {
                subTitle: translate(`${translateNamespace}.subtitle`, device).value,
                message: translate(`${translateNamespace}.message`, device).value,
            },
            isCloseDisabled: true,
            actions: [
                {
                    component: VButton,
                    content: translate(`${translateNamespace}.merge`).value,
                    props: {},
                    events: {
                        click() {
                            if (device.kind === Kind.kart) {
                                void openDeviceMergeModal(device, model);
                            } else {
                                void openDeviceMergeModal(device, model);
                            }
                            modal.close();
                        },
                    },
                },
                {
                    component: VButton,
                    content: translate(`${translateNamespace}.create`).value,
                    props: {
                        placeload: false,
                        color: "primary",
                        disabled: false,
                    },
                    events: {
                        click() {
                            void device.$edit();
                            modal.close();
                        },
                    },
                },
            ],
        });
        const modal = useModal().createModal(PromptComponent, options);
    }

    tryOnUnmounted(() => {
        socket.closeSocket();
    });

    return {
        currentRace,
        currentGroup,
        duration,
        lap,
        assignKart,
        setSpeed,
        sendGoSpeed,
        sendSlowSpeed,
        sendPitStopSpeed,
        sendStopSpeed,
        setKartLights,
        setKartsLights,
        start,
        cancel,
        end,
        startTrackCommunication,
        endTrackCommunication,
        isSocketOpen: socket.isSocketOpen,
        activateKart,
        deactivateKart,
    };
});

/**
 * Pinia supports Hot Module replacement so you can edit your stores and
 * interact with them directly in your app without reloading the page.
 *
 * @see https://pinia.esm.dev/cookbook/hot-module-replacement.html
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useRace, import.meta.hot));
}
