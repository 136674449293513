import { Owner } from "../owner";
import { Entity, Var, Input } from "addeus-common-library/stores/firestore";
import { OrderStatus } from "./order/orderStatus";
import { SalesChannel } from "./channel";
import { EntityArray } from "addeus-common-library/stores/firestore/entity";
import { useUserSession } from "../../stores/userSession";
import { computed } from "vue";

export enum PaymentType {
    BankingCard = "BankingCard",
    Online_Kkia = "Online Kkia",
    Online = "Online",
    Cash = "Cash",
    Transfer = "Transfer",
    Wallet = "Wallet",
    Other = "Other",
}

export enum Currencies {
    Euro = "Euro",
    Dollar = "Dollar",
    Bitcoin = "Bitcoin",
}

export class PaymentMethod extends Entity {
    static collectionName = "paymentMethods";
    @Var(String)
    @Input("text", {
        required: true,
    })
    name?: string;

    @Var(PaymentType)
    @Input("select", {
        options: PaymentType,
        required: true,
    })
    type: PaymentType = PaymentType.Other;

    @Var(String)
    @Input("text")
    apiKey?: string;

    @Var(Array.of(SalesChannel))
    @Input("select", {
        options: {
            entity: SalesChannel,
            where() {
                const userSession = useUserSession();
                return computed(() => [
                    ["owner", "==", userSession.user?.owner?.$getID()],
                ]);
            },
            limit: -1,
        },
        multiple: true,
    })
    saleChannels: SalesChannel[] = EntityArray(new Array<SalesChannel>());

    @Var(Currencies)
    @Input("select", {
        options: Currencies,
        required: true,
    })
    currency: Currencies = Currencies.Euro;

    @Var(Boolean)
    @Input("checkbox")
    canRefund: boolean = false;

    @Var(Owner)
    owner?: Owner;

    @Var(OrderStatus)
    @Input("select", {
        options: {
            WaitingPayment: OrderStatus.WaitingPayment,
            Closed: OrderStatus.Closed,
        },
        required: true,
    })
    orderStatus: OrderStatus = OrderStatus.Closed;

    @Var(Number)
    @Input("number")
    maxAmount?: number;

    @Var(String)
    @Input("text", {
        required: true,
    })
    maxAmountMessage?: string;

    toString() {
        return this.name;
    }
}
