<script lang="ts">
import { defineComponent, toRefs } from "vue";
import { useUserSession } from "/@src/stores/userSession";
import * as yup from "yup";
import { Permission } from "/@src/models/role";

export function modelEvents() {
    return {
        async onBeforeCreate(role: any) {
            const { user } = useUserSession();
            if (user === null) throw new Error("No owner selected");
            role.owner = user.owner;
        },

        async onBeforeEdit(role: any) {
            const { user } = useUserSession();
            if (user === null) throw new Error("No owner selected");
            role.owner = user.owner;
        },
    };
}
interface Option {
    label: string;
    value: Permission;
    id: string;
}
interface Perms {
    read?: Option;
    write?: Option;
    perm: string[];
}
export default defineComponent({
    props: {
        form: {
            type: Object,
            required: true,
        },
        fromOwner: {
            type: Boolean,
            default: false,
        },
    },
    setup(props: any) {
        const { form: role } = toRefs(props);
        const schema = yup.array(`.${props.property}.validation.array`);
        function getViewPermissions(): Option[] {
            const result: any[] = Object.keys(Permission)
                .filter((perm) => perm.endsWith("_view"))
                .sort((a, b) => a.localeCompare(b))
                .map((rowKey) => {
                    return {
                        label: `.options.${rowKey}`,
                        value: Permission[rowKey as keyof typeof Permission],
                        id: rowKey,
                    };
                });
            return result;
        }
        function getEditPermissions(): Option[] {
            const result: any[] = Object.keys(Permission)
                .filter((perm) => perm.endsWith("_edit"))
                .sort((a, b) => a.localeCompare(b))
                .map((rowKey) => {
                    return {
                        label: `.options.${rowKey}`,
                        value: Permission[rowKey as keyof typeof Permission],
                        id: rowKey,
                    };
                });
            return result;
        }

        function getPrefixPermission(perm1) {
            return perm1.id.replace(/_(view|edit)$/, "");
        }

        /**
         * A function that get permissions sorted by group
         * A group started with same prefix and ended with _view and _edit
         * @returns {Perms[]} An array of permissions sorted by group
         */
        function getPermissions(): Perms[] {
            const result: { [key: string]: Perms } = {};

            const viewPerms = getViewPermissions();
            const editPerms = getEditPermissions();
            viewPerms.forEach((perm) => {
                const prefix = getPrefixPermission(perm);
                if (result[prefix] === undefined) {
                    result[prefix] = {
                        read: perm,
                        perm: [perm.id],
                    };
                } else {
                    result[prefix].read = perm;
                    result[prefix].perm.push(perm.id);
                }
            });

            editPerms.forEach((perm) => {
                const prefix = getPrefixPermission(perm);
                if (result[prefix] === undefined) {
                    result[prefix] = {
                        write: perm,
                        perm: [perm.id],
                    };
                } else {
                    result[prefix].write = perm;
                    result[prefix].perm.push(perm.id);
                }
            });
            return Object.values(result);
        }
        return {
            role,
            schema,
            permissions: getPermissions(),
        };
    },
});
</script>

<template>
    <div>
        <VFieldModel v-model="role" property="name"></VFieldModel>
        <VValidation
            v-slot="{ field }"
            ref="validation"
            v-model="role['permissions']"
            :property="'permissions'"
            :schema="schema">
            <VField v-slot="{ id }" :label="'.permissions.label'" :label-attr="role">
                <VFlex :flex-direction="'row'" :justify-content="'space-around'">
                    <table>
                        <thead></thead>
                        <tbody>
                            <tr v-for="(permission, index) in permissions" :key="index">
                                <td>
                                    <VCheckbox
                                        v-if="permission.read"
                                        :id="id"
                                        v-model="field.value"
                                        class="m-3"
                                        :name="'permissions'"
                                        :value="permission.read.value"
                                        multiple>
                                        <TranslateNamespace :path="`.permissions`">
                                            <Translate>{{
                                                permission.read.label
                                            }}</Translate>
                                        </TranslateNamespace>
                                    </VCheckbox>
                                </td>
                                <td v-if="permission.write">
                                    <VCheckbox
                                        :id="id"
                                        v-model="field.value"
                                        class="m-3"
                                        :name="'permissions'"
                                        :value="permission.write.value"
                                        multiple>
                                        <TranslateNamespace :path="`.permissions`">
                                            <Translate>{{
                                                permission.write.label
                                            }}</Translate>
                                        </TranslateNamespace>
                                    </VCheckbox>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </VFlex>
            </VField>
        </VValidation>
    </div>
</template>

<style lang="scss">
tr:empty {
    display: none;
}
</style>
