import { Entity, Input, Var } from "addeus-common-library/stores/firestore/index";
import { Owner } from "../owner";
import { Type as RaceType } from "./index";
import { EntityArray } from "addeus-common-library/stores/firestore/entity";

export class GroupType extends Entity {
    static collectionName = "raceGroupTypes";
    @Var(String)
    @Input("text", { required: true })
    name?: string;

    @Var(Array.of(RaceType))
    @Input("array", { of: RaceType })
    raceTypes: RaceType[] = EntityArray();

    @Var(Owner)
    owner?: Owner;

    @Var(Boolean)
    @Input("checkbox")
    isOrderRaceBlocked: boolean = false;

    @Var(Number)
    @Input("number", { required: true })
    weight: number = 0;

    toString() {
        return this.name;
    }
}
