import { createRouter as createClientRouter, createWebHistory } from "vue-router";

/**
 * routes are generated using vite-plugin-pages
 * each .vue files located in the ./src/pages are registered as a route
 * @see https://github.com/hannoeru/vite-plugin-pages
 */
import routes from "~pages";

export function createRouter() {
    const router = createClientRouter({
        /**
         * If you need to serve track2max under a subdirectory,
         * you have to set the name of the directory in createWebHistory here
         * and update "base" config in vite.config.ts
         */
        // history: createWebHistory('my-subdirectory'),
        history: createWebHistory(),
        routes: routes,
    });

    return router;
}
