<script lang="ts">
import { defineComponent, toRefs, type Ref } from "vue";
import { PaymentType, type PaymentMethod } from "../../../models/product/paymentMethod";
import { useUserSession } from "../../../stores/userSession";

export function modelEvents() {
    return {
        onBeforeCreate(paymentMethod: PaymentMethod) {
            const { user } = useUserSession();
            if (user === undefined) throw new Error("No owner selected");
            paymentMethod.owner = user.owner;
        },
        onBeforeEdit(paymentMethod: PaymentMethod) {
            const { user } = useUserSession();
            if (user === undefined) throw new Error("No owner selected");
            paymentMethod.owner = user.owner;
        },
    };
}

export default defineComponent({
    props: {
        form: {
            type: Object,
            required: true,
        },
    },
    setup(props: any) {
        const { form: paymentMethod } = toRefs(props);
        const apiPayments: PaymentType[] = [PaymentType.Online, PaymentType.Online_Kkia];
        return {
            PaymentType,
            apiPayments,
            paymentMethod: paymentMethod as Ref<PaymentMethod>,
        };
    },
});
</script>

<template>
    <div>
        <VFieldModel v-model="paymentMethod" property="name"></VFieldModel>
        <VFieldModel v-model="paymentMethod" property="type"></VFieldModel>
        <VFieldModel
            v-if="apiPayments.includes(paymentMethod.type)"
            v-model="paymentMethod"
            property="apiKey"></VFieldModel>
        <VFieldModel v-model="paymentMethod" property="saleChannels"></VFieldModel>
        <VFieldModel v-model="paymentMethod" property="canRefund"></VFieldModel>
        <VFieldModel v-model="paymentMethod" property="orderStatus"></VFieldModel>
        <VFieldModel v-model="paymentMethod" property="maxAmount"></VFieldModel>
        <VFieldModel
            v-if="typeof paymentMethod.maxAmount === 'number'"
            v-model="paymentMethod"
            property="maxAmountMessage"></VFieldModel>
    </div>
</template>
