import { Entity, Input, Var } from "addeus-common-library/stores/firestore/index";
import { Scenario, Hook, HookType, Status } from "./scenario";
import { Owner } from "../owner";
import { Frame } from "./frame";
import { EntityArray } from "addeus-common-library/stores/firestore/entity";

export class Display extends Entity {
    static collectionName = "displays";

    @Var(String)
    @Input("text", { required: true })
    name?: String;

    @Var(Owner)
    owner?: Owner;

    @Var(Array.of(Scenario))
    scenarios?: Scenario[] = EntityArray();

    async startScenario(scenario: Scenario) {
        this.scenarios?.forEach((scenario) => {
            scenario.status = Status.idle;
        });
        await this.$save();
        scenario.status = Status.playing;
        await this.$save();
    }
}

export { Frame, Scenario, Hook, HookType };
